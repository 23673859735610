/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';

import ErrorList from 'components/ErrorList';
import FormGroup from 'components/FormGroup';
import ClientFields from 'components/fields/ClientFields';
import { BillingContactSelect } from 'components/inputs/BillingContactSelect';
import ClientSelect from 'components/inputs/ClientSelect';
import Input from 'components/inputs/Input';
import SalesRepSelect from 'components/inputs/SalesRepSelect';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { nestedErrors } from 'utils/validation';
import CustomPropTypes from '../../../utils/propTypes';

const errorFields = {
  client: 'client',
  billingContact: 'billing contact',
  'client.name': 'client name',
  'client.addressZipcode': 'client zip code',
  salesRep: 'sales representative',
  'billingContact.name': 'billing contact name',
  'billingContact.email': 'billing contact email',
  'billingContact.phone': 'billing contact phone',
};

const ViewToggleLink = ({ onClick, children }) => {
  return (
    <span style={{ lineHeight: '40px' }}>
      or <a onClick={onClick}>{children}</a>
    </span>
  );
};

const ClientInfoFields = ({ errors = {}, engagement, newEngagement }) => {
  const { register, setValue, watch } = useFormContext();

  const clientId = watch('clientId', engagement?.client?.id);
  const hasClientAttributes = watch('hasClientAttributes');
  const hasBillingContactAttributes = watch('hasBillingContactAttributes');

  const toggleClientAttributes = useCallback(() => {
    const nextClientAttributes = !hasClientAttributes;

    // Going to show Client Form, Also Show Contact Form
    if (nextClientAttributes) setValue('hasBillingContactAttributes', true);
    setValue('hasClientAttributes', nextClientAttributes);
  }, [setValue, hasClientAttributes]);

  const toggleBillingContactAttributes = useCallback(
    () => setValue('hasBillingContactAttributes', !hasBillingContactAttributes),
    [setValue, hasBillingContactAttributes]
  );

  return (
    <div>
      <ErrorList errors={errors} fields={errorFields} />
      {hasClientAttributes ? (
        <ClientFields path="clientAttributes" errors={nestedErrors(errors, 'client')} newEngagement={newEngagement}>
          <ViewToggleLink onClick={toggleClientAttributes}>Select Existing Client</ViewToggleLink>
        </ClientFields>
      ) : (
        <FormGroup label="Client">
          <ClientSelect name="clientId" errors={errors.client} width={380} autoFocus />
          <ViewToggleLink onClick={toggleClientAttributes}>Create New Client</ViewToggleLink>
        </FormGroup>
      )}
      <FormGroup label="Billing Contact">
        {hasBillingContactAttributes ? (
          <>
            <FormGroup>
              <Input
                {...register('billingContactAttributes.name')}
                errors={errors['billingContact.name']}
                placeholder="Billing contact name"
                width={380}
              />
              <ViewToggleLink onClick={toggleBillingContactAttributes}>Select Existing Contact</ViewToggleLink>
            </FormGroup>
            <FormGroup>
              <Input
                {...register('billingContactAttributes.email')}
                errors={errors['billingContact.email']}
                placeholder="Email"
                width={380}
              />
              <Input
                {...register('billingContactAttributes.phone')}
                errors={errors['billingContact.phone']}
                placeholder="Phone #"
                width={185}
              />
            </FormGroup>
          </>
        ) : (
          <FormGroup>
            <BillingContactSelect
              name="billingContactId"
              clientId={clientId}
              errors={errors.billingContact}
              width={380}
            />
            <ViewToggleLink onClick={toggleBillingContactAttributes}>Create New Contact</ViewToggleLink>
          </FormGroup>
        )}
      </FormGroup>
      <FormGroup label="Sales Representative">
        <SalesRepSelect name="salesRepId" errors={errors.salesRep} width={380} showEnabledUsers />
      </FormGroup>
    </div>
  );
};

ClientInfoFields.propTypes = {
  errors: PropTypes.object,
  engagement: CustomPropTypes.engagement,
  newEngagement: PropTypes.bool,
};

export default ClientInfoFields;
