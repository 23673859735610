import { call, put } from 'redux-saga/effects';

import { fetchEngagementsSuccess } from 'actions/engagements';
import { fetchEngagements } from 'utils/api';

export default function* fetchEngagementsSaga({ payload: params }) {
  try {
    const { engagements } = yield call(fetchEngagements, params);
    if (engagements) yield put(fetchEngagementsSuccess(engagements));
  } catch (e) {
    console.warn('Something went wrong');
  }
}
