import { RHFSelect, UncontrolledSelect } from 'components/inputs/Select';

const buildOptions = roles => {
  return roles.sort().map(role => ({
    label: role,
    value: role,
  }));
};

const RoleSelect = ({ roles, ...props }) => {
  return (
    <RHFSelect
      placeholder="Select role"
      {...props}
      options={buildOptions(roles)}
      formatCreateLabel={label => `Create new role "${label}"`}
      clearable
      creatable
    />
  );
};

RoleSelect.propTypes = {
  ...UncontrolledSelect.propTypes,
};

export default RoleSelect;
