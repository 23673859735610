import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import CancelButton from 'components/buttons/CancelButton';
import SaveCancelButtons from 'components/buttons/SaveCancelButtons';
import { errorFields } from 'components/Clients';
import ErrorList from 'components/ErrorList';
import ClientFields from 'components/fields/ClientFields';
import Page from 'components/Page';
import Section from 'components/Section';
import { pushRoute } from 'utils/navigation';
import { combineErrors } from '../../../utils/combineErrors';
import { clientSchema } from '../../../schemas/client.schema';
import { VALIDATION_MODE } from 'constants';

const NewClient = ({ submit }) => {
  const formContext = useForm({
    resolver: yupResolver(clientSchema.required()),
    mode: VALIDATION_MODE,
  });

  const errors = combineErrors(formContext.formState.errors, {});

  return (
    <Page title="New Client" headerAdditions={<CancelButton onClick={() => pushRoute('/clients')} />} padding={15}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(submit)}>
          <Section title="Client Information">
            <ErrorList errors={errors} fields={errorFields} />
            <ClientFields errors={errors} />
          </Section>
          <SaveCancelButtons />
        </form>
      </FormProvider>
    </Page>
  );
};

NewClient.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default NewClient;
