import { useReferenceQuery } from 'hooks/useReferenceQuery';
import { fetchSalesReps } from '../../utils/api';

const labelFormatter = salesRep => (salesRep.salesRepId ? `${salesRep.salesRepId} - ${salesRep.name}` : salesRep.name);

export const useSalesReps = params => {
  const queryName = 'salesReps';

  const queryMethod = async () => {
    const { spudUsers } = await fetchSalesReps(params);
    return spudUsers;
  };

  const options = {
    formatOptions: { labelFormatter },
  };

  const salesRepQuery = useReferenceQuery(queryName, queryMethod, options);
  return salesRepQuery;
};
