export default {
  resource: {
    width: 150,
  },
  role: {
    width: 150,
  },
  billingRate: {
    width: 100,
  },
  estimate: {
    width: 350,
  },
  estimateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  estimateInput: {
    flex: '0 1 33.3333%',
    marginRight: 5,
    '&:last-child': {
      margin: 0,
    },
  },
  interval: {
    minWidth: 125,
    maxWidth: 125,
  },
  controls: {
    textAlign: 'right !important',
    minWidth: 90,
  },
  highlighted: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.2) !important',
    },
  },
  container: {
    width: '100%',
  },
  accountDisabled: {
    backgroundColor: 'rgba(192,192,192, 0.5)',
    color: 'black',
    '&:hover': {
      backgroundColor: 'rgba(192,192,192, 0.65) !important',
      color: 'black !important',
    },
  },
  tableRow: {
    verticalAlign: 'top',
  },
};
