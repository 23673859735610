import { fetchResourceRolesSuccess } from 'actions/resources';
import { call, put } from 'redux-saga/effects';

import { fetchResourceRoles } from 'utils/api';

export function* fetchRolesSaga({ payload: params }) {
  try {
    const { roles } = yield call(fetchResourceRoles, params);
    if (roles.errors) {
      // TODO: handle errors
    } else {
      yield put(fetchResourceRolesSuccess(roles));
    }
  } catch (e) {
    // TODO: handle connection problem and rollback
  }
}
