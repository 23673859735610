import { Component } from 'react';

import Section from './Section';

const toggleEditing = state => ({ editing: !state.editing });

export default class SectionContainer extends Component {
  state = { editing: false };

  toggleEditing = () => this.setState(toggleEditing);

  getChildProps() {
    return {
      ...this.props,
      ...this.state,
      toggleEditing: this.toggleEditing,
    };
  }

  render() {
    return <Section {...this.getChildProps()} />;
  }
}
