import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

const allProjectStatuses = state => state.projectStatuses.get('projectStatuses');
export const getAllProjectStatuses = createSelector([allProjectStatuses], projectStatuses =>
  orderBy(projectStatuses.toArray(), ['updatedAt'], ['desc'])
);

const newProjectStatuses = state => state.projectStatuses.get('newProjectStatuses');
export const getNewProjectStatuses = createSelector([newProjectStatuses], newProjectStatuses =>
  newProjectStatuses.toArray()
);

export const getEngagementId = state => state.projectStatuses.get('engagementId');

export const getCurrentStatus = state => getAllProjectStatuses[0];
