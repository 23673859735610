import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { ConditionalLabel } from '../../ConditionalLabel';

const StyledInput = styled.input`
  &.hasLabel {
    position: relative;
    top: -3px;
    margin-right: 10px;
  }
`;

const Radio = forwardRef(({ label, ...props }, ref) => {
  return (
    <ConditionalLabel label={label}>
      <StyledInput {...props} type="radio" ref={ref} className={classnames({ hasLabel: label })} />
    </ConditionalLabel>
  );
});

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.node,
  onChange: PropTypes.func,
};

export default Radio;
