/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CustomPropTypes from 'utils/propTypes';

import styles from './DocumentRow.styles';
import { DestroyIcon, EditIcon } from './icons';

const DocumentRow = ({ toggleEditing, showControls, document, classes, mutations }) => {
  const handleDestroyDocument = () => {
    return mutations.destroy.mutate(document);
  };

  return (
    <tr>
      <td>{document.name}</td>
      <td>{document.description}</td>
      <td>{dayjs(document.createdAt).format('MM/DD/YYYY h:MM A')}</td>
      <td>{document.uploadedBy}</td>
      <td className={classes.download}>
        <a href={document.url} download={document.name} target="_blank" rel="noreferrer">
          <i className="fa fa-download" />
        </a>
      </td>
      {showControls && (
        <td className={classes.controls}>
          <span>
            <a onClick={toggleEditing} style={{ marginRight: 10 }} children={<EditIcon />} />
            <a onClick={handleDestroyDocument} children={<DestroyIcon />} />
          </span>
        </td>
      )}
    </tr>
  );
};

DocumentRow.propTypes = {
  classes: CustomPropTypes.classes,
  document: CustomPropTypes.document.isRequired,
  showControls: PropTypes.bool,
  toggleEditing: PropTypes.func.isRequired,
  mutations: PropTypes.shape({
    destroy: PropTypes.object.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(DocumentRow);
