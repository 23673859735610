import BaseIcon from 'components/Icon';
import * as theme from 'theme';

const Icon = ({ color: originalColor, ...props }) => {
  let color = originalColor;
  let cursor = 'pointer';

  if (props.disabled) {
    color = 'rgba(0, 0, 0, 0.25)';
    cursor = 'not-allowed';
  }

  return <BaseIcon {...props} color={color} style={{ fontSize: '20px', cursor, ...props.style }} />;
};

export const CancelIcon = () => <Icon type="times-circle" color="#a2a3a9" />;
export const DestroyIcon = () => <Icon type="times-circle" color={theme.colors.darkRed} />;
export const EditIcon = () => <Icon type="pencil" />;
export const SaveIcon = ({ disabled }) => <Icon type="floppy-o" color={theme.colors.green} disabled={disabled} />;
