/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { DatePicker } from 'components/inputs';
import Input from 'components/inputs/Input';
import ResourcesSelect from 'components/inputs/ResourcesSelect';
import RoleSelect from 'components/inputs/RoleSelect';
import { RHFSelect } from 'components/inputs/Select';
import * as theme from 'theme';
import { objectToOptions } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

import { FormProvider, useForm } from 'react-hook-form';
import { ESTIMATE_TYPES, HOUR_INTERVAL } from './constants';
import { CancelIcon, DestroyIcon, SaveIcon } from './icons';
import { useDispatch } from 'react-redux';
import { fetchResourceRoles, saveResource } from 'actions/resources';
import { yupResolver } from '@hookform/resolvers/yup';
import { resourceSchema } from 'schemas/resource.schema';
import { combineErrors } from 'utils/combineErrors';

const MAX_ROLE_LENGTH = 31;

const estimateTypesOptions = objectToOptions(ESTIMATE_TYPES);
const EstimateTypesSelect = props => <RHFSelect {...props} options={estimateTypesOptions} autosize={false} />;

const hoursIntervalOptions = objectToOptions(HOUR_INTERVAL);
const HoursIntervalSelect = props => <RHFSelect {...props} options={hoursIntervalOptions} autosize={false} />;

const createDefaultValuesFromResource = resource => ({
  id: resource?.id,
  endDate: resource?.endDate && dayjs(resource.endDate, 'YYYY-MM-DD').toDate(),
  estimate: resource?.estimate,
  estimateInterval: resource?.estimateInterval ?? 'total_project',
  estimateType: resource?.estimateType ?? 'full_time',
  name: resource?.name ?? '',
  rate: resource?.rate ?? '100',
  role: resource?.role ?? '',
  spudUserId: resource?.spudUserId,
  startDate: resource?.startDate && dayjs(resource.startDate, 'YYYY-MM-DD').toDate(),
});

export const EditResourceRow = ({
  classes,
  engagement,
  employees,
  newResource: isNewResource,
  resource,
  roles,
  showControls,
  showRate,
  removeNewResource,
  toggleEditing,
  uuid,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResourceRoles());
  }, [dispatch]);

  const formContext = useForm({
    defaultValues: createDefaultValuesFromResource(resource),
    resolver: yupResolver(resourceSchema),
    shouldUnregister: true,
  });

  const { register, watch, handleSubmit, setValue } = formContext;
  const [role, estimateType, spudUserId] = watch(['role', 'estimateType', 'spudUserId']);

  // TODO: name was never cleaned up when we switched to using spudUserId.
  // Analysis needs to be done and everything should be moved to use spudUser now.
  useEffect(() => {
    if (!spudUserId) return;

    const selectedEmployee = employees.find(({ id }) => id === spudUserId);
    setValue('name', selectedEmployee?.name ?? '');
  }, [spudUserId, employees, setValue]);

  const lastEstimateType = useRef(resource.estimateType);
  useEffect(() => {
    if (estimateType === lastEstimateType.current) return;

    lastEstimateType.current = estimateType;
    setValue('estimate', '');
    setValue('estimateInterval', estimateType === 'fixed' ? 'total_project' : '');
  }, [estimateType, setValue]);

  const handleSave = useCallback(
    attributes => dispatch(saveResource({ id: resource.id, ...attributes }, isNewResource, uuid)),
    [dispatch, isNewResource, uuid, resource.id]
  );

  const handleCancel = useCallback(() => {
    if (isNewResource) return removeNewResource(uuid);
    return toggleEditing();
  }, [uuid, isNewResource, removeNewResource, toggleEditing]);

  const isRoleLengthInvalid = useMemo(() => role && role.length > MAX_ROLE_LENGTH, [role]);

  const roleSelectStyle = useMemo(() => {
    if (!isRoleLengthInvalid) return {};
    return { borderColor: theme.colors.lightRed };
  }, [isRoleLengthInvalid]);

  const errors = combineErrors(formContext.formState.errors, {});

  return (
    <FormProvider {...formContext}>
      <tr className={classes.tableRow}>
        <td className={classes.resource}>
          <ResourcesSelect
            name="spudUserId"
            errors={errors.spudUser}
            className={classes.resource}
            employees={employees}
          />
        </td>
        <td className={classes.role}>
          <RoleSelect name="role" className={classes.role} roles={roles} style={roleSelectStyle} errors={errors.role} />
          {isRoleLengthInvalid && (
            <div style={{ color: theme.colors.lightRed }}>
              The role length is more than {MAX_ROLE_LENGTH} characters.
            </div>
          )}
        </td>
        {showRate && (
          <td className={classes.billingRate}>
            <Input
              {...register('rate')}
              type="number"
              step="any"
              placeholder="Rate"
              block
              error={errors.rate}
              min="0"
            />
          </td>
        )}
        <td className={classes.estimate}>
          <div className={classes.estimateContainer}>
            <EstimateTypesSelect
              name="estimateType"
              className={classes.estimateInput}
              errors={errors.estimateType}
              placeholder="Est. Type"
            />
            {['fixed', 'partial'].includes(estimateType) && (
              <Input
                {...register('estimate')}
                errors={errors.estimate}
                type="number"
                className={classes.estimateInput}
                placeholder={estimateType === 'partial' ? '%' : 'Hours'}
                min="0"
                block
              />
            )}
            {estimateType === 'fixed' && (
              <HoursIntervalSelect
                name="estimateInterval"
                errors={errors.estimateInterval}
                className={classes.estimateInput}
                placeholder="Est. Interval"
              />
            )}
          </div>
        </td>
        <td className={classes.interval}>
          <DatePicker
            minDate={dayjs(engagement.startDate).toDate()}
            name="startDate"
            errors={errors.startDate}
            showIcon={false}
            placeholder="Select Date"
            popoverTargetAttachment="bottom left"
          />
        </td>
        <td className={classes.interval}>
          <DatePicker
            maxDate={engagement.endDate && dayjs(engagement.endDate).toDate()}
            name="endDate"
            errors={errors.endDate}
            showIcon={false}
            placeholder="Select Date"
            popoverTargetAttachment="bottom left"
          />
        </td>
        {showControls && (
          <td className={classes.controls}>
            <a onClick={handleSubmit(handleSave)} style={{ marginRight: 10 }}>
              <SaveIcon disabled={!formContext.formState.isValid} />
            </a>
            <a onClick={handleCancel}>{isNewResource ? <DestroyIcon /> : <CancelIcon />}</a>
          </td>
        )}
      </tr>
    </FormProvider>
  );
};

EditResourceRow.propTypes = {
  classes: CustomPropTypes.classes,
  engagement: CustomPropTypes.engagement.isRequired,
  employees: CustomPropTypes.employees.isRequired,
  newResource: PropTypes.bool,
  resource: CustomPropTypes.resource,
  roles: CustomPropTypes.resourceRoles.isRequired,
  showControls: PropTypes.bool,
  showRate: PropTypes.bool,
  removeNewResource: PropTypes.func,
  toggleEditing: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};
