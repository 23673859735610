import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Button from 'components/Button';
import { goBack } from 'utils/navigation';
import CustomPropTypes from 'utils/propTypes';

import styles from './SaveCancelButtons.styles';

const SaveCancelButtons = ({ onSave, onCancel = goBack, classes }) => (
  <div className={classes.buttons}>
    <Button theme="cancel" onClick={onCancel}>
      CANCEL
    </Button>
    <Button type="submit" onClick={onSave} className={classes.saveButton}>
      SAVE
    </Button>
  </div>
);

SaveCancelButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(SaveCancelButtons);
