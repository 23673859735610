import * as theme from 'theme';

const badgeStyles = {
  Badge: {
    display: 'inline-block',
    padding: [0, 4, 0, 3],
    'min-width': 20,
    height: 20,
    'border-radius': 10,
    background: theme.colors.lightRed,
    'text-align': 'center',
    font: {
      size: '12px',
      weight: 'bold',
    },
    'line-height': '21px',
    color: 'white',
  },
};

export default badgeStyles;
