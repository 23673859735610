import Index from './ClientsIndex';
import New from './NewClient';
import Show from './ShowClient';

export const errorFields = {
  name: 'client name',
  addressLine1: 'street address',
  addressLine2: 'extended address',
  addressCity: 'city',
  addressState: 'state',
  addressZipcode: 'zip',
  billingTerms: 'billing terms',
  billingInstructions: 'billing instructions',
  salesRep: 'sales rep',
};

export default { Index, New, Show };
