import { all, takeLatest, throttle } from 'redux-saga/effects';

import { fetchEngagementProjectStatuses, saveProjectStatus } from 'actions/projectStatuses';

import fetchEngagementProjectStatusesSaga from './fetchEngagementProjectStatuses';
import saveProjectStatusSaga from './saveProjectStatus';

export default function* projectStatusesSaga() {
  yield all([takeLatest(fetchEngagementProjectStatuses, fetchEngagementProjectStatusesSaga)]);
  yield throttle(2000, saveProjectStatus, saveProjectStatusSaga);
}
