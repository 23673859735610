import * as theme from 'theme';

export default {
  TabGroup: {
    display: 'flex',
    'list-style': 'none',
    padding: 0,
    margin: 0,
    height: theme.page.header.height,
  },
};
