import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import office365Logo from 'assets/images/office-365-logo@2x.png';
import CustomPropTypes from 'utils/propTypes';

import styles from './Login.styles';

const Login = ({ classes, handleSignIn }) => (
  <div className={classes.loginWrapper}>
    <div className={classes.Login}>
      <h1 className={classes.loginTitle}>Moser Engagements</h1>
      <div className={classes.azureLogin} onClick={handleSignIn}>
        <img src={office365Logo} alt="Office 365" />
        <span>Sign in with Office 365</span>
      </div>
    </div>
  </div>
);

Login.propTypes = {
  classes: CustomPropTypes.classes,
  handleSignIn: PropTypes.func.isRequired,
};

export default injectSheet(styles)(Login);
