import packageInfo from '../../package.json';

const { REACT_APP_ROLLBAR_TOKEN, REACT_APP_ROLLBAR_ENV, NODE_ENV } = process.env;

const rollbarConfig = {
  // enabled if `REACT_APP_ROLLBAR_TOKEN` is defined and not an empty string
  enabled: !!REACT_APP_ROLLBAR_TOKEN,
  accessToken: REACT_APP_ROLLBAR_TOKEN,
  environment: REACT_APP_ROLLBAR_ENV || NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  version: packageInfo.version,
};

export default rollbarConfig;
