import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import SearchButton from 'components/buttons/SearchButton';
import Loader from 'components/Loader';
import Page from 'components/Page';
import SearchBox from 'components/SearchBox';
import Tab from 'components/Tab';
import TabGroup from 'components/TabGroup';
import UsersTable from 'components/tables/UsersTable';

const UsersIndex = ({ users, filter, isFetching, searching, query, updateQuery, updateFilter, toggleSearch }) => {
  const headerAdditions = (
    <div style={{ display: 'flex' }}>
      <TabGroup value={filter} onChange={updateFilter}>
        <Tab value="">All</Tab>
        <Tab value="engagement-manager">Engagement Managers</Tab>
        <Tab value="sales-rep">Sales Reps</Tab>
        <Tab value="admin">Admins</Tab>
      </TabGroup>
      <SearchButton onClick={toggleSearch} active={searching} />
    </div>
  );

  return (
    <Page title="Users" headerAdditions={headerAdditions}>
      {searching && <SearchBox placeholder="Search by name or email" onChange={updateQuery} value={query} />}
      {isFetching ? <Loader /> : <UsersTable users={orderBy(users, 'nameReverse')} />}
    </Page>
  );
};

UsersIndex.propTypes = {
  filter: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  query: PropTypes.string.isRequired,
  searching: PropTypes.bool,
  toggleSearch: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  users: PropTypes.array,
};

export default UsersIndex;
