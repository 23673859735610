import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import injectSheet from 'react-jss';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { addNewResources } from 'actions/resources';
import Button from 'components/Button';
import { UncontrolledSelect as Select } from 'components/inputs/Select';
import { LoaderDots } from 'components/LoaderDots';
import { actions as toastActions } from 'modules/toast';
import { fetchEngagementResources, fetchEngagements } from 'utils/api';
import CustomPropTypes from 'utils/propTypes';

const { createToast } = toastActions;

const newResourcePayloadFields = ['spudUserId', 'name', 'role', 'rate', 'estimateType', 'estimate', 'estimateInterval'];

const Container = styled.div`
  display: inline-flex;
`;

const useResourceImport = engagement => {
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { watch } = useFormContext();
  const lastClientIdRef = useRef(null);
  const dispatch = useDispatch();

  const clientId = watch('clientId');

  useEffect(() => {
    const loadEngagementOptions = async () => {
      setLoading(true);

      try {
        const { engagements } = await fetchEngagements({ client_id: clientId });
        const newOptions = engagements
          .filter(e => e.id !== engagement.id)
          .map(({ name, id }) => ({ label: name, value: id }));

        setOptions(newOptions);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (engagement && clientId !== lastClientIdRef.current) {
      lastClientIdRef.current = clientId;
      loadEngagementOptions();
    }
  }, [engagement, clientId, isLoading]);

  const handleInputChange = useCallback(id => setSelectedId(id), []);

  const handleImport = useCallback(async () => {
    try {
      const { id: engagementId } = engagement ?? {};
      const params = { account_enabled: true };

      if (!engagementId || !selectedId) return;

      setLoading(true);

      const [currentResult, selectedResult] = await Promise.all([
        fetchEngagementResources(engagementId, params),
        fetchEngagementResources(selectedId, params),
      ]);

      const { resources: currentResources } = currentResult;
      const { resources: selectedResources } = selectedResult;

      const notCurrentResourceUserAndRole = selectedEntry => {
        return !currentResources.some(currentEntry => {
          const isUserMatch = selectedEntry.spudUserId === currentEntry.spudUserId;
          const isRoleMatch = selectedEntry.role === currentEntry.role;

          return isUserMatch && isRoleMatch;
        });
      };

      const resourcesToImport = selectedResources.filter(notCurrentResourceUserAndRole);

      if (!resourcesToImport.length) {
        dispatch(createToast('info', { title: 'No Resources', message: 'No unique resources to import.' }));
        setSelectedId(null);

        return;
      }

      dispatch(addNewResources(resourcesToImport.map(resource => pick(resource, newResourcePayloadFields))));
      dispatch(createToast('success', { message: `Imported ${resourcesToImport.length} resource(s).` }));
      setSelectedId(null);
    } catch (error) {
      dispatch(createToast('alert', { message: 'Resource import failed.' }));
    } finally {
      setLoading(false);
    }
  }, [dispatch, engagement, selectedId]);

  const registerInput = useCallback(
    () => ({
      options,
      disabled: isLoading,
      value: selectedId,
      onChange: handleInputChange,
    }),
    [handleInputChange, isLoading, options, selectedId]
  );

  const registerSubmit = useCallback(
    () => ({
      disabled: isLoading || !selectedId,
      onClick: handleImport,
    }),
    [isLoading, selectedId, handleImport]
  );

  return { isLoading, registerInput, registerSubmit };
};

const ResourceImport = ({ engagement, style, classes, ...props }) => {
  const { isLoading, registerInput, registerSubmit } = useResourceImport(engagement);

  return (
    <Container style={style}>
      <Select placeholder="Select engagement..." {...registerInput()} {...props} />
      <Button className={classes.Button} {...registerSubmit()}>
        {isLoading ? <LoaderDots /> : 'Import'}
      </Button>
    </Container>
  );
};

ResourceImport.propTypes = {
  engagement: CustomPropTypes.engagement,
  style: PropTypes.object,
  classes: CustomPropTypes.classes,
};

const buttonStyles = {
  Button: {
    padding: '0 30px',
  },
};

export default injectSheet(buttonStyles)(ResourceImport);
