// eslint-disable-next-line import/no-named-as-default
import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import { APP_NAME, APP_VERSION, IS_DEV } from 'utils/common';

const { REACT_APP_MIXPANEL_TOKEN } = process.env;

const plugins = [];

if (REACT_APP_MIXPANEL_TOKEN) {
  const plugin = mixpanelPlugin({
    token: REACT_APP_MIXPANEL_TOKEN,
    options: { debug: IS_DEV, ignore_dnt: IS_DEV },
  });

  plugins.push(plugin);
}

export const analytics = Analytics({
  app: APP_NAME,
  version: APP_VERSION,
  debug: IS_DEV,
  plugins,
});
