import find from 'lodash/find';
import PropTypes from 'prop-types';

import ClientFields from 'components/fields/ClientFields';
import FormGroup from 'components/FormGroup';
import { billingTermsTypes } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

const EditableClientInfo = ({ editing, client, errors }) =>
  editing ? (
    <ClientFields client={client} errors={errors} />
  ) : (
    <div>
      <FormGroup label="Client Name">
        <span>{client.name}</span>
      </FormGroup>
      <FormGroup label="Address">
        <span>{client.addressLine1}</span>
        {client.addressLine2 && <span>, #{client.addressLine2}</span>}
        <br />
        <span>
          {client.addressCity}, {client.addressState} {client.addressZipcode}
        </span>
      </FormGroup>
      <FormGroup label="Billing Terms">
        <span>
          {client.billingTerms ? find(billingTermsTypes, term => term.value === client.billingTerms).label : 'N/A'}
        </span>
      </FormGroup>
      <FormGroup label="Billing Instructions">
        <pre>{client.billingInstructions || 'N/A'}</pre>
      </FormGroup>
      <FormGroup label="Sales Representative">
        <span>{client.salesRep ? client.salesRep.name : 'Unassigned'}</span>
      </FormGroup>
    </div>
  );

EditableClientInfo.propTypes = {
  editing: PropTypes.bool,
  errors: PropTypes.object,
  client: CustomPropTypes.client.isRequired,
};

export default EditableClientInfo;
