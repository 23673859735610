export default {
  resourceManager: {
    marginTop: 20,
  },
  resourceTable: {
    '& tr th, & tr td': {
      padding: 10,
    },
  },
  addResourceButton: {
    marginTop: 20,
  },
};
