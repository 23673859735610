import { RHFSelect, UncontrolledSelect } from 'components/inputs/Select';
import { useClients } from 'hooks/referenceData/useClients';

const ClientSelect = props => {
  const { dataAsOptions, isLoading } = useClients();
  return <RHFSelect isLoading={isLoading} options={dataAsOptions} placeholder="Select client" {...props} />;
};

ClientSelect.propTypes = UncontrolledSelect.propTypes;

export default ClientSelect;
