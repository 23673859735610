import { fetchUsers, fetchUsersSuccess, userInvalid } from 'actions/users';
import { createGenericReducer } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'users',
  actionTypes: {
    fetch: fetchUsers,
    fetchSuccess: fetchUsersSuccess,
    invalid: userInvalid,
  },
});
