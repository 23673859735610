export default {
  statuses: {
    maxHeight: 200,
    overflow: 'auto',
  },
  statusTable: {
    marginTop: 40,
    '& tr th, & tr td': {
      padding: 15,
      '&:first-child': {
        paddingLeft: 0,
        width: 130,
      },
    },
    '& tr': {
      border: '0 !important',
    },
  },
};
