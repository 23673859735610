const sectionColor = '#F4F4F4';

export default {
  Section: {
    border: `1px solid ${sectionColor}`,
    'margin-bottom': 10,
  },
  header: {
    'background-color': sectionColor,
    display: 'flex',
    height: 60,
    'align-items': 'center',
  },
  title: {
    margin: 0,
    'padding-left': 30,
    'font-weight': 'normal',
    'font-size': '20px',
    flex: '1 1 100%',
  },
  headerAdditions: {
    flex: '1 1 100%',
    height: '100%',
    display: 'flex',
    'justify-content': 'flex-end',
  },
  body: {
    padding: '30px',
  },
};
