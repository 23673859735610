import classnames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Badge from 'components/Badge';
import CustomPropTypes from 'utils/propTypes';

import styles from './Tab.styles';

const Tab = ({ value, label, children, active, onClick, badge = '', classes }) => (
  <li className={classnames(classes.Tab, { [classes.active]: active })} onClick={onClick}>
    {label || children || value}
    {active && <div className={classes.underline} />}
    {badge.length > 0 && <Badge className={classes.badge}>{badge}</Badge>}
  </li>
);

Tab.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  badge: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Tab);
