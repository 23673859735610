import { RHFSelect, UncontrolledSelect } from 'components/inputs/Select';
import { useDepartments } from 'hooks/referenceData/useDepartments';

const DepartmentSelect = props => {
  const { dataAsOptions, isLoading } = useDepartments();
  return <RHFSelect isLoading={isLoading} options={dataAsOptions} placeholder="Select department" {...props} />;
};

DepartmentSelect.propTypes = UncontrolledSelect.propTypes;

export default DepartmentSelect;
