import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import { PureComponent } from 'react';
import styled from 'styled-components';

import Icon from 'components/Icon';
import Table from 'components/Table';
import { colors } from 'theme';
import { pushRoute } from 'utils/navigation';
import CustomPropTypes from 'utils/propTypes';
import dayjs from 'lib/dayjs';

const CheckMark = () => <Icon type="check-circle" color={colors.green} />;

const TableHeader = styled.th`
  cursor: pointer;
  white-space: nowrap;
`;
const SortingIcon = styled.i`
  font-size: 14px;
  margin-left: 5px;
`;

const currentYear = dayjs().year();

const SortIndicator = ({ sorting, direction }) =>
  sorting ? (
    direction === 1 ? (
      <SortingIcon className="fa fa-caret-up" />
    ) : (
      <SortingIcon className="fa fa-caret-down" />
    )
  ) : null;

class UsersTable extends PureComponent {
  state = {
    users: [],
    sortKey: 'nameReverse',
    sortDirection: 1,
  };

  componentDidMount() {
    this.updateUsers(this.props.users);
  }

  updateUsers = users => this.setState({ users });

  handleSort = sortColumn => {
    const { users, sortDirection, sortKey } = this.state;
    const direction = sortDirection === 1 ? 'desc' : 'asc';
    let sortedUsers = [];
    let resetSort = false;

    if (sortKey !== sortColumn) resetSort = true;

    if (includes(['engagement-manager', 'sales-rep', 'admin'], sortColumn)) {
      sortedUsers = orderBy(
        users,
        [i => i.roles.includes(sortColumn), 'nameReverse'],
        resetSort ? ['asc'] : [direction]
      );
    } else if (includes(['currentPtoBalance'], sortColumn)) {
      sortedUsers = orderBy(users, [i => parseFloat(i.currentPtoBalance)], resetSort ? ['asc'] : [direction]);
    } else {
      sortedUsers = orderBy(users, [sortColumn], resetSort ? ['asc'] : [direction]);
    }

    this.updateUsers(sortedUsers);
    this.setState({ sortKey: sortColumn, sortDirection: resetSort ? 1 : sortDirection * -1 });
  };

  render() {
    const { sortKey, sortDirection, users } = this.state;

    return users.length ? (
      <Table scrollable>
        <thead>
          <tr>
            <TableHeader onClick={() => this.handleSort('nameReverse')}>
              Name <SortIndicator sorting={sortKey === 'nameReverse'} direction={sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => this.handleSort('email')}>
              Email <SortIndicator sorting={sortKey === 'email'} direction={sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => this.handleSort('startDate')}>
              Start Date <SortIndicator sorting={sortKey === 'startDate'} direction={sortDirection} />
            </TableHeader>
            <TableHeader className="center" onClick={() => this.handleSort('currentPtoBalance')}>
              {currentYear} Hours Requested{' '}
              <SortIndicator sorting={sortKey === 'currentPtoBalance'} direction={sortDirection} />
            </TableHeader>
            <TableHeader className="center" onClick={() => this.handleSort('engagement-manager')}>
              Engagement Manager <SortIndicator sorting={sortKey === 'engagement-manager'} direction={sortDirection} />
            </TableHeader>
            <TableHeader className="center" onClick={() => this.handleSort('sales-rep')}>
              Sales Rep <SortIndicator sorting={sortKey === 'sales-rep'} direction={sortDirection} />
            </TableHeader>
            <TableHeader className="center" onClick={() => this.handleSort('admin')}>
              Admin <SortIndicator sorting={sortKey === 'admin'} direction={sortDirection} />
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id} onClick={() => pushRoute(`/users/${user.id}`)}>
              <td>{user.nameReverse}</td>
              <td>{user.email}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{user.startDate}</td>
              <td className="center">{user.currentPtoBalance}</td>
              <td className="center">{user.roles.includes('engagement-manager') && <CheckMark />}</td>
              <td className="center">{user.roles.includes('sales-rep') && <CheckMark />}</td>
              <td className="center">{user.roles.includes('admin') && <CheckMark />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <div style={{ padding: '20px' }}>There are no users to show 😭</div>
    );
  }
}

UsersTable.propTypes = {
  users: CustomPropTypes.users.isRequired,
};

export default UsersTable;
