export const nestedErrors = (errors, key) => {
  const prefix = `${key}.`;

  return Object.keys(errors)
    .filter(prop => prop.indexOf(prefix) === 0)
    .reduce(
      (retErrors, prop) => ({
        ...retErrors,
        [prop.slice(prefix.length)]: errors[prop],
      }),
      {}
    );
};
