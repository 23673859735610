import * as theme from 'theme';

export default {
  container: {
    extend: theme.inlineContainer,
  },
  datePicker: {
    extend: theme.formControl,
  },
  errors: {
    borderColor: theme.colors.darkRed,
    transition: [['border-color', '0']],
    '&:focus': {
      borderColor: theme.colors.darkRed,
    },
  },
  calendarIcon: {
    color: theme.colors.lightBlue,
    'margin-left': 10,
    'font-size': '22px',
    cursor: 'pointer',
  },
};
