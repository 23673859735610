import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { getCurrentUser, getIsFetching } from 'selectors/currentUser';
import CustomPropTypes from 'utils/propTypes';

import { LoaderView } from './LoaderView';
import querystring from 'query-string';

const AuthGate = ({ loginComponent: LoginComponent, children }) => {
  const currentUser = useSelector(getCurrentUser);
  const isLoading = useSelector(getIsFetching);
  const location = useLocation();

  if (!currentUser) {
    const { pathname, search } = location;

    return (
      <Switch>
        <Route path="/login" render={() => (isLoading ? <LoaderView /> : <LoginComponent />)} />
        <Redirect
          to={{
            pathname: '/login',
            search: `?redirect=${pathname}${search}`,
          }}
        />
      </Switch>
    );
  }

  const { redirect = '/' } = querystring.parse(location.search);

  return (
    <Switch>
      <Route path="/login" render={() => <Redirect to={redirect} />} />
      <Route render={() => children} />
    </Switch>
  );
};

AuthGate.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: CustomPropTypes.user,
  isLoading: PropTypes.bool,
  loginComponent: PropTypes.any.isRequired,
};

export default AuthGate;
