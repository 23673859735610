import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import injectSheet from 'react-jss';

import CancelButton from 'components/buttons/CancelButton';
import EditButton from 'components/buttons/EditButton';
import CustomPropTypes from 'utils/propTypes';

import styles from './Section.styles';

const ToggleEditButton = ({ editing, ...props }) => {
  const ButtonComponent = editing ? CancelButton : EditButton;
  return <ButtonComponent {...props} />;
};

const Section = ({
  title,
  headerAdditions,
  children,
  editable,
  editing,
  toggleEditing,
  editChildren,
  showChildren,
  classes,
}) => (
  <section className={classes.Section}>
    {(title || headerAdditions) && (
      <header className={classes.header}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {(headerAdditions || editable) && (
          <div className={classes.headerAdditions}>
            {editable && <ToggleEditButton editing={editing} onClick={toggleEditing} />}
            {headerAdditions}
          </div>
        )}
      </header>
    )}
    <div className={classes.body}>
      {editable ? Children.map(children, child => cloneElement(child, { editing })) : children}
    </div>
  </section>
);

Section.propTypes = {
  editable: PropTypes.bool,
  editing: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  editChildren: PropTypes.func,
  headerAdditions: PropTypes.node,
  showChildren: PropTypes.func,
  toggleEditing: PropTypes.func.isRequired,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Section);
