import dayjs from 'lib/dayjs';
import injectSheet from 'react-jss';

import Table from 'components/Table';
import { pushRoute } from 'utils/navigation';
import CustomPropTypes from 'utils/propTypes';

import styles from './ClientsTable.styles';

const ClientsTable = ({ clients, classes }) =>
  clients.length ? (
    <Table className={classes.Table}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Sales Rep</th>
          <th className="center"># Open Engagements</th>
          <th className="center">Last Modified</th>
        </tr>
      </thead>
      <tbody>
        {clients.map(client => (
          <tr key={client.id} onClick={() => pushRoute(`/clients/${client.id}`)}>
            <td>{client.name}</td>
            <td>{client.salesRep ? client.salesRep.name : 'House Acct.'}</td>
            <td className="center">{client.openEngagements}</td>
            <td className="center">{dayjs(client.updatedAt).format('MM/DD/YYYY')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <div style={{ padding: '20px' }}>There are no clients to show 😭</div>
  );

ClientsTable.propTypes = {
  clients: CustomPropTypes.clients.isRequired,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(ClientsTable);
