import { billingTermsTypes } from 'utils/common';

import { RHFSelect } from './Select';

const billingTermsDefault = '30';

const BillingTermsSelect = props => (
  <RHFSelect
    width={200}
    {...props}
    defaultValue={props.defaultValue || billingTermsDefault}
    options={billingTermsTypes}
  />
);

export default BillingTermsSelect;
