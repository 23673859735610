import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ErrorList from 'components/ErrorList';
import FormGroup from 'components/FormGroup';
import { DatePicker, Select, TextArea } from 'components/inputs';
import DepartmentSelect from 'components/inputs/DepartmentSelect';
import Input from 'components/inputs/Input';
import { getCurrentUser } from 'selectors/currentUser';
import CustomPropTypes from 'utils/propTypes';
import { engagementTypes, objectToOptions } from 'utils/common';

const errorFields = {
  name: 'name',
  department: 'department',
  projectId: 'project #',
  startDate: 'start date',
  endDate: 'end date',
  cmName: "client manager's name",
  cmEmail: "client manager's email",
  cmPhone: "client manager's phone #",
  description: 'project description',
};

const ProjectInfoFields = ({ engagement, errors = {}, setDepartmentId }) => {
  const currentUser = useSelector(getCurrentUser);
  const { register } = useFormContext();

  return (
    <div>
      <ErrorList errors={errors} fields={errorFields} />
      <FormGroup label="Project Name">
        <Input {...register('name')} errors={errors.name} placeholder="Enter project name" width={380} />
      </FormGroup>
      <FormGroup label="Department">
        <DepartmentSelect name="departmentId" errors={errors.department} onChange={setDepartmentId} width={380} />
      </FormGroup>
      <FormGroup label="Project #">
        {currentUser.roles.includes('admin') ? (
          <Input {...register('projectId')} errors={errors.projectId} placeholder="Project #" />
        ) : (
          <span>{engagement && engagement.projectId ? engagement.projectId : 'Not yet created'}</span>
        )}
      </FormGroup>
      <FormGroup label="Project Start Date">
        <DatePicker name="startDate" errors={errors.startDate} popoverTargetAttachment="bottom left" />
      </FormGroup>
      <FormGroup label="Project End Date">
        <DatePicker name="endDate" errors={errors.endDate} isClearable={true} popoverTargetAttachment="bottom left" />
        <em style={{ color: '#999', fontSize: '14px' }}>Leave blank if open ended</em>
      </FormGroup>
      <FormGroup label="Client Manager">
        <FormGroup>
          <Input {...register('cmName')} errors={errors.cmName} placeholder="Client Manager name" width={200} />
        </FormGroup>
        <FormGroup>
          <Input {...register('cmEmail')} errors={errors.cmEmail} placeholder="Email" width={200} />
          <Input {...register('cmPhone')} errors={errors.cmPhone} placeholder="Phone #" width={200} />
        </FormGroup>
      </FormGroup>
      <FormGroup label="Project Description">
        <TextArea {...register('description')} errors={errors.description} />
      </FormGroup>
      <FormGroup label="Engagement Type">
        <Select
          name="engagementType"
          errors={errors.engagementType}
          options={objectToOptions(engagementTypes)}
          placeholder="Select type"
          width={200}
        />
      </FormGroup>
    </div>
  );
};

ProjectInfoFields.propTypes = {
  engagement: CustomPropTypes.engagement,
  errors: PropTypes.object,
  setDepartmentId: PropTypes.number,
};

export default ProjectInfoFields;
