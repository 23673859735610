import dayjs from 'lib/dayjs';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { EngagementsTable } from './EngagementsTable';
import CustomPropTypes from 'utils/propTypes';

const formatters = {
  client: item => item.name,
  department: item => item.name,
  engagementManager: item => item.name,
};

const EngagementsTableContainer = props => {
  const [engagements, setEngagements] = useState([]);

  const updateEngagements = (engagements = []) => {
    const { sortDirection, sortKey, sortType } = props;
    const formatter = formatters[props.sortKey] || (item => item);

    let sorted = [];

    if (sortType === 'date') {
      sorted = [
        ...engagements.sort((a, b) => {
          const aMoment = dayjs(formatter(a[sortKey]), 'YYYY-MM-DD');
          const bMoment = dayjs(formatter(b[sortKey]), 'YYYY-MM-DD');

          if (!aMoment.isValid()) {
            return 1 * sortDirection;
          } else if (!bMoment.isValid()) {
            return -1 * sortDirection;
          }

          return aMoment.diff(bMoment, 'days') * sortDirection;
        }),
      ];
    } else {
      sorted = [
        ...engagements.sort((a, b) => {
          const aItem = formatter(a[sortKey]);
          const bItem = formatter(b[sortKey]);

          if (!aItem) {
            return 1 * sortDirection;
          } else if (!bItem) {
            return -1 * sortDirection;
          }

          const aString = aItem.toLowerCase();
          const bString = bItem.toLowerCase();

          return aString < bString ? -1 * sortDirection : bString < aString ? 1 * sortDirection : 0;
        }),
      ];
    }

    return { engagements: sorted };
  };
  const handleEngagementUpdate = (nextEngagements, resetCache = false) => {
    const { engagements } = updateEngagements(nextEngagements, resetCache);
    setEngagements(engagements);
  };

  const sortBy = (sortKey, sortType = 'string') => {
    const sortDirection = props.sortKey === sortKey ? props.sortDirection * -1 : 1;
    props.onSortDirectionChange(sortDirection);
    props.onSortKeyChange(sortKey);
    props.onSortyTypeChange(sortType);
    handleEngagementUpdate(props.engagements, false);
  };

  useEffect(() => {
    handleEngagementUpdate(props.engagements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.engagements]);

  return (
    <EngagementsTable
      engagements={engagements}
      sortBy={sortBy}
      sortDirection={props.sortDirection}
      sortKey={props.sortKey}
    />
  );
};

EngagementsTableContainer.propTypes = {
  engagements: CustomPropTypes.engagements,
  filter: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  engagement_manager: PropTypes.number,
  sales_rep: PropTypes.number,
  onSortDirectionChange: PropTypes.func.isRequired,
  onSortKeyChange: PropTypes.func.isRequired,
  onSortyTypeChange: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  searching: PropTypes.bool,
  sortCache: PropTypes.object.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  sortDirection: PropTypes.number.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateProperty: PropTypes.func,
  updateQuery: PropTypes.func.isRequired,
};

export default EngagementsTableContainer;
