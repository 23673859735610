import PropTypes from 'prop-types';

const CustomPropTypes = {};

CustomPropTypes.classes = PropTypes.object.isRequired;

/* react-jss stylesheet */
CustomPropTypes.stylesheet = PropTypes.shape({
  classes: PropTypes.object.isRequired,
});

/* react-router router */
CustomPropTypes.router = PropTypes.shape({
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
});

CustomPropTypes.billingType = PropTypes.oneOf(['fixed', 'variable']);

/* tab */
CustomPropTypes.tab = PropTypes.shape({
  value: PropTypes.any.isRequired,
  label: PropTypes.node,
  badge: PropTypes.node,
});

/* clients */
CustomPropTypes.client = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  salesRep: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  updatedAt: PropTypes.string.isRequired,
});

/* documents */
CustomPropTypes.document = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  uploadedBy: PropTypes.string,
});

CustomPropTypes.clients = PropTypes.arrayOf(CustomPropTypes.client);

/* engagements */
CustomPropTypes.engagement = PropTypes.shape({
  projectId: PropTypes.string,
  status: PropTypes.object,
  client: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

CustomPropTypes.engagements = PropTypes.arrayOf(CustomPropTypes.engagement);

/* resources */
CustomPropTypes.resource = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  role: PropTypes.string,
  rate: PropTypes.string,
  hoursEstimate: PropTypes.number,
  hoursInterval: PropTypes.string,
});

CustomPropTypes.resources = PropTypes.arrayOf(CustomPropTypes.resource);

/* project statuses */
CustomPropTypes.projectStatus = PropTypes.shape({
  id: PropTypes.number,
  reason: PropTypes.string,
  color: PropTypes.string,
});

CustomPropTypes.projectStatuses = PropTypes.arrayOf(CustomPropTypes.projectStatus);

/* users */
CustomPropTypes.user = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
});

CustomPropTypes.users = PropTypes.arrayOf(CustomPropTypes.user);

/* roles */
CustomPropTypes.role = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  title: PropTypes.string.isRequired,
});

CustomPropTypes.roles = PropTypes.arrayOf(CustomPropTypes.role);

/* resource roles */
CustomPropTypes.resourceRole = PropTypes.string;
CustomPropTypes.resourceRoles = PropTypes.arrayOf(CustomPropTypes.resourceRole);

/* employees */
CustomPropTypes.employee = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  managerAzureId: PropTypes.string,
});

CustomPropTypes.employees = PropTypes.arrayOf(CustomPropTypes.employee);

export default CustomPropTypes;
