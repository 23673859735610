import { createGlobalStyle } from 'styled-components';

import * as theme from 'theme';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, input {
    font-family: "Lato", sans-serif;
  }
  
  body {
    padding: 0;
    margin: 0;
    background: ${theme.colors.background};
    color: ${theme.colors.darkBlue};
    min-width: 1000;
  }

  a, a:link, a:visited, a:hover, a:active {
    text-decoration: none;
    color: ${theme.colors.lightBlue};
    cursor: pointer;
  }

  .red {
    color: ${theme.colors.darkRed};
  }

  .yellow {
    color: ${theme.colors.orange};
  }

  .green {
    color: ${theme.colors.green};
  }
`;

/* html,
  body,
  * {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100vh;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }

  a {
    color: ${colors.lightBlue};
  }

  ::-webkit-scrollbar {
    width: 11px;
    background: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    opacity: 0.7;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.75;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.lightBlue};
  }

  .download-table-xls-button {
    outline: none;
  } */
