export default {
  container: {
    display: 'flex',
    'justify-content': 'center',
    margin: [120, 30, 60],
    'text-align': 'center',
  },
  statusIcon: {
    margin: 0,
    fontSize: '100px',
    lineHeight: '100px',
  },
  statusText: {
    margin: 0,
    font: {
      size: '46px',
      weight: 'bold',
    },
    textTransform: 'capitalize',
  },
  statusCode: {
    margin: 0,
    fontSize: '28px',
  },
};
