import { useReferenceQuery } from 'hooks/useReferenceQuery';
import { fetchBillingContacts } from '../../utils/api';

const labelFormatter = contact => `${contact.name} (${contact.email})`;

export const useBillingContacts = clientId => {
  const queryName = ['billingContacts', clientId];

  const queryMethod = async () => {
    const { billingContacts } = await fetchBillingContacts(clientId);
    return billingContacts;
  };

  const options = {
    formatOptions: { labelFormatter },
    queryOptions: { enabled: !!clientId },
  };

  const billingContactsQuery = useReferenceQuery(queryName, queryMethod, options);
  return billingContactsQuery;
};
