import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import injectSheet from 'react-jss';
import { useDispatch } from 'react-redux';

import * as actions from 'actions/projectStatuses';
import Button from 'components/Button';
import ErrorList from 'components/ErrorList';
import FormGroup from 'components/FormGroup';
import Input from 'components/inputs/Input';
import CustomPropTypes from 'utils/propTypes';

import styles from './ProjectStatusFields.styles';

const errorFields = { color: 'Status' };

const ProjectStatusFields = ({ engagement, classes, errors = {} }) => {
  const formContext = useForm({
    defaultValues: {
      color: engagement?.status?.color ?? 'green',
      reason: '',
    },
  });

  useEffect(() => formContext.reset(), [engagement, formContext]);

  const reason = formContext.watch('reason');
  const dispatch = useDispatch();
  const saveProjectStatus = useCallback(values => dispatch(actions.saveProjectStatus(values)), [dispatch]);

  return (
    <div>
      <FormProvider {...formContext}>
        <ErrorList errors={errors} fields={errorFields} />
        <FormGroup label="Add a Note">
          <Input {...formContext.register('reason')} width={380} />
          <Button
            type="button"
            onClick={formContext.handleSubmit(saveProjectStatus)}
            label="submit"
            className={classes.statusSubmit}
            disabled={reason.length === 0}
          />
        </FormGroup>
      </FormProvider>
    </div>
  );
};

ProjectStatusFields.propTypes = {
  engagement: CustomPropTypes.engagement.isRequired,
  classes: CustomPropTypes.classes,
  errors: PropTypes.object,
};

export default injectSheet(styles)(ProjectStatusFields);
