import { call, takeEvery } from 'redux-saga/effects';
import { engagementInvalid } from 'actions/engagements';
import { clientInvalid } from 'actions/clients';
import { userInvalid } from 'actions/users';

function* scrollToTop() {
  yield call(window.scrollTo, 0, 0);
}

export default function* validationSaga() {
  yield takeEvery([engagementInvalid, clientInvalid, userInvalid], scrollToTop);
}
