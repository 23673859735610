import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export { history };

export const pushRoute = (...args) => history.push(...args);
export const replaceRoute = (...args) => history.replace(...args);
export const goBack = (...args) => history.goBack(...args);

export const getHostAndApiUrl = () => {
  let host, apiUrl;
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    host = 'localhost:3005';
    apiUrl = 'localhost:3000';
  } else if (hostname === 'engagements.moserlab.net') {
    host = apiUrl = 'engagements.moserlab.net';
  } else if (hostname === 'engagements.moserit.com') {
    host = apiUrl = 'engagements.moserit.com';
  }
  return { host, apiUrl };
};

export const reload = () => {
  window.location.reload();
};

export const navigateTo = location => {
  window.location.assign(location);
};

export const azureRedirect = admin => {
  const { apiUrl } = getHostAndApiUrl();

  const apiLoginUrl = admin ? `https://${apiUrl}/azure/admin_consent/login` : `https://${apiUrl}/azure/login`;

  window.location.assign(`${apiLoginUrl}?return_to=${window.location.href}`);
};
