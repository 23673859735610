import PropTypes from 'prop-types';

import ClientInfoFields from 'components/fields/ClientInfoFields';
import FormGroup from 'components/FormGroup';
import CustomPropTypes from 'utils/propTypes';

const EditableClientInfo = ({ editing, engagement, errors }) =>
  editing ? (
    <ClientInfoFields engagement={engagement} errors={errors} />
  ) : (
    <div>
      <FormGroup label="Client" highlighted={engagement.updatedEngagementFields.includes('client_id')}>
        <span>{engagement.client.name}</span>
      </FormGroup>
      <FormGroup
        label="Billing Contact"
        highlighted={engagement.updatedEngagementFields.includes('billing_contact_id')}
      >
        <span>
          {engagement.billingContact.name} ({engagement.billingContact.email})
        </span>
      </FormGroup>
      <FormGroup label="Sales Representative" highlighted={engagement.updatedEngagementFields.includes('sales_rep_id')}>
        <span>{engagement && engagement.salesRep ? engagement.salesRep.name : 'Unassigned'}</span>
      </FormGroup>
    </div>
  );

EditableClientInfo.propTypes = {
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement,
  errors: PropTypes.object,
};

export default EditableClientInfo;
