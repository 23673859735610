import { fetchRoles, fetchRolesSuccess } from 'actions/roles';
import { createGenericReducer } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'roles',
  actionTypes: {
    fetch: fetchRoles,
    fetchSuccess: fetchRolesSuccess,
  },
});
