import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import SaveCancelButtons from 'components/buttons/SaveCancelButtons';
import DocumentUpload from 'components/DocumentUpload/DocumentUpload.container';
import CurrentStateSelect from 'components/inputs/CurrentStateSelect';
import Page from 'components/Page';
import ProjectStatus from 'components/ProjectStatus';
import Section from 'components/Section';
import ValidationSection from 'components/ValidationSection';
import CustomPropTypes from 'utils/propTypes';

import EditableClientInfo from './EditableClientInfo';
import EditableProjectBilling from './EditableProjectBilling';
import EditableProjectInfo from './EditableProjectInfo';
import EditableResources from './EditableResources';
import RequestCloseButton from './RequestCloseButton';
import { useSelector } from 'react-redux';
import { getEngagement } from 'selectors/engagement';
import Loader from 'components/Loader';
import dayjs from 'lib/dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { engagementSchema } from '../../../schemas/engagement.schema';
import { combineErrors } from '../../../utils/combineErrors';
import { VALIDATION_MODE } from 'constants';

const EngagementActions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
`;

const createDefaultValuesFromEngagement = engagement => {
  return {
    state: engagement.state,
    clientId: engagement.client.id,
    billingContactId: engagement.billingContact.id,
    salesRepId: engagement.salesRep ? engagement.salesRep.id : null,
    recipientsUserIds: engagement.recipientsUsers.map(u => u.id),
    billingType: engagement.billingType,
    name: engagement.name,
    departmentId: engagement.departmentId,
    projectId: engagement.projectId,
    startDate: dayjs(engagement.startDate).toDate(),
    endDate: engagement.endDate && dayjs(engagement.endDate).toDate(),
    cmName: engagement.cmName,
    cmEmail: engagement.cmEmail,
    cmPhone: engagement.cmPhone,
    description: engagement.description,
    engagementType: engagement.engagementType ?? 'commercial',
    billingMax: engagement.billingMax,
    billingMaxInterval: engagement.billingMaxInterval ?? 'monthly',
    billingContractValue: engagement.billingContractValue,
    invoiceInstructions: engagement.invoiceInstructions,
    clientPoNumber: engagement.clientPoNumber,
    billingClass: engagement.billingClass,
    anticipatedBillingRate: engagement.anticipatedBillingRate,
    engagementManagerId: engagement.engagementManagerId,
  };
};

const ShowEngagement = ({ currentUser, engagement, errors: serverErrors, handleRequestClose, loading, submit }) => {
  const formContext = useForm({
    defaultValues: createDefaultValuesFromEngagement(engagement),
    resolver: yupResolver(engagementSchema),
    mode: VALIDATION_MODE,
    shouldUnregister: true,
    context: { isEditing: true },
  });

  const canEditCurrentState = useMemo(
    () => currentUser.roles.includes('admin') || currentUser.roles.includes('accounting'),
    [currentUser]
  );

  const errors = combineErrors(formContext.formState.errors, serverErrors);
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(submit)}>
        <Page
          title="Engagement Details"
          breadcrumb={{ to: '/engagements', text: '< Back to Engagement List' }}
          headerAdditions={
            <div style={{ marginRight: 15 }}>
              {canEditCurrentState && <CurrentStateSelect name="state" roles={currentUser.roles} />}
            </div>
          }
          padding={15}
        >
          <Section title="Project Notes">
            <ProjectStatus engagement={engagement} />
          </Section>
          {hasErrors && (
            <ValidationSection>
              <h4>There are errors preventing you from saving this engagement, please see below.</h4>
            </ValidationSection>
          )}
          <Section title="Client Information" editable>
            <EditableClientInfo engagement={engagement} errors={errors} />
          </Section>
          <Section title="Project Information" editable>
            <EditableProjectInfo engagement={engagement} errors={errors} />
          </Section>
          <Section title="Project Billing" editable>
            <EditableProjectBilling engagement={engagement} errors={errors} />
          </Section>
          <Section title="Resources" editable>
            <EditableResources engagement={engagement} errors={errors} />
          </Section>
          <Section title="Documents" editable>
            <DocumentUpload associatedType="engagements" associatedId={engagement.id} />
          </Section>
          <EngagementActions>
            <RequestCloseButton engagement={engagement} loading={loading} onClick={handleRequestClose} />
            <SaveCancelButtons />
          </EngagementActions>
        </Page>
      </form>
    </FormProvider>
  );
};

ShowEngagement.propTypes = {
  currentUser: PropTypes.object.isRequired,
  engagement: CustomPropTypes.engagement.isRequired,
  errors: PropTypes.object,
  handleRequestClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submit: PropTypes.func,
};

/**
 * The showPage HOC puts engagement in local state and ShowEngagementContainer puts it in redux,
 * this allows us to wait until redux actually _has_ it. Delete this component once showPage is removed.
 */
const ShowEngagementLoader = props => {
  const engagement = useSelector(getEngagement);
  if (!engagement) return <Loader />;
  return <ShowEngagement {...props} engagement={engagement} />;
};

export default ShowEngagementLoader;
