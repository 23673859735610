/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useMemo } from 'react';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import injectSheet from 'react-jss';
import CustomPropTypes from 'utils/propTypes';

import { Input } from 'components/inputs';

import styles from './DocumentRow.styles';
import { CancelIcon, DestroyIcon, SaveIcon } from './icons';

const EditDocumentRow = props => {
  const { mutations, toggleEditing, document, isNew, classes } = props;

  const formContext = useForm({
    defaultValues: {
      name: document.name,
      description: document.description,
    },
  });

  const { formState } = formContext;

  const isDirty = useMemo(() => isNew || formState.isDirty, [isNew, formState.isDirty]);

  const handleSaveDocument = useCallback(
    values => {
      if (isNew) {
        mutations.create.mutate({ ...values, file: document.file }, { onSuccess: toggleEditing });
      } else if (isDirty) {
        mutations.update.mutate({ ...values, id: document.id }, { onSuccess: toggleEditing });
      }
    },
    [mutations, document, isNew, toggleEditing, isDirty]
  );

  const destructiveButtonIcon = useMemo(() => (isNew ? <DestroyIcon /> : <CancelIcon />), [isNew]);

  return (
    <FormProvider {...formContext}>
      <tr>
        <td>
          <Input placeholder="Name" {...formContext.register('name')} block />
        </td>
        <td>
          <Input placeholder="Description" {...formContext.register('description')} block />
        </td>
        <td>{dayjs(document.createdAt).format('MM/DD/YYYY h:MM A')}</td>
        <td>{document.uploadedBy}</td>
        <td />
        <td className={classes.controls}>
          <a
            aria-disabled={!isDirty}
            onClick={formContext.handleSubmit(handleSaveDocument)}
            style={{ marginRight: 10 }}
            children={<SaveIcon disabled={!isDirty} />}
          />
          <a onClick={toggleEditing} children={destructiveButtonIcon} />
        </td>
      </tr>
    </FormProvider>
  );
};

EditDocumentRow.propTypes = {
  mutations: PropTypes.shape({
    destroy: PropTypes.object.isRequired,
    create: PropTypes.object.isRequired,
    update: PropTypes.object.isRequired,
  }).isRequired,
  classes: CustomPropTypes.classes,
  document: CustomPropTypes.document.isRequired,
  isNew: PropTypes.bool,
  toggleEditing: PropTypes.func.isRequired,
};

export default injectSheet(styles)(EditDocumentRow);
