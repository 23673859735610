import { call, put } from 'redux-saga/effects';

import { createEngagementSuccess, engagementInvalid } from 'actions/engagements';
import { createEngagement } from 'utils/api';
import { pushRoute } from 'utils/navigation';

export default function* createEngagementSaga({ payload: attributes }) {
  try {
    const { engagement } = yield call(createEngagement, attributes);

    if (engagement) {
      if (engagement.errors) {
        yield put(engagementInvalid(engagement.errors));
      } else {
        yield put(createEngagementSuccess(engagement));
        yield call(pushRoute, `/engagements/${engagement.id}`);
      }
    }
  } catch (e) {
    console.warn('Something went wrong:', e);
  }
}
