import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { fetchEngagementProjectStatuses, unloadProjectStatuses } from 'actions/projectStatuses';
import { getAllProjectStatuses } from 'selectors/projectStatuses';
import CustomPropTypes from 'utils/propTypes';

import ProjectStatus from './ProjectStatus';

class ProjectStatusContainer extends Component {
  static propTypes = {
    engagement: CustomPropTypes.engagement.isRequired,
    errors: PropTypes.object,
    fetchEngagementProjectStatuses: PropTypes.func.isRequired,
    projectStatuses: PropTypes.arrayOf(PropTypes.object),
    unloadProjectStatuses: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchEngagementProjectStatuses(this.props.engagement.id);
  }

  componentWillUnmount() {
    this.props.unloadProjectStatuses();
  }

  render() {
    return (
      <ProjectStatus
        engagement={this.props.engagement}
        errors={this.props.errors}
        projectStatuses={this.props.projectStatuses}
      />
    );
  }
}

const mapStateToProps = state => ({
  projectStatuses: getAllProjectStatuses(state),
  errors: state.projectStatuses.get('errors'),
});

const actions = {
  fetchEngagementProjectStatuses,
  unloadProjectStatuses,
};

export default connect(mapStateToProps, actions)(ProjectStatusContainer);
