import find from 'lodash/find';
import PropTypes from 'prop-types';
import { Component } from 'react';

import TabGroup from './TabGroup';

export default class TabGroupContainer extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  state = { value: null };

  componentDidMount() {
    this.setState({ value: this.getDefaultValue() });
  }

  getDefaultValue() {
    const { defaultValue, children } = this.props;

    if (defaultValue) return defaultValue;
    if (children) return (find(children, child => child && child.props.default) || children[0]).props.value;

    return null;
  }

  currentValue() {
    return this.props.value || this.state.value;
  }

  onTabClick = value => {
    if (this.props.onChange) this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    return <TabGroup {...this.props} currentValue={this.currentValue()} onTabClick={this.onTabClick} />;
  }
}
