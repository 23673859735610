import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import ProjectStatusFields from 'components/fields/ProjectStatusFields';
import Table from 'components/Table';
import CustomPropTypes from 'utils/propTypes';

import styles from './ProjectStatus.styles';
import StatusRow from './StatusRow';

const ProjectStatus = ({ projectStatuses, engagement, errors, classes }) => (
  <div className={classes.projectStatus}>
    <ProjectStatusFields engagement={engagement} errors={errors} />
    {projectStatuses.length > 0 && (
      <Table className={classes.statusTable}>
        <thead>
          <tr>
            <th>Date Modified</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">
              <div className={classes.statuses}>
                <table>
                  <tbody>
                    {projectStatuses.map(projectStatus => (
                      <StatusRow key={projectStatus.id} projectStatus={projectStatus} />
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    )}
  </div>
);

ProjectStatus.propTypes = {
  engagement: CustomPropTypes.engagement.isRequired,
  projectStatuses: CustomPropTypes.projectStatuses.isRequired,
  classes: CustomPropTypes.classes,
  errors: PropTypes.object,
};

export default injectSheet(styles)(ProjectStatus);
