import * as theme from 'theme';

export default {
  SearchBox: {
    background: theme.colors.darkGray,
    padding: 30,
    position: 'relative',
  },
  inputContainer: {
    width: '100%',
    'border-radius': 4,
    background: 'white',
    position: 'relative',
    display: 'flex',
  },
  input: {
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: '0 15px 0 0',
    background: 'transparent',
    'font-size': '14px',
  },
  icon: {
    padding: 15,
    color: theme.colors.lightGray,
  },
};
