import { call, put } from 'redux-saga/effects';

import { fetchEmployeesSuccess } from 'actions/employees';
import { fetchEmployees } from 'utils/api';

export function* fetchEmployeesSaga({ payload: params }) {
  try {
    const { employees } = yield call(fetchEmployees, params);
    if (employees.errors) {
      // TODO: handle errors
    } else {
      yield put(fetchEmployeesSuccess(employees));
    }
  } catch (e) {
    // TODO: handle connection problem and rollback
  }
}
