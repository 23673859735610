import * as theme from 'theme';

export default {
  Dialog: {
    extend: theme.hasShadow,
    background: 'white',
    flex: '1 1 100%',
    'max-width': 600,
  },
  header: {
    display: 'flex',
    'align-items': 'center',
    height: 62,
    background: theme.colors.darkBlue,
    'border-bottom': `2px solid ${theme.colors.green}`,
    color: 'white',
    'font-size': '20px',
    'text-transform': 'uppercase',
    padding: [0, 15],
  },
  container: {
    padding: 20,
  },
};
