/* eslint-disable jsx-a11y/anchor-is-valid */
import classnames from 'classnames';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { destroyResource } from 'actions/resources';
import { toUsd } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

import { ESTIMATE_INTERVALS, ESTIMATE_TYPES } from './constants';
import { DestroyIcon, EditIcon } from './icons';

const createEstimateMessage = resource => {
  const type = ESTIMATE_TYPES[resource.estimateType];

  if (!resource.estimate) return type;

  switch (resource.estimateType) {
    case 'fixed': {
      const interval = ESTIMATE_INTERVALS[resource.estimateInterval];
      if (resource.estimateInterval === 'total_project') return `${type} - ${resource.estimate} hrs ${interval}`;
      return `${type} - ${resource.estimate} hrs/${interval}`;
    }
    case 'partial':
      return `${type} - ${resource.estimate}%`;
    default:
      return type;
  }
};

export const ViewResourceRow = ({ classes, highlighted, resource, showRate, showControls, toggleEditing }) => {
  const estimateMessage = useMemo(() => createEstimateMessage(resource), [resource]);
  const dispatch = useDispatch();
  const handleDestroy = useCallback(() => {
    dispatch(destroyResource(resource.id));
  }, [dispatch, resource.id]);

  return (
    <tr
      className={classnames({
        [classes.highlighted]: highlighted,
        [classes.accountDisabled]: resource.userData ? !resource.userData.accountEnabled : false,
      })}
    >
      <td>{resource.name}</td>
      <td>{resource.role}</td>
      {showRate && <td className={classes.billingRate}>${toUsd(resource.rate)}</td>}
      <td className={classes.estimate}>{estimateMessage}</td>
      <td className={classes.interval}>
        {resource.startDate ? dayjs(resource.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
      </td>
      <td className={classes.interval}>
        {resource.endDate ? dayjs(resource.endDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
      </td>
      {showControls && (
        <td className={classes.controls}>
          <span>
            <a onClick={toggleEditing} style={{ marginRight: 10 }} children={<EditIcon />} />
            <a onClick={handleDestroy} children={<DestroyIcon />} />
          </span>
        </td>
      )}
    </tr>
  );
};

ViewResourceRow.propTypes = {
  classes: CustomPropTypes.classes,
  highlighted: PropTypes.bool,
  resource: CustomPropTypes.resource,
  showRate: PropTypes.bool,
  showControls: PropTypes.bool,
  toggleEditing: PropTypes.func,
};
