import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import CustomPropTypes from 'utils/propTypes';

import styles from './Badge.styles';

const Badge = ({ color, children, className, style, classes }) => (
  <div className={[className, classes.Badge].filter(Boolean).join(' ')} style={{ backgroundColor: color, ...style }}>
    {children}
  </div>
);

Badge.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Badge);
