import hoistNonReactStatics from 'hoist-non-react-statics';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Loader from 'components/Loader';
import { getCurrentUser } from 'selectors/currentUser';
import { getDisplayName } from 'utils/common';

const showPage = ({ endpoint, action, decorator, beforeSave, validator }) => WrappedComponent => {
  class ShowPage extends Component {
    static displayName = `ShowPageHOC(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      match: PropTypes.shape({ params: PropTypes.objectOf(PropTypes.string) }),
      dispatchUpdate: PropTypes.func,
    };

    // anything in state will be passed down as props
    state = { loading: true, errors: {} };

    componentDidMount() {
      this.loadDetails();
    }

    async loadDetails() {
      const {
        match: {
          params: { id },
        },
      } = this.props;

      // create the base updates object
      const updates = { loading: false };

      try {
        // await response from api
        const res = await endpoint(id);

        // "loop" through (one item) and add to updates; apply decorator if specified
        Object.keys(res).forEach(key => {
          updates[key] = decorator ? decorator(res[key]) : res[key];
        });
      } catch (e) {
        updates.error = "Couldn't reach the api";
      } finally {
        this.setState(updates);
      }
    }

    submit = attributes => {
      const {
        match: {
          params: { id },
        },
        dispatchUpdate,
      } = this.props;

      if (validator) {
        const errors = validator(attributes, this.props);

        if (Object.keys(errors).length) {
          this.setState({ errors });
          return false;
        }
      }

      if (beforeSave && typeof beforeSave === 'function') {
        attributes = beforeSave(attributes, this.state);
      }

      dispatchUpdate({ id, ...attributes });
    };

    getChildProps() {
      return {
        ...omit(this.props, ['dispatchUpdate']),
        ...this.state,
        refetch: this.loadDetails.bind(this),
        submit: this.submit.bind(this),
      };
    }

    render() {
      if (this.state.loading) return <Loader />;
      return <WrappedComponent {...this.getChildProps()} />;
    }
  }

  const mapState = state => ({ currentUser: getCurrentUser(state) });

  const ConnectedShowPage = connect(mapState, { dispatchUpdate: action })(ShowPage);

  hoistNonReactStatics(ConnectedShowPage, WrappedComponent);

  return ConnectedShowPage;
};

export default showPage;
