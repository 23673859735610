import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';

import FormGroup from 'components/FormGroup';
import { DatePicker } from 'components/inputs';
import Input from 'components/inputs/Input';
import RolesSelectGroup from 'components/inputs/RolesSelectGroup';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomPropTypes from 'utils/propTypes';
import ErrorList from 'components/ErrorList';

export const errorFields = {
  roleIds: 'roles',
  /* `combineErrors` right-trims "Id" from keys; in this case either key could be used */
  salesRep: 'sales rep id',
  salesRepId: 'sales rep id',
};

const currentYear = dayjs().year();

const UserFields = ({ user = {}, errors = {}, salesRepRoleId }) => {
  const { register, watch } = useFormContext();
  const observedRoleIds = watch('roleIds');
  const isSalesRep = useMemo(() => {
    const roleIds = observedRoleIds ?? user.roleIds.map(String);
    return roleIds.includes(salesRepRoleId);
  }, [user, observedRoleIds, salesRepRoleId]);

  return (
    <>
      <ErrorList errors={errors} fields={errorFields} />
      <FormGroup label="Name">
        <span>{user.name}</span>
      </FormGroup>
      <FormGroup label="Email">
        <span>{user.email}</span>
      </FormGroup>
      <FormGroup label="Roles">
        <RolesSelectGroup name="roleIds" />
      </FormGroup>
      {isSalesRep && (
        <FormGroup label="Sales Rep ID">
          <Input
            {...register('salesRepId')}
            defaultValue={user.salesRepId}
            errors={errors.salesRepId || errors.salesRep}
            maxLength="2"
            type="text"
          />
        </FormGroup>
      )}
      <FormGroup label="Start Date">
        <DatePicker name="startDate" errors={errors.startDate} width={380} popoverTargetAttachment="bottom left" />
      </FormGroup>
      <FormGroup label={`${currentYear} Rollover + Accrued PTO`}>
        <span>{user.accumulatedPtoBalance} Hours</span>
      </FormGroup>
      <FormGroup label={`${currentYear} Hours Requested`}>
        <span>{user.ptoRequested ? user.ptoRequested[currentYear] || 0 : 0} Hours</span>
      </FormGroup>
    </>
  );
};

UserFields.propTypes = {
  errors: PropTypes.object,
  user: CustomPropTypes.user,
  salesRepRoleId: PropTypes.string.isRequired,
};

export default UserFields;
