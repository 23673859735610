import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import injectSheet from 'react-jss';

import Button from 'components/Button';
import Table from 'components/Table';
import CustomPropTypes from 'utils/propTypes';

import { fetchEmployees } from 'actions/employees';
import * as actions from 'actions/resources';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from 'selectors/employees';
import { getAllResourceRoles, getAllResources, getEditingResources, getNewResources } from 'selectors/resources';
import styles from './ResourceManager.styles';
import ResourceRow from './ResourceRow';

const ResourceManager = ({ editing, showRate, engagement, classes }) => {
  const dispatch = useDispatch();
  const resources = useSelector(getAllResources);
  const newResources = useSelector(getNewResources);
  const editingResources = useSelector(getEditingResources);
  const employees = useSelector(getAllEmployees);
  const roles = useSelector(getAllResourceRoles);

  useEffect(() => {
    dispatch(actions.fetchEngagementResources(engagement.id));
    return () => dispatch(actions.unloadResources());
  }, [dispatch, engagement.id]);

  // Refresh ref data when toggling into edit mode
  useEffect(() => {
    if (!editing) return;

    dispatch(actions.fetchResourceRoles());
    dispatch(fetchEmployees());
  }, [dispatch, editing]);

  // Reset new/editing arrays when switching out of edit
  useEffect(() => {
    if (editing) return;
    if (newResources.length) dispatch(actions.clearNewResources());
    if (editingResources.length) dispatch(actions.clearResourceEditing());
  }, [dispatch, editing, newResources, editingResources]);

  const addNewResource = useCallback(() => dispatch(actions.addNewResource()), [dispatch]);
  const removeNewResource = useCallback(uuid => dispatch(actions.removeNewResource(uuid)), [dispatch]);

  return (
    <div className={classes.resourceManager}>
      {engagement.removedResources.length > 0 && !editing && (
        <div style={{ color: 'red' }}>
          {engagement.removedResources.length}
          {' resource(s) have been removed: '}
          {engagement.removedResources.join(', ')}.
        </div>
      )}
      <Table className={classes.resourceTable}>
        <thead>
          <tr>
            <th>Resource</th>
            <th>Role</th>
            {showRate && <th>Rate/Hr</th>}
            <th>Utilization</th>
            <th>Start Date</th>
            <th>End Date</th>
            {editing && <th />}
          </tr>
        </thead>
        <tbody>
          {resources.map(resource => (
            <ResourceRow
              key={resource.id}
              editing={editingResources.includes(resource.id)}
              engagement={engagement}
              resource={resource}
              showControls={editing}
              showRate={showRate}
              employees={employees}
              roles={roles}
              highlighted={engagement.updatedResources.includes(resource.id)}
            />
          ))}
          {newResources.map(({ uuid, ...resource }) => (
            <ResourceRow
              engagement={engagement}
              key={uuid}
              resource={resource}
              removeNewResource={removeNewResource}
              showRate={showRate}
              uuid={uuid}
              employees={employees}
              roles={roles}
              editing
              newResource
              showControls
            />
          ))}
        </tbody>
      </Table>
      {resources.length + newResources.length === 0 && (
        <div style={{ padding: '10px 5px' }}>There are no resources to show</div>
      )}
      {editing && (
        <Button type="button" className={classes.addResourceButton} onClick={addNewResource} label="Add Resource" />
      )}
    </div>
  );
};

ResourceManager.propTypes = {
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement.isRequired,
  classes: CustomPropTypes.classes,
  showRate: PropTypes.bool,
};

export default injectSheet(styles)(ResourceManager);
