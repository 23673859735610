import classnames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { pushRoute } from 'utils/navigation';
import CustomPropTypes from 'utils/propTypes';

import styles from './Button.styles';

const Button = ({ label, linkTo, children, className, theme, onClick, classes, ...props }) => (
  <button
    type="button"
    className={classnames(classes.Button, classes[theme], className)}
    {...props}
    onClick={e => {
      if (linkTo && linkTo.length > 0) pushRoute(linkTo);
      if (onClick && typeof onClick === 'function') onClick(e);
    }}
  >
    {children || label}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  classes: CustomPropTypes.classes,
  className: PropTypes.string,
  label: PropTypes.node,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
};

export default injectSheet(styles)(Button);
