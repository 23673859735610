import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';

import ProjectBillingFields from 'components/fields/ProjectBillingFields';
import FormGroup from 'components/FormGroup';
import { toUsd } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

const BILLING_INTERVALS = {
  annual: 'year',
  monthly: 'month',
  weekly: 'week',
  contract: 'total contract',
};

const getBillingTypeInfo = (engagement, fixedBilling) => {
  const { billingContractValue, billingMax, billingMaxInterval } = engagement;

  if (fixedBilling && billingContractValue) return `Contract value ${toUsd(billingContractValue)}`;
  if (!fixedBilling && billingMax) {
    return `Not to exceed $${toUsd(billingMax)} per ${BILLING_INTERVALS[billingMaxInterval]}`;
  }
  return null;
};

const EditableProjectBilling = ({ editing, engagement, errors }) => {
  if (editing) {
    return <ProjectBillingFields engagement={engagement} errors={errors} />;
  }

  const fixedBilling = engagement.billingType === 'fixed';

  const billingTypeUpdated =
    intersection(engagement.updatedEngagementFields, [
      'billing_type',
      'billing_contract_value',
      'billing_max',
      'billing_max_interval',
    ]).length > 0;

  let anticipatedBillingRate = null;

  if (engagement.anticipatedBillingRate) {
    anticipatedBillingRate = `$${engagement.anticipatedBillingRate}/hr`;
  } else {
    anticipatedBillingRate = 'N/A';
  }

  const emailRecipients = engagement.recipientsUsers
    .map(ru => ru.email)
    .sort()
    .join(', ');

  const billingTypeInfo = getBillingTypeInfo(engagement, fixedBilling);

  return (
    <div>
      <FormGroup label="Billing Type" highlighted={billingTypeUpdated}>
        <span>{fixedBilling ? 'Fixed Fee' : 'T&M'}</span>
        {billingTypeInfo && (
          <>
            <span style={{ margin: '0 15px' }}>—</span>
            <span>{billingTypeInfo}</span>
          </>
        )}
      </FormGroup>
      <FormGroup
        label="Invoice Instructions"
        highlighted={engagement.updatedEngagementFields.includes('invoice_instructions')}
      >
        <pre>{engagement.invoiceInstructions || 'N/A'}</pre>
      </FormGroup>
      <FormGroup label="Client PO #" highlighted={engagement.updatedEngagementFields.includes('client_po_number')}>
        <pre>{engagement.clientPoNumber || 'N/A'}</pre>
      </FormGroup>
      <FormGroup label="Billing Class" highlighted={engagement.updatedEngagementFields.includes('billing_class')}>
        <pre>{engagement.billingClass || ''}</pre>
      </FormGroup>
      {fixedBilling && (
        <FormGroup
          label="Anticipated Billing Rate"
          highlighted={engagement.updatedEngagementFields.includes('anticipated_billing_rate')}
        >
          <pre>{anticipatedBillingRate}</pre>
        </FormGroup>
      )}
      {fixedBilling && (
        <FormGroup label="Weekly Email Recipients">
          <pre>{emailRecipients}</pre>
        </FormGroup>
      )}
    </div>
  );
};

EditableProjectBilling.propTypes = {
  errors: PropTypes.object,
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement.isRequired,
};

export default EditableProjectBilling;
