import * as yup from 'yup';
import { baseClientFields } from './client.schema';

const requiredForNew = field => {
  return field.when('$isEditing', (isEdit, schema) => (isEdit ? schema.notRequired() : schema.required()));
};

export const engagementSchema = yup.object().shape({
  id: yup.number().notRequired(),
  name: requiredForNew(yup.string()),
  departmentId: requiredForNew(yup.number()),
  projectId: yup.string().nullable(),
  startDate: requiredForNew(yup.date()),
  endDate: yup.date().nullable(),
  cmName: yup.string().nullable(),
  cmEmail: yup.string().nullable(),
  cmPhone: yup.string().nullable(),
  description: yup.string().nullable(),
  engagementType: yup.string().nullable(),
  billingMax: yup.string().nullable(),
  billingMaxInterval: yup
    .string()
    .notRequired()
    .default(null)
    .when(['billingType', 'billingMax'], {
      is: (type, max = '') => type === 'variable' && max !== null && max.trim().length > 0,
      then: () => yup.string().required(),
      otherwise: () =>
        yup
          .string()
          .nullable()
          .notRequired(),
    }),
  billingContractValue: yup
    .string()
    .notRequired()
    .nullable()
    .default('')
    .when('billingType', {
      is: 'fixed',
      then: () => yup.string().required(),
      otherwise: () =>
        yup
          .string()
          .nullable()
          .notRequired(),
    }),
  invoiceInstructions: yup.string().nullable(),
  clientPoNumber: yup.string().nullable(),
  billingClass: yup.string().nullable(),
  anticipatedBillingRate: yup.string().nullable(),
  engagementManagerId: requiredForNew(yup.number()),
  salesRepId: requiredForNew(yup.number()),
  billingType: requiredForNew(yup.string().nullable()),
  hasBillingContactAttributes: yup.boolean().default(false),
  billingContactAttributes: yup
    .object()
    .notRequired()
    .default(null)
    .when('hasBillingContactAttributes', {
      is: f => !!f,
      then: schema => {
        return schema
          .shape({
            name: yup.string().required(),
            email: yup.string().required(),
            phone: yup.string(),
          })
          .required();
      },
      otherwise: () => yup.object().notRequired(),
    }),
  billingContactId: yup
    .number()
    .required()
    .when('hasBillingContactAttributes', {
      is: f => !f,
      then: () => requiredForNew(yup.number()),
      otherwise: () => yup.number().notRequired(),
    }),
  hasClientAttributes: yup.boolean().default(false),
  clientAttributes: yup
    .object()
    .notRequired()
    .default(null)
    .when('hasClientAttributes', {
      is: f => !!f,
      then: () => yup.object(baseClientFields),
      otherwise: () => yup.object().notRequired(),
    }),
  clientId: yup
    .number()
    .required()
    .when('hasClientAttributes', {
      is: f => !f,
      then: () => requiredForNew(yup.number().required()),
      otherwise: () => yup.number().notRequired(),
    }),
});
