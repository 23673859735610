import { setEngagement } from 'actions/engagement';
import { unloadEngagement } from 'actions/engagements';
import { receiveProjectStatus } from 'actions/projectStatuses';
import { handleActions } from 'redux-actions';

// Currently only used to share the engagement info outside of the show page.
const initialState = { engagement: null };

export default handleActions(
  {
    [setEngagement]: (state, { payload: engagement }) => ({ ...state, engagement }),
    [receiveProjectStatus]: (state, { payload: status }) => ({ ...state, engagement: { ...state.engagement, status } }),
    [unloadEngagement]: () => initialState,
  },
  initialState
);
