/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import injectSheet from 'react-jss';
import { useDispatch } from 'react-redux';

import { toggleResourceEditing } from 'actions/resources';
import CustomPropTypes from 'utils/propTypes';

import { EditResourceRow } from './EditResourceRow';
import styles from './ResourceRow.styles';
import { ViewResourceRow } from './ViewResourceRow';

/* ResourceRow */
const ResourceRow = props => {
  const { resource, editing } = props;

  const dispatch = useDispatch();
  const toggleEditing = useCallback(() => dispatch(toggleResourceEditing(resource.id)), [dispatch, resource]);
  const ResourceComponent = useMemo(() => (editing ? EditResourceRow : ViewResourceRow), [editing]);

  return <ResourceComponent {...props} toggleEditing={toggleEditing} />;
};

ResourceRow.propTypes = {
  classes: CustomPropTypes.classes,
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement.isRequired,
  employees: CustomPropTypes.employees.isRequired,
  highlighted: PropTypes.bool,
  newResource: PropTypes.bool,
  removeNewResource: PropTypes.func,
  resource: CustomPropTypes.resource,
  roles: CustomPropTypes.resourceRoles.isRequired,
  showControls: PropTypes.bool,
  showRate: PropTypes.bool,
  uuid: PropTypes.string,
};

export default injectSheet(styles)(ResourceRow);
