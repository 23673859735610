import {
  createEngagement,
  createEngagementSuccess,
  engagementInvalid,
  fetchEngagements,
  fetchEngagementsSuccess,
  updateSingleEngagement,
} from 'actions/engagements';
import { createGenericReducer } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'engagements',
  actionTypes: {
    fetch: fetchEngagements,
    fetchSuccess: fetchEngagementsSuccess,
    create: createEngagement,
    createSuccess: createEngagementSuccess,
    invalid: engagementInvalid,
  },
  actionHandlers: {
    [updateSingleEngagement]: (state, { payload: { id, status } }) =>
      state.updateIn(['engagements', id], engagement => ({
        ...engagement,
        status: { ...engagement.status, ...status },
      })),
  },
});
