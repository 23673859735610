import { handleActions } from 'redux-actions';

import { createEngagement, engagementInvalid, unloadEngagement } from 'actions/engagements';
import { createClient, clientInvalid, unloadClient } from 'actions/clients';

const clearEngagementErrors = ({ engagement, ...state }) => state;
const setEngagementErrors = (state, { payload: engagement }) => ({
  ...state,
  engagement,
});

const clearClientErrors = ({ client, ...state }) => state;
const setClientErrors = (state, { payload: client }) => ({ ...state, client });

export default handleActions(
  {
    // engagements
    [createEngagement]: clearEngagementErrors,
    [unloadEngagement]: clearEngagementErrors,
    [engagementInvalid]: setEngagementErrors,

    //clients
    [createClient]: clearClientErrors,
    [unloadClient]: clearClientErrors,
    [clientInvalid]: setClientErrors,
  },
  {}
);
