export default {
  buttons: {
    padding: [15, 0],
    display: 'flex',
    'justify-content': 'flex-end',
    '& button': { extend: 'Button' },
  },
  saveButton: {
    marginLeft: 15,
  },
};
