import { createAction } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

export const fetchEngagementResources = createAction('FETCH_ENGAGEMENT_RESOURCES');
export const receiveResources = createAction('RECEIVE_RESOURCES');
export const destroyResource = createAction('DESTROY_RESOURCE');
export const resourceDestroyed = createAction('RESOURCE_DESTROYED');

const addUuidToPayload = payload => ({ ...payload, uuid: uuidv4() });

export const addNewResource = createAction('ADD_NEW_RESOURCE', addUuidToPayload);
export const addNewResources = createAction('ADD_NEW_RESOURCES', (payloads = []) => payloads.map(addUuidToPayload));

export const removeNewResource = createAction('REMOVE_NEW_RESOURCE');
export const clearNewResources = createAction('CLEAR_NEW_RESOURCES');
export const saveResource = createAction('SAVE_RESOURCE', (resource, isNew, uuid) => ({ resource, isNew, uuid }));
export const receiveResource = createAction('RECEIVE_RESOURCE');

export const unloadResources = createAction('UNLOAD_RESOURCES');

export const toggleResourceEditing = createAction('TOGGLE_RESOURCE_EDITING');
export const clearResourceEditing = createAction('CLEAR_RESOURCE_EDITING');

export const fetchResourceRoles = createAction('FETCH_RESOURCE_ROLES');
export const fetchResourceRolesSuccess = createAction('FETCH_RESOURCE_ROLES_SUCCESS');
