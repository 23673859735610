import { fetchFixedFeeProfitabilityReport, fetchFixedFeeProfitabilityReportSuccess } from 'actions/reports';

import { createGenericReducer, genericFetchHandler } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'reports',
  initialState: {
    fixedFeeProfitabilityReport: {},
  },
  actionHandlers: {
    [fetchFixedFeeProfitabilityReport]: (state, { payload: engagementId }) => {
      return genericFetchHandler(state.set('engagementId', engagementId));
    },
    [fetchFixedFeeProfitabilityReportSuccess]: (state, { payload }) => {
      return state.set('isFetching', false).set('fixedFeeProfitabilityReport', payload);
    },
  },
});
