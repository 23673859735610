import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ErrorList from 'components/ErrorList';
import FormGroup from 'components/FormGroup';
import { Radio, Select, TextArea } from 'components/inputs';
import Input from 'components/inputs/Input';
import UsersSelect from 'components/inputs/UsersSelect';

const errorFields = {
  billingClass: 'billing class',
  billingType: 'billing type',
  billingMax: 'billing maximum',
  billingMaxInterval: 'billing interval',
  billingContractValue: 'contract value',
  anticipatedBillingRate: 'anticipated billing rate',
};

const ProjectBillingFields = ({ errors = {} }) => {
  const { register, watch, clearErrors } = useFormContext();
  const billingType = watch('billingType');
  const isFixedBilling = useMemo(() => billingType === 'fixed', [billingType]);

  useEffect(() => {
    clearErrors(['billingMax', 'billingMaxInterval', 'billingContractValue']);
  }, [billingType, clearErrors]);

  return (
    <div>
      <ErrorList errors={errors} fields={errorFields} />
      <FormGroup label="Billing Type" errors={errors.billingType}>
        <FormGroup>
          <span style={{ width: 110, display: 'inline-block' }}>
            <Radio {...register('billingType')} label="T&amp;M" value="variable" />
          </span>
          <span style={{ width: 120, display: 'inline-block' }}>Not to exceed</span>
          <span style={{ marginRight: 5 }}>$</span>
          <Input {...register('billingMax')} errors={errors.billingMax} min="0" step="any" type="number" width={100} />
          <Select
            errors={errors.billingMaxInterval}
            name="billingMaxInterval"
            options={[
              { label: 'Per Week', value: 'weekly' },
              { label: 'Per Month', value: 'monthly' },
              { label: 'Per Year', value: 'annual' },
              { label: 'Total Contract', value: 'contract' },
            ]}
            width={221}
          />
        </FormGroup>
        <FormGroup>
          <span style={{ width: 110, display: 'inline-block' }}>
            <Radio {...register('billingType')} label="Fixed Fee" value="fixed" />
          </span>
          <span style={{ width: 120, display: 'inline-block' }}>Contract Value</span>
          <span style={{ marginRight: 5 }}>$</span>
          <Input
            {...register('billingContractValue')}
            errors={errors.billingContractValue}
            min="0"
            step="any"
            type="number"
            width={100}
          />
        </FormGroup>
      </FormGroup>
      <FormGroup label="Invoice Instructions">
        <TextArea {...register('invoiceInstructions')} errors={errors.invoiceInstructions} />
      </FormGroup>
      <FormGroup label="Client PO #">
        <Input {...register('clientPoNumber')} errors={errors.clientPoNumber} width={100} />
      </FormGroup>
      <FormGroup label="Billing Class">
        <Select
          name="billingClass"
          errors={errors.billingClass}
          options={[
            { label: 'Auto', value: 'Auto' },
            { label: 'Consolidated', value: 'Consolidated' },
            { label: 'Detailed', value: 'Detailed' },
            { label: 'Unique', value: 'Unique' },
          ]}
          width={150}
        />
      </FormGroup>
      {isFixedBilling && (
        <>
          <FormGroup label="Anticipated Billing Rate">
            <Input
              {...register('anticipatedBillingRate')}
              errors={errors.anticipatedBillingRate}
              min="0"
              step="any"
              type="number"
              width={150}
            />
          </FormGroup>
          <FormGroup label="Weekly Email Recipients">
            <UsersSelect errors={errors.recipientsUsers} name="recipientsUsersIds" width={380} />
          </FormGroup>
        </>
      )}
    </div>
  );
};

ProjectBillingFields.propTypes = {
  fixedBilling: PropTypes.bool,
  errors: PropTypes.object,
};

export default ProjectBillingFields;
