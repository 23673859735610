import omit from 'lodash/omit';
import { Component } from 'react';
import { connect } from 'react-redux';

import { signIn } from 'actions/currentUser';

import Login from './Login';

class LoginContainer extends Component {
  getChildProps() {
    return {
      ...omit(this.props, ['userLogin']),
      handleSignIn: this.props.signIn.bind(this),
    };
  }

  render() {
    return <Login {...this.getChildProps()} />;
  }
}

export default connect(null, { signIn })(LoginContainer);
