import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import injectSheet from 'react-jss';

import { ConditionalLabel } from 'components/ConditionalLabel';
import CustomPropTypes from 'utils/propTypes';

import styles from './Checkbox.styles';

const Checkbox = forwardRef(({ label, classes, ...props }, ref) => {
  return (
    <ConditionalLabel label={label}>
      <input {...props} ref={ref} type="checkbox" className={classnames({ [classes.hasLabel]: label })} />
    </ConditionalLabel>
  );
});

Checkbox.propTypes = {
  label: PropTypes.node,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Checkbox);
