import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import SaveCancelButtons from 'components/buttons/SaveCancelButtons';
import Page from 'components/Page';
import Section from 'components/Section';

import { yupResolver } from '@hookform/resolvers/yup';
import { VALIDATION_MODE } from 'constants';
import { find } from 'lodash';
import dayjs from 'lib/dayjs';
import { useMemo } from 'react';
import { userSchema } from 'schemas/user.schema';
import { combineErrors } from 'utils/combineErrors';
import CustomPropTypes from 'utils/propTypes';
import EditableUserInfo from './EditableUserInfo';

const createDefaultValuesFromUser = user => ({
  roleIds: user.roleIds.map(id => `${id}`) ?? [],
  startDate: user.startDate ? dayjs(user.startDate).toDate() : dayjs().toDate(),
});

const ShowUser = ({ user, errors: serverErrors, submit, roles }) => {
  const salesRepRoleId = useMemo(() => find(roles, { slug: 'sales-rep' })?.id.toString(), [roles]);

  const formContext = useForm({
    defaultValues: createDefaultValuesFromUser(user),
    shouldUnregister: true,
    mode: VALIDATION_MODE,
    resolver: yupResolver(userSchema),
    context: { salesRepRoleId },
  });

  const errors = combineErrors(formContext.formState.errors, serverErrors);

  return (
    <Page title="User Details" breadcrumb={{ to: '/users', text: '< Back to User List' }} padding={15}>
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(submit)}>
          <Section title="User Information" editable>
            <EditableUserInfo user={user} errors={errors} roles={roles} salesRepRoleId={salesRepRoleId} />
          </Section>
          <SaveCancelButtons />
        </form>
      </FormProvider>
    </Page>
  );
};

ShowUser.propTypes = {
  user: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  error: PropTypes.any,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  roles: CustomPropTypes.roles.isRequired,
};

export default ShowUser;
