import BaseIcon from 'components/Icon';
import * as theme from 'theme';

// icons
const Icon = props => (
  <BaseIcon
    {...props}
    style={{ fontSize: '20px', cursor: props.disabled ? 'not-allowed' : 'pointer', opacity: props.disabled ? 0.5 : 1 }}
  />
);

export const SaveIcon = props => <Icon type="floppy-o" color={theme.colors.green} {...props} />;
export const CancelIcon = () => <Icon type="times-circle" color="#a2a3a9" />;
export const DestroyIcon = () => <Icon type="times-circle" color={theme.colors.darkRed} />;
export const EditIcon = () => <Icon type="pencil" />;
