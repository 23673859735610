export default {
  statusSubmit: {
    display: 'inline-block',
    fontWeight: 'lighter',
    fontSize: '16px !important',
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
};
