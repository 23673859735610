import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import * as theme from 'theme';

const SearchButton = ({ active, ...props }) => (
  <Button
    theme="square"
    style={{ background: theme.colors.darkGray }}
    {...props}
    children={<Icon type={active ? 'times' : 'search'} />}
  />
);

SearchButton.propTypes = {
  active: PropTypes.bool,
};

export default SearchButton;
