export default {
  Table: {
    width: '100%',
    'border-collapse': 'collapse',
    'font-size': '14px',
    '& th, & td': {
      padding: [15, 20],
      'text-align': 'left',
      '&.center': {
        'text-align': 'center',
      },
    },
    '& tr': {
      'border-bottom': '1px solid #f4f4f4',
    },
    '& tbody tr': {
      cursor: 'pointer',
      '&:hover': {
        background: '#F4F4F4',
      },
    },
  },
  tableScroller: {
    width: '100%',
    overflowX: 'auto',
  },
};
