import { createSelector } from 'reselect';
import { getAllEmployees } from './employees';

const allResources = state => state.resources.get('resources');
export const getAllResources = createSelector([allResources, getAllEmployees], (resources, employeeArray) => {
  return resources.toArray().map(resource => ({
    ...resource,
    userData: employeeArray.find(({ id }) => id === resource.spudUserId),
  }));
});

const newResources = state => state.resources.get('newResources');
export const getNewResources = createSelector([newResources], newResources => newResources.toArray());

export const getEngagementId = state => state.resources.get('engagementId');

const editingResources = state => state.resources.get('editing');
export const getEditingResources = createSelector([editingResources], editing => editing.toArray());

export const unsavedResourceCount = createSelector(
  [getNewResources, getEditingResources],
  (newResources, editingResources) => newResources.length + editingResources.length
);

export const allResourceRoles = state => state.resources.get('roles');
export const getAllResourceRoles = createSelector([allResourceRoles], roles => roles.toArray());
