import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import injectSheet from 'react-jss';

import Button from 'components/Button';
import Table from 'components/Table';
import CustomPropTypes from 'utils/propTypes';

import DocumentRow from './DocumentRow';
import styles from './DocumentUpload.styles';
import { DOCUMENT_UPLOAD_ACCEPTED_TYPES } from 'constants';

const DocumentUpload = props => {
  const {
    classes,
    documents,
    documentUploadModule: { handlers, refs, state },
    editing,
  } = props;

  const newDocuments = useMemo(() => Object.values(state.newDocuments), [state.newDocuments]);

  return (
    <div className={classes.documentUpload}>
      <Table style={{ marginBottom: '20px' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Uploaded Date</th>
            <th>Uploaded By</th>
            <th style={{ textAlign: 'center' }}>Download</th>
            {editing && <th />}
          </tr>
        </thead>
        <tbody>
          {documents.map(document => (
            <DocumentRow
              key={document.id}
              associatedId={props.associatedId}
              associatedType={props.associatedType}
              document={document}
              documentUploadModule={props.documentUploadModule}
              showControls={editing}
            />
          ))}
          {newDocuments.map(document => (
            <DocumentRow
              key={document.uuid}
              associatedId={props.associatedId}
              associatedType={props.associatedType}
              document={document}
              documentUploadModule={props.documentUploadModule}
              editing
              isNew
              showControls
            />
          ))}
        </tbody>
      </Table>
      {documents.length + newDocuments.length === 0 && (
        <div style={{ padding: '10px 5px' }}>There are no documents attached</div>
      )}
      {editing && (
        <Dropzone
          disableClick
          ref={refs.dropzone}
          onDrop={handlers.onFileDropOrSelection}
          accept={DOCUMENT_UPLOAD_ACCEPTED_TYPES.join(',')}
        >
          <Button onClick={handlers.onBrowseButtonClick}>Add Document</Button>
        </Dropzone>
      )}
    </div>
  );
};

DocumentUpload.propTypes = {
  documentUploadModule: PropTypes.shape({
    refs: PropTypes.shape({
      dropzone: PropTypes.shape({ current: PropTypes.instanceOf(Dropzone) }),
    }),
    state: PropTypes.shape({
      newDocuments: PropTypes.objectOf(CustomPropTypes.document).isRequired,
    }),
    handlers: PropTypes.shape({
      onFileDropOrSelection: PropTypes.func.isRequired,
      onBrowseButtonClick: PropTypes.func.isRequired,
    }),
  }).isRequired,
  associatedType: PropTypes.string.isRequired,
  associatedId: PropTypes.number.isRequired,
  classes: CustomPropTypes.classes,
  documents: PropTypes.arrayOf(CustomPropTypes.document).isRequired,
  editing: PropTypes.bool,
};

export default injectSheet(styles)(DocumentUpload);
