import hoistNonReactStatics from 'hoist-non-react-statics';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getCurrentUser } from 'selectors/currentUser';
import { getDisplayName } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

const mapStateToProps = state => ({ currentUser: getCurrentUser(state) });
const EmptyComponent = props => props.children;

export const requireRoles = (ReplacementComponent, ...roles) => (WrappedComponent = EmptyComponent) => {
  let displayName = `${roles.length === 1 ? 'Role' : 'Roles'}Required(${roles.join(',')})`;
  if (WrappedComponent) displayName += `(${getDisplayName(WrappedComponent)})`;

  class RolesRequired extends Component {
    static displayName = displayName;
    static propTypes = { currentUser: CustomPropTypes.user };

    hasRequiredRoles = () => {
      const matchedRoles = roles.filter(role => this.props.currentUser.roles.includes(role));
      return matchedRoles.length === roles.length;
    };

    render() {
      return this.hasRequiredRoles() ? <WrappedComponent {...this.props} /> : <ReplacementComponent />;
    }
  }

  if (WrappedComponent) hoistNonReactStatics(RolesRequired, WrappedComponent);

  return connect(mapStateToProps, null)(RolesRequired);
};
