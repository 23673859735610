import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { unloadClient, updateClient } from 'actions/clients';
import showPageFactory from 'hoc/showPage';
import { getErrorsFor } from 'selectors/validation';
import { fetchClient } from 'utils/api';

import ShowClient from './ShowClient';

class ShowClientContainer extends Component {
  static propTypes = {
    unloadClient: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.unloadClient();
  }

  getChildProps = () => ({ ...omit(this.props, ['unloadClient']) });

  render() {
    return <ShowClient {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  errors: getErrorsFor('client')(state),
});

export default compose(
  showPageFactory({ endpoint: fetchClient, action: updateClient }),
  connect(mapStateToProps, { unloadClient })
)(ShowClientContainer);
