import styled, { css } from 'styled-components';

export const font = '"Lato", sans-serif';

export const colors = {
  background: '#f4f4f4',
  lightBlue: '#5f7583',
  darkBlue: '#212435',
  gray: '#e3e3e3',
  lightGray: '#898f9a',
  darkGray: '#50535f',
  darkerGray: '#424242',
  grayBorder: '#bfbfbf',
  darkRed: '#cf383e',
  lightRed: '#d13838',
  orange: '#e8a23d',
  green: '#90b13e',
  trueWhite: '#ffffff',
};

export const page = {
  width: 1280,
  gutter: 30,
  header: {
    height: 60,
  },
};

export const hasShadow = {
  'box-shadow': '0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1)',
};

export const transitions = {
  inputBorderColor: 'border-color 300ms ease',
};

export const jssColorPlaceholder = (color, opacity = 1) => ({
  '&::-webkit-input-placeholder': { color, opacity },
  '&::-moz-placeholder': { color, opacity },
  '&:-ms-input-placeholder': { color, opacity },
  '&:-moz-placeholder': { color, opacity },
});

export const formGroup = {
  'margin-bottom': 20,
};

export const formLabel = {
  display: 'inline-block',
  'margin-bottom': 10,
  'font-weight': 'bold',
};

export const formControls = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  'margin-top': 20,
};

export const formControl = {
  extend: jssColorPlaceholder('#444'),
  width: '100%',
  padding: 10,
  outline: 'none',
  background: 'white',
  'font-size': '14px',
  border: `1px solid ${colors.grayBorder}`,
  'border-radius': 5,
  color: 'black',
  transition: [transitions.inputBorderColor],
  '&:-webkit-autofill': {
    'box-shadow': `0 0 0px 1000px white inset`,
  },
  '&:focus': {
    'border-color': colors.green,
  },
};

export const inlineContainer = {
  display: 'inline-block',
  '&:not(:last-child)': {
    margin: {
      right: 10,
    },
  },
};

export const formControlCSS = css`
  width: 100%;
  padding: 10px;
  outline: none;
  background: white;
  font-size: 14px;
  border: 1px solid ${colors.grayBorder};
  border-radius: 5px;
  color: black;
  transition: ${transitions.inputBorderColor};

  &:focus {
    border-color: ${colors.green};
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
  }

  &::placeholder {
    color: #444;
    opacity: 1;
  }

  &.errors {
    border-color: ${colors.darkRed};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #7f7f7f;
      cursor: not-allowed;
    `}
`;

export const InlineContainer = styled.div`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
