export const BillingType = {
  VARIABLE: 'variable',
};

export const BillingClass = {
  CONSOLIDATED: 'Consolidated',
  DETAILED: 'Detailed',
};

export const DOCUMENT_UPLOAD_ACCEPTED_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const VALIDATION_MODE = 'onChange';
