import engagementsLogoSrc from '../../assets/images/moser-engagements-logo.png';
import { colors, formGroup, formLabel, formControls } from 'theme';

export default {
  loginWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loginTitle: {
    backgroundImage: `url(${engagementsLogoSrc})`,
    textIndent: '-7777px',
    overflow: 'hidden',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '59px',
    width: '430px',
    maxWidth: '100%',
    margin: 'auto',
  },
  formGroup,
  formLabel,
  formControls,
  success: {
    textAlign: 'center',
    color: colors.green,
  },
  error: {
    textAlign: 'center',
    color: colors.darkRed,
  },
  azureLogin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.trueWhite,
    border: '1px solid rgba(255,255,255,.54)',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.5)',
    borderRadius: '3px',
    padding: '8px 0',
    textDecoration: 'none',
    margin: 'auto',
    cursor: 'pointer',
    width: '300px',
    marginTop: '20px',
    '& img': {
      width: '28px',
      height: '34px',
      verticalAlign: 'middle',
    },
    '& span': {
      marginLeft: '20px',
      color: colors.darkerGray,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
    },
  },
};
