const joinFieldNames = (...fieldNames) => {
  return fieldNames.filter(Boolean).join('.');
};

export const combineErrors = (formErrors, serverErrors, nestedFieldName) => {
  const errors = { ...serverErrors };
  for (const [originalFieldName, value] of Object.entries(formErrors)) {
    if (value.message) {
      let fieldName = originalFieldName.replace('Id', '');
      if (nestedFieldName) {
        fieldName = joinFieldNames(nestedFieldName.replace('Attributes', ''), fieldName);
      }

      errors[fieldName] = [value.message.replace(`${joinFieldNames(nestedFieldName, originalFieldName)} `, '')];
    } else {
      Object.assign(
        errors,
        combineErrors(formErrors[originalFieldName], serverErrors[originalFieldName] ?? {}, originalFieldName)
      );
    }
  }
  return errors;
};
