import { call, put } from 'redux-saga/effects';

import { receiveResources } from 'actions/resources';
import { fetchEngagementResources } from 'utils/api';

export default function* fetchEngagementResourcesSaga({ payload: engagementId }) {
  if (engagementId) {
    try {
      const { resources } = yield call(fetchEngagementResources, engagementId);
      yield put(receiveResources(resources));
    } catch (e) {
      console.log('something went wrong, ', e);
    }
  }
}
