import classnames from 'classnames';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import CustomPropTypes from 'utils/propTypes';

import styles from './FormGroup.styles';

const FormGroup = ({ label, children, highlighted, errors, classes }) => (
  <div
    className={classnames(classes.FormGroup, {
      [classes.updatedField]: highlighted,
      [classes.errors]: errors,
    })}
  >
    {label && <label className={classes.label}>{label}</label>}
    <div className={classes.flex}>{children}</div>
  </div>
);

FormGroup.propTypes = {
  children: PropTypes.node,
  classes: CustomPropTypes.classes,
  errors: PropTypes.arrayOf(PropTypes.string),
  highlighted: PropTypes.bool,
  label: PropTypes.string,
};

export default injectSheet(styles)(FormGroup);
