import { call, put } from 'redux-saga/effects';

import { receiveProjectStatuses } from 'actions/projectStatuses';
import { fetchEngagementProjectStatuses } from 'utils/api';

export default function* fetchEngagementProjectStatusesSaga({ payload: engagementId }) {
  if (engagementId) {
    try {
      const { projectStatuses } = yield call(fetchEngagementProjectStatuses, engagementId);

      yield put(receiveProjectStatuses(projectStatuses));
    } catch (e) {
      console.log('something went wrong, ', e);
    }
  }
}
