import apiFetch from './apiFetch';

const apiRequestCache = new Map();
const DEFAULT_EXPIRATION = 1000 * 60 * 5;

const setCacheItem = (key, value, expiration) => {
  apiRequestCache.set(key, {
    expires: Date.now() + expiration,
    value,
  });
};

const getCacheItem = key => {
  const cachedItem = apiRequestCache.get(key);
  if (!cachedItem) return null;

  if (Date.now() > cachedItem.expires) {
    apiRequestCache.delete(key);
    return null;
  }

  return cachedItem.value;
};

export const apiFetchCached = async ({ key, expiration = DEFAULT_EXPIRATION }, ...args) => {
  const cacheItem = getCacheItem(key);
  if (cacheItem) return cacheItem;

  const response = await apiFetch(...args);
  setCacheItem(key, response, expiration);

  return response;
};
