import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import AddButton from 'components/buttons/AddButton';
import SearchButton from 'components/buttons/SearchButton';
import Loader from 'components/Loader';
import Page from 'components/Page';
import SearchBox from 'components/SearchBox';
import Tab from 'components/Tab';
import TabGroup from 'components/TabGroup';
import ClientsTable from 'components/tables/ClientsTable';
import CustomPropTypes from 'utils/propTypes';

const ClientsIndex = ({ clients, filter, isFetching, searching, query, updateQuery, updateFilter, toggleSearch }) => {
  const headerAdditions = (
    <div style={{ display: 'flex' }}>
      <TabGroup value={filter} onChange={updateFilter}>
        <Tab value="open">Active</Tab>
        <Tab value="new">New</Tab>
        <Tab value="updated">Updated</Tab>
        <Tab value="closed">Inactive</Tab>
      </TabGroup>
      <SearchButton onClick={toggleSearch} active={searching} />
      <AddButton linkTo="/clients/new" />
    </div>
  );

  return (
    <Page title="Clients" headerAdditions={headerAdditions}>
      {searching && <SearchBox placeholder="Search by name or sales rep name" onChange={updateQuery} value={query} />}
      {isFetching ? <Loader /> : <ClientsTable clients={orderBy(clients, 'name')} />}
    </Page>
  );
};

ClientsIndex.propTypes = {
  clients: CustomPropTypes.clients,
  filter: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  query: PropTypes.string.isRequired,
  searching: PropTypes.bool,
  toggleSearch: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
};

export default ClientsIndex;
