import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorList from 'components/ErrorList';
import EngagementManagerSelect from 'components/inputs/EngagementManagerSelect';
import ResourceImport from 'components/ResourceManager/ResourceImport';
import CustomPropTypes from 'utils/propTypes';

const ResourcesFields = ({ engagement, errors = {}, editing = false }) => (
  <div>
    <ErrorList errors={errors} fields={{ engagementManager: 'engagement manager' }} />
    <FieldRow>
      <FieldItem>
        <label>Engagement Manager</label>
        <EngagementManagerSelect
          name="engagementManagerId"
          errors={errors.engagementManager}
          width={300}
          showEnabledUsers
        />
      </FieldItem>
      {editing && (
        <FieldItem>
          <label>Import Resources</label>
          <ResourceImport engagement={engagement} width={250} />
        </FieldItem>
      )}
    </FieldRow>
  </div>
);

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const FieldItem = styled.div`
  display: flex;

  align-items: center;

  label {
    font-weight: bold;
    margin-right: 20px;
  }
`;

ResourcesFields.propTypes = {
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement,
  errors: PropTypes.object,
};

export default ResourcesFields;
