import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import ValidationSection from 'components/ValidationSection';

const defaultHeader = <h4 style={{ margin: 0 }}>This section contains the following errors:</h4>;

const ErrorList = ({ errors, fields, header = defaultHeader }) => {
  const filteredErrors = Object.keys(pick(errors, Object.keys(fields)));

  if (filteredErrors.length === 0) return null;

  return (
    <ValidationSection>
      {header}
      <ul style={{ marginTop: 0 }}>
        {filteredErrors.map(fieldName => (
          <li key={fieldName}>
            {fields[fieldName]} {errors[fieldName].join(' and ')}
          </li>
        ))}
      </ul>
    </ValidationSection>
  );
};

ErrorList.propTypes = {
  errors: PropTypes.object,
  fields: PropTypes.object.isRequired,
  header: PropTypes.element,
};

export default ErrorList;
