import whiteMarkSrc from '../../assets/images/white-mark.png';

export default {
  Logo: {
    position: 'relative',
    height: 95,
    'line-height': '86px',
    padding: {
      left: 110,
    },
    'font-size': '30px',
    color: '#212435',
    '&:before': {
      content: '""',
      width: 95,
      height: 95,
      background: {
        image: `url(${whiteMarkSrc})`,
        repeat: 'no-repeat',
        size: 'contain',
      },
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  moser: {
    color: '#212435',
    'font-weight': 'bold',
  },
  engagements: {
    color: '#5F7583',
    margin: {
      left: 5,
    },
  },
};
