import { fetchClients, fetchClientsSuccess, createClient, createClientSuccess, clientInvalid } from 'actions/clients';
import { createGenericReducer } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'clients',
  actionTypes: {
    fetch: fetchClients,
    fetchSuccess: fetchClientsSuccess,
    create: createClient,
    createSuccess: createClientSuccess,
    invalid: clientInvalid,
  },
});
