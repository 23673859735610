import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { buildOptionsFromArray } from 'utils/common';

const DEFAULT_OPTIONS = {
  formatOptions: {},
  queryOptions: {},
};

export const useReferenceQuery = (queryName, queryMethod, options = DEFAULT_OPTIONS) => {
  const queryResult = useQuery(queryName, queryMethod, options?.queryOptions);

  const dataAsOptions = useMemo(() => {
    const dataOptions = buildOptionsFromArray(queryResult.data, options?.formatOptions);
    return sortBy(dataOptions, 'label');
  }, [queryResult.data, options?.formatOptions]);

  return { ...queryResult, dataAsOptions };
};
