import { List } from 'immutable';

import {
  addNewResource,
  addNewResources,
  clearNewResources,
  clearResourceEditing,
  fetchEngagementResources,
  fetchResourceRolesSuccess,
  receiveResource,
  receiveResources,
  removeNewResource,
  resourceDestroyed,
  toggleResourceEditing,
  unloadResources,
} from 'actions/resources';

import { createGenericReducer, genericFetchHandler, orderedMapFromArray } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'resources',
  initialState: {
    engagementId: null,
    newResources: List(),
    editing: List(),
    roles: List(),
  },
  actionTypes: {
    fetchSuccess: receiveResources,
    destroy: resourceDestroyed,
    unload: unloadResources,
  },
  actionHandlers: {
    [fetchEngagementResources]: (state, { payload: engagementId }) => {
      return genericFetchHandler(state.set('engagementId', engagementId));
    },
    [addNewResource]: (state, { payload }) => state.update('newResources', list => list.push(payload)),
    [addNewResources]: (state, { payload }) => state.update('newResources', list => list.push(...payload)),
    [removeNewResource]: (state, { payload: uuid }) =>
      state.update('newResources', list =>
        list.find(newResource => newResource.uuid === uuid)
          ? list.delete(list.findIndex(newResource => newResource.uuid === uuid))
          : list
      ),
    [clearNewResources]: state => state.update('newResources', list => list.clear()),
    [receiveResource]: (state, { payload: resource }) =>
      state.update('resources', resources => resources.set(resource.id, resource)),
    [toggleResourceEditing]: (state, { payload: resourceId }) =>
      state.update('editing', list =>
        list.includes(resourceId) ? list.delete(list.indexOf(resourceId)) : list.push(resourceId)
      ),
    [clearResourceEditing]: state => state.update('editing', list => list.clear()),
    [fetchResourceRolesSuccess]: (state, { payload }) => {
      return state.set('roles', orderedMapFromArray(payload));
    },
  },
});
