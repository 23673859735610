import * as theme from '../../theme';

export default {
  Page: {
    margin: [theme.page.gutter, 'auto', theme.page.gutter],
    'max-width': theme.page.width,
    'min-width': 715,
    [`@media screen and (max-width: ${theme.page.width + theme.page.gutter * 2}px)`]: {
      margin: theme.page.gutter,
    },
  },
  breadcrumb: {
    display: 'flex',
    'align-items': 'center',
    height: theme.page.gutter * 1.5,
    'font-size': '14px',
    '& a': {
      color: theme.colors.lightBlue,
    },
  },
  container: {
    display: 'flex',
    'flex-direction': 'column',
    extend: theme.hasShadow,
  },
  header: {
    display: 'flex',
    'align-items': 'center',
    background: theme.colors.darkBlue,
    color: 'white',
    padding: 0,
    height: 60,
  },
  title: {
    'text-transform': 'uppercase',
    'font-weight': 'normal',
    'font-size': '20px',
    padding: '0 15px',
    margin: '0',
    flex: '1 1 100%',
  },
  headerAdditions: {
    flex: '1 1 100%',
    height: '100%',
    display: 'flex',
    'justify-content': 'flex-end',
    'align-items': 'center',
  },
  body: {
    background: 'white',
  },
};
