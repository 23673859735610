import dayjs from 'lib/dayjs';

import CustomPropTypes from 'utils/propTypes';

/* StatusRow */
const StatusRow = ({ projectStatus }) => (
  <tr>
    <td>{<span>{dayjs(projectStatus.updatedAt).format('M/DD/YYYY')}</span>}</td>
    <td>{projectStatus.reason}</td>
  </tr>
);

StatusRow.propTypes = {
  projectStatus: CustomPropTypes.projectStatus,
};

export default StatusRow;
