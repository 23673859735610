import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { createClient, updateClient, fetchClients } from 'actions/clients';

import { createClientSaga } from './createClient';
import { updateClientSaga } from './updateClient';
import { fetchClientsSaga } from './fetchClients';

export default function* clientsSaga() {
  yield all([
    takeEvery(updateClient, updateClientSaga),
    takeEvery(createClient, createClientSaga),
    takeLatest(fetchClients, fetchClientsSaga),
  ]);
}
