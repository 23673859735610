import classnames from 'classnames';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Table from 'components/Table';
import { billingTypes } from 'utils/common';
import { pushRoute } from 'utils/navigation';
import CustomPropTypes from 'utils/propTypes';

const TableHeader = styled.th`
  cursor: pointer;
  white-space: nowrap;
`;

const SortingIcon = styled.i`
  font-size: 14px;
  margin-left: 5px;
`;

const SortIndicator = ({ sorting, direction }) => {
  if (!sorting) return null;
  const iconClass = direction === 1 ? 'fa-caret-up' : 'fa-caret-down';
  return <SortingIcon className={classnames('fa', iconClass)} />;
};

export const EngagementsTable = props => {
  return props.engagements.length ? (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <tr>
            <TableHeader onClick={() => props.sortBy('client')}>
              Client <SortIndicator sorting={props.sortKey === 'client'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('name')}>
              Engagement Name <SortIndicator sorting={props.sortKey === 'name'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('engagementManager')}>
              Engagement Manager{' '}
              <SortIndicator sorting={props.sortKey === 'engagementManager'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('department')}>
              Department <SortIndicator sorting={props.sortKey === 'department'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('projectId')} className="center">
              Project # <SortIndicator sorting={props.sortKey === 'projectId'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('endDate', 'date')} className="center">
              End Date <SortIndicator sorting={props.sortKey === 'endDate'} direction={props.sortDirection} />
            </TableHeader>
            <TableHeader onClick={() => props.sortBy('billingType')}>
              Billing Type <SortIndicator sorting={props.sortKey === 'billingType'} direction={props.sortDirection} />
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {props.engagements.map(
            ({ id, name, engagementManager: manager, projectId, endDate, department, client, billingType }) => {
              const endDateDay = dayjs(endDate);
              const endDateFormatted = endDateDay.isValid() ? endDateDay.format('MM/DD/YYYY') : 'Open';
              const billingTypeName = billingTypes[billingType];

              return (
                <tr key={id} onClick={() => pushRoute(`/engagements/${id}`)}>
                  <td>{client ? client.name : 'Unspecified'}</td>
                  <td>{name}</td>
                  <td>{manager ? manager.name : 'Unspecified'}</td>
                  <td>{department ? department.name : 'Unspecified'}</td>
                  <td className="center">{projectId || 'N/A'}</td>
                  <td className="center">{endDateFormatted}</td>
                  <td>{billingTypeName}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>
    </div>
  ) : (
    <div style={{ padding: '20px' }}>There are no engagements to show 😭</div>
  );
};

EngagementsTable.propTypes = {
  engagements: CustomPropTypes.engagements.isRequired,
  sortBy: PropTypes.func.isRequired,
  sortDirection: PropTypes.number.isRequired,
  sortKey: PropTypes.string.isRequired,
};
