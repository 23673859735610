import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RHFSelect, UncontrolledSelect } from 'components/inputs/Select';
import { useEngagementManagers } from 'hooks/referenceData/useEngagementManagers';
import { selectors as appStateSelectors } from 'modules/app';

const EngagementManagerSelect = ({ uncontrolled, showEnabledUsers, ...props }) => {
  const { filter } = useSelector(appStateSelectors.byStateKey('engagements'));

  const params = {};
  if (!['closed', 'close_requested'].includes(filter) || showEnabledUsers) params.account_enabled = true;

  const { dataAsOptions, isLoading, refetch } = useEngagementManagers(params);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const SelectComponent = uncontrolled ? UncontrolledSelect : RHFSelect;
  return (
    <SelectComponent placeholder="Select engagement manager" options={dataAsOptions} isLoading={isLoading} {...props} />
  );
};

EngagementManagerSelect.propTypes = {
  ...UncontrolledSelect.propTypes,
  uncontrolled: PropTypes.bool,
};

export default EngagementManagerSelect;
