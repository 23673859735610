import Button from 'components/Button';
import Icon from 'components/Icon';
import * as theme from 'theme';

const EditButton = props => (
  <Button theme="square" style={{ background: theme.colors.green }} {...props}>
    <Icon type="pencil" />
  </Button>
);

export default EditButton;
