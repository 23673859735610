import intersection from 'lodash/intersection';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';

import ProjectInfoFields from 'components/fields/ProjectInfoFields';
import FormGroup from 'components/FormGroup';
import Icon from 'components/Icon';
import { engagementTypes } from 'utils/common';
import CustomPropTypes from 'utils/propTypes';

const EditableProjectInfo = ({ editing, engagement, errors }) =>
  editing ? (
    <ProjectInfoFields engagement={engagement} errors={errors} />
  ) : (
    <div>
      <FormGroup label="Project Name" highlighted={engagement.updatedEngagementFields.includes('name')}>
        <span>{engagement.name}</span>
      </FormGroup>
      <FormGroup label="Department" highlighted={engagement.updatedEngagementFields.includes('department_id')}>
        <span>{engagement.department ? engagement.department.name : 'Not yet specified'}</span>
      </FormGroup>
      <FormGroup label="Project #" highlighted={engagement.updatedEngagementFields.includes('project_id')}>
        <span>{engagement.projectId || 'Not yet created'}</span>
      </FormGroup>
      <FormGroup label="Project Start Date" highlighted={engagement.updatedEngagementFields.includes('start_date')}>
        <span>{dayjs(engagement.startDate).format('M/DD/YYYY')}</span>
      </FormGroup>
      <FormGroup label="Project End Date" highlighted={engagement.updatedEngagementFields.includes('end_date')}>
        <span>{engagement.endDate ? dayjs(engagement.endDate).format('M/DD/YYYY') : 'Open'}</span>
      </FormGroup>
      <FormGroup
        label="Client Manager"
        highlighted={intersection(engagement.updatedEngagementFields, ['cm_name', 'cm_email', 'cm_phone']).length > 0}
      >
        <span>{engagement.cmName}</span>
        <br />
        {engagement.cmEmail && (
          <span>
            <Icon type="envelope" style={{ marginRight: 5 }} />
            <a href={`mailto:${engagement.cmEmail}`}>{engagement.cmEmail}</a>
          </span>
        )}
        {engagement.cmPhone && (
          <span>
            <Icon type="phone" style={{ margin: '0 5px 0 15px' }} />
            {engagement.cmPhone}
          </span>
        )}
      </FormGroup>
      <FormGroup label="Project Description" highlighted={engagement.updatedEngagementFields.includes('description')}>
        <div
          style={{
            lineHeight: '1.2rem',
            fontSize: '.85rem',
            marginTop: '12px',
            textAlign: 'justify',
          }}
        >
          {engagement.description || 'N/A'}
        </div>
      </FormGroup>
      <FormGroup label="Engagement Type" highlighted={engagement.updatedEngagementFields.includes('engagement_type')}>
        <span>{engagement.engagementType ? engagementTypes[engagement.engagementType] : 'N/A'}</span>
      </FormGroup>
    </div>
  );

EditableProjectInfo.propTypes = {
  editing: PropTypes.bool,
  engagement: CustomPropTypes.engagement,
  errors: PropTypes.object,
};

export default EditableProjectInfo;
