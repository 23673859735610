import styled from 'styled-components';
import { colors } from 'theme';

const LOADER_WIDTH = 75;

const LoaderWrapper = styled.div`
  padding: 5%;

  .loader {
    position: relative;
    margin: 0 auto;
    width: ${LOADER_WIDTH}px;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: ${colors.green};
    }
    40% {
      stroke: ${colors.lightBlue};
    }
    66% {
      stroke: ${colors.green};
    }
    80%,
    90% {
      stroke: ${colors.lightGray};
    }
  }
`;

const Loader = ({ classes }) => (
  <LoaderWrapper>
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  </LoaderWrapper>
);

export default Loader;
