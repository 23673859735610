import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchEngagementResources, destroyResource, saveResource, fetchResourceRoles } from 'actions/resources';

import destroyResourceSaga from './destroyResource';
import fetchEngagementResourcesSaga from './fetchEngagementResources';
import saveResourceSaga from './saveResource';
import { fetchRolesSaga } from './fetchRoles';

export default function* resourcesSaga() {
  yield all([
    takeEvery(destroyResource, destroyResourceSaga),
    takeLatest(fetchEngagementResources, fetchEngagementResourcesSaga),
    takeEvery(saveResource, saveResourceSaga),
    takeLatest(fetchResourceRoles, fetchRolesSaga),
  ]);
}
