import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import BaseSelect from 'react-select';
import BaseCreatable from 'react-select/creatable';
import styled from 'styled-components';
import classnames from 'classnames';

import { colors, InlineContainer, transitions } from 'theme';

const SelectContainer = styled(InlineContainer)`
  vertical-align: top;
  font-size: 14px;
  &.errors div div {
    border-color: ${colors.darkRed};
  }
`;

const defaultStyles = {
  control: (provided, state) => {
    let borderColor = state.isFocused ? colors.green : colors.grayBorder;

    return {
      ...provided,
      borderRadius: 5,
      borderColor,
      boxShadow: 'none',
      transition: [transitions.inputBorderColor],
      '&:hover': {
        borderColor: null,
      },
    };
  },
  singleValue: provided => ({
    ...provided,
    position: 'static',
    transform: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#444',
    opacity: 1,
    position: 'static',
    transform: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

// TODO: MOSR-1087 handle errors styling
export const UncontrolledSelect = ({ className, creatable, onChange, errors, styles, width, ...props }) => {
  const BaseComponent = useMemo(() => (creatable ? BaseCreatable : BaseSelect), [creatable]);

  const selectedOption = useMemo(() => {
    const value = props.value !== undefined ? props.value : props.defaultValue;
    return value && props.options.find(o => o.value === value);
  }, [props.options, props.value, props.defaultValue]);

  const combinedStyles = useMemo(() => ({ ...defaultStyles, ...styles }), [styles]);

  return (
    <SelectContainer style={{ width }} className={classnames(className, { errors: !!errors })}>
      <BaseComponent
        clearable={false}
        {...props}
        styles={combinedStyles}
        getOptionValue={x => x.value}
        components={{ IndicatorSeparator: () => null }}
        onChange={onChange ? ({ value }) => onChange(value) : undefined}
        value={selectedOption}
      />
    </SelectContainer>
  );
};

UncontrolledSelect.propTypes = {
  className: PropTypes.string,
  creatable: PropTypes.bool,
  defaultValue: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  styles: PropTypes.object,
  value: PropTypes.any,
  width: PropTypes.number,
  onChange: PropTypes.func,
};

export const RHFSelect = ({ options, ...props }) => {
  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <UncontrolledSelect
          {...props}
          onBlur={field.onBlur}
          onChange={field.onChange}
          options={options}
          value={field.value}
        />
      )}
    />
  );
};

export default RHFSelect;
