import { call, put, select } from 'redux-saga/effects';

import { updateClientSuccess, clientInvalid } from 'actions/clients';
import { pushRoute } from 'utils/navigation';
import * as api from 'utils/api';
import { unsavedDocumentCount } from 'modules/documents';

export function* updateClientSaga({ payload: { id, ...attributes } }) {
  const unsaved = yield select(unsavedDocumentCount);

  if (unsaved) {
    const message = 'You have unsaved documents. Please make sure all documents are saved before continuing.';
    yield call(window.alert, message);
  } else {
    try {
      const { client } = yield call(api.updateClient, id, attributes);

      if (client) {
        if (client.errors) {
          yield put(clientInvalid(client.errors));
        } else {
          yield put(updateClientSuccess(client));
          yield call(pushRoute, '/clients');
        }
      }
    } catch (e) {
      console.warn('Something went wrong:', e);
    }
  }
}
