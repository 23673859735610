import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as actions from 'actions/currentUser';
import { actions as toastActions } from 'modules/toast';
import * as nav from 'utils/navigation';
import * as api from 'utils/api';

const toast = (type, title, message) =>
  toastActions.createToast(type, {
    title,
    message,
    timeout: 20000,
  });

const failedSignInTypes = [
  {
    name: 'permission_failed',
    message: 'Sorry, you are not enabled to login to this application',
  },
];

function* signOutService() {
  try {
    const { success } = yield call(api.signout);
    if (success) return { success };
  } catch (err) {
    return { error: 'Something went awry' };
  }
}

export function* fetchCurrentUserSaga() {
  try {
    const { currentUser, error } = yield call(api.fetchCurrentUser);

    if (currentUser && currentUser.roles.length) {
      yield put(actions.fetchCurrentUserSuccess(currentUser));
    } else if (currentUser && !currentUser.roles.length) {
      yield put(toast('alert', 'Login Failed', 'Sorry, you are not enabled to login to this application.'));
      yield put(actions.signInFailed());
    } else {
      const errorType = failedSignInTypes.find(errorType => {
        return errorType.name === error;
      });
      yield put(actions.signInFailed(errorType));
    }
  } catch (e) {
    yield put(actions.signInFailed(e));
  }
}

function* azureSignInSaga() {
  yield call(nav.azureRedirect);
}

function* azureAuthSuccessSaga({ payload: { redirectTo = '/' } }) {
  yield call(nav.replaceRoute, redirectTo);
}

function* signOutSaga() {
  yield call(signOutService);
  yield call(nav.navigateTo, '');
}

export default function* authenticationSaga() {
  yield all([
    takeEvery(actions.fetchCurrentUser, fetchCurrentUserSaga),
    takeEvery(actions.signIn, azureSignInSaga),
    takeEvery(actions.azureAuthSuccess, azureAuthSuccessSaga),
    takeEvery(actions.signOut, signOutSaga),
    put(actions.fetchCurrentUser()),
  ]);
}
