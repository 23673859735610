import PropTypes from 'prop-types';
import { useEffect } from 'react';

import Select, { UncontrolledSelect } from 'components/inputs/Select';
import { useFormContext } from 'react-hook-form';
import { useBillingContacts } from 'hooks/referenceData/useBillingContacts';

export const BillingContactSelect = ({ clientId, ...props }) => {
  const { setValue, watch } = useFormContext();
  const { data, dataAsOptions, isLoading } = useBillingContacts(clientId);

  const billingContactId = watch('billingContactId');

  useEffect(() => {
    if (!billingContactId) return;

    const matchingBillingContact = (data ?? []).find(c => c.id === billingContactId);
    if (!matchingBillingContact) setValue('billingContactId', null);
  }, [billingContactId, data, setValue]);

  return (
    <Select
      isDisabled={!clientId}
      isLoading={isLoading}
      options={dataAsOptions}
      placeholder="Select billing contact"
      {...props}
    />
  );
};

BillingContactSelect.propTypes = {
  ...UncontrolledSelect.propTypes,
  clientId: PropTypes.number,
};
