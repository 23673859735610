import PropTypes from 'prop-types';

import Checkbox from 'components/inputs/Checkbox';
import { useFormContext } from 'react-hook-form';
import { useRoles } from 'hooks/useRoles';
import Loader from 'components/Loader';

const RolesSelectGroup = ({ name }) => {
  const { data, isLoading } = useRoles();
  const { register } = useFormContext();

  if (isLoading) return <Loader />;

  const { roles } = data;

  return (
    <div>
      {roles.map(role => (
        <div key={role.id}>
          <Checkbox {...register(name)} value={`${role.id}`} label={role.title} />
        </div>
      ))}
    </div>
  );
};

RolesSelectGroup.propTypes = {
  name: PropTypes.string,
};

RolesSelectGroup.defaultProps = {
  name: 'roleIds[]',
};

export default RolesSelectGroup;
