import { createActions, handleActions } from 'redux-actions';

// default state
const defaultState = { unsaved: 0 };

// actions
export const actions = createActions('UPDATE_UNSAVED');

// selectors
export const unsavedDocumentCount = state => state.documents.unsaved;

// reducer
export const reducer = handleActions(
  {
    [actions.updateUnsaved](state, action) {
      return { ...state, unsaved: action.payload };
    },
  },
  defaultState
);
