import * as yup from 'yup';

export const resourceSchema = yup.object({
  endDate: yup.date().nullable(),
  estimate: yup
    .number()
    .default(null)
    .when('estimateType', {
      is: estimateType => ['partial', 'fixed'].includes(estimateType),
      then: schema =>
        schema
          .required()
          .min(0)
          .when('estimateType', { is: 'partial', then: schema => schema.max(100) }),
      otherwise: schema => schema.strip(),
    }),
  estimateInterval: yup.string().when('estimateType', {
    is: 'fixed',
    then: schema => schema.required(),
    otherwise: schema => schema.nullable(),
  }),
  estimateType: yup.string().required(),
  name: yup.string().notRequired(),
  rate: yup.number().min(0),
  role: yup.string().required(),
  spudUserId: yup.number().required(),
  startDate: yup.date().nullable(),
});
