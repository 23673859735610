import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import SaveCancelButtons from 'components/buttons/SaveCancelButtons';
import { errorFields } from 'components/Clients';
import DocumentUpload from 'components/DocumentUpload/DocumentUpload.container';
import ErrorList from 'components/ErrorList';
import CurrentStateSelect from 'components/inputs/CurrentStateSelect';
import Page from 'components/Page';
import Section from 'components/Section';

import EditableClientInfo from './EditableClientInfo';

const ShowClient = ({ client, loading, submit, currentUser, errors }) => {
  const formContext = useForm({
    defaultValues: {
      name: client.name,
      addressLine1: client.addressLine1,
      addressLine2: client.addressLine2,
      addressCity: client.addressCity,
      addressState: client.addressState,
      addressZipcode: client.addressZipcode,
      billingTerms: client.billingTerms,
      billingInstructions: client.billingInstructions,
      salesRepId: client.salesRepId,
    },
    shouldUnregister: true,
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={formContext.handleSubmit(submit)}>
        <Page
          title="Client Details"
          breadcrumb={{ to: '/clients', text: '< Back to Client List' }}
          headerAdditions={
            currentUser.roles.includes('admin') ? (
              <div style={{ marginRight: 15 }}>
                <CurrentStateSelect name="state" entityType="client" defaultValue={client.state} />
              </div>
            ) : null
          }
          padding={15}
        >
          <Section title="Client Information" editable>
            <ErrorList errors={errors} fields={errorFields} />
            <EditableClientInfo client={client} errors={errors} />
          </Section>
          <Section title="Documents" editable>
            <DocumentUpload associatedType="clients" associatedId={client.id} />
          </Section>
          <SaveCancelButtons />
        </Page>
      </form>
    </FormProvider>
  );
};

ShowClient.propTypes = {
  currentUser: PropTypes.shape({ roles: PropTypes.arrayOf(PropTypes.string) }).isRequired,
  errors: PropTypes.object,
  client: PropTypes.object,
  loading: PropTypes.bool,
  submit: PropTypes.func.isRequired,
};

export default ShowClient;
