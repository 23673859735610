import { call, put } from 'redux-saga/effects';

import { fetchFixedFeeProfitabilityReportSuccess } from 'actions/reports';
import { fetchFixedFeeProfitabilityReport } from 'utils/api';

export function* fetchFixedFeeProfitabilityReportSaga({ payload: params }) {
  try {
    const { results } = yield call(fetchFixedFeeProfitabilityReport, params);
    if (results.errors) {
      // TODO: handle errors
    } else {
      yield put(fetchFixedFeeProfitabilityReportSuccess(results));
    }
  } catch (e) {
    console.log('something went wrong getting the fixed fee profitability report, ', e);
  }
}
