import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ResourcesFields from 'components/fields/ResourcesFields';
import FormGroup from 'components/FormGroup';
import ResourceManager from 'components/ResourceManager';
import { useWatch } from 'react-hook-form';
import CustomPropTypes from 'utils/propTypes';

const EditableResources = ({ editing, engagement, errors }) => {
  const currentBillingType = useWatch({ name: 'billingType' });
  const billingType = currentBillingType ?? engagement.billingType;

  const shouldShowRate = useMemo(() => billingType === 'variable', [billingType]);

  return editing ? (
    <div>
      <ResourcesFields engagement={engagement} errors={errors} editing />
      <ResourceManager engagement={engagement} errors={errors} showRate={shouldShowRate} editing />
    </div>
  ) : (
    <div>
      <FormGroup
        label="Engagement Manager"
        highlighted={engagement.updatedEngagementFields.includes('engagement_manager_id')}
      >
        <span>{engagement.engagementManager.name}</span>
      </FormGroup>
      <ResourceManager engagement={engagement} showRate={shouldShowRate} />
    </div>
  );
};

EditableResources.propTypes = {
  editing: PropTypes.bool,
  errors: PropTypes.object,
  engagement: CustomPropTypes.engagement.isRequired,
};

export default EditableResources;
