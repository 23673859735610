import * as yup from 'yup';

export const baseClientFields = {
  name: yup.string().required(),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  addressCity: yup.string(),
  addressState: yup.string(),
  addressZipcode: yup.string(),
  billingTerms: yup.string(),
  billingInstructions: yup.string(),
};

export const clientSchema = yup.object({
  ...baseClientFields,
  salesRepId: yup.string().required(),
});
