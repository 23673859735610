import { call, put } from 'redux-saga/effects';

import { fetchUsersSuccess } from 'actions/users';
import { fetchEmployees } from 'utils/api';

export function* fetchUsersSaga({ payload: params }) {
  try {
    const { employees } = yield call(fetchEmployees, { account_enabled: true, ...params });
    if (employees) yield put(fetchUsersSuccess(employees));
  } catch (e) {
    console.warn('Something went wrong', e);
  }
}
