import * as theme from 'theme';

export default {
  Tab: {
    position: 'relative',
    padding: '0 15px',
    color: theme.colors.lightGray,
    display: 'flex',
    'align-items': 'center',
    height: '100%',
    cursor: 'pointer',
  },
  active: {
    color: 'white',
  },
  underline: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '4px',
    background: theme.colors.green,
  },
  badge: {
    position: 'absolute',
    top: 5,
    left: 'calc(100% - 18px)',
  },
};
