export const ESTIMATE_TYPES = {
  as_needed: 'As Needed',
  full_time: 'Full Time',
  partial: 'Partial',
  fixed: 'Fixed Hours',
};

export const ESTIMATE_INTERVALS = {
  total_project: 'total',
  monthly: 'month',
  weekly: 'week',
};

export const HOUR_INTERVAL = {
  total_project: 'Total Project',
  monthly: 'Monthly',
  weekly: 'Weekly',
};
