import { call, put } from 'redux-saga/effects';

import { fetchRolesSuccess } from 'actions/roles';
import { fetchRoles } from 'utils/api';

export function* fetchRolesSaga() {
  try {
    const { roles } = yield call(fetchRoles);

    if (roles.errors) {
      // TODO: handle errors
    } else {
      yield put(fetchRolesSuccess(roles));
    }
  } catch (e) {
    // TODO: handle connection problem and rollback
  }
}
