import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { createClient, unloadClient } from 'actions/clients';
import { getErrorsFor } from 'selectors/validation';

import NewClient from './NewClient';

class NewClientContainer extends Component {
  static propTypes = {
    createClient: PropTypes.func.isRequired,
    unloadClient: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.unloadClient();
  }

  submit = attributes => this.props.createClient(attributes);

  getChildProps() {
    return { ...this.props, submit: this.submit };
  }

  render() {
    return <NewClient {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  errors: getErrorsFor('client')(state),
});

export default connect(mapStateToProps, { createClient, unloadClient })(NewClientContainer);
