import { useReferenceQuery } from 'hooks/useReferenceQuery';
import { fetchDepartments } from '../../utils/api';

export const useDepartments = () => {
  const queryName = 'departments';

  const queryMethod = async () => {
    const { departments } = await fetchDepartments();
    return departments;
  };

  const departmentsQuery = useReferenceQuery(queryName, queryMethod);
  return departmentsQuery;
};
