import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import FormGroup from 'components/FormGroup';
import BillingTermsSelect from 'components/inputs/BillingTermsSelect';
import Input from 'components/inputs/Input';
import SalesRepSelect from 'components/inputs/SalesRepSelect';
import TextArea from 'components/inputs/TextArea';
import CustomPropTypes from 'utils/propTypes';

const booleanFilterAndJoin = (arr, delim) => arr.filter(Boolean).join(delim);
const createJoinedPath = (...arr) => booleanFilterAndJoin(arr, '.');

const ClientFields = ({ children, ...props }) => {
  const { errors = {}, path: basePath, client = {}, newEngagement } = props;

  const { register } = useFormContext();

  return (
    <>
      <FormGroup label="Client Name">
        <Input
          {...register(createJoinedPath(basePath, 'name'))}
          autoFocus
          errors={errors.name}
          placeholder="Client name"
          defaultValue={client.name}
          width={380}
        />
        {children}
      </FormGroup>
      <FormGroup label="Address">
        <FormGroup>
          <Input
            {...register(createJoinedPath(basePath, 'addressLine1'))}
            errors={errors.addressLine1}
            placeholder="Street"
            width={380}
          />
          <Input
            {...register(createJoinedPath(basePath, 'addressLine2'))}
            errors={errors.addressLine2}
            placeholder="Suite #"
            width={185}
          />
        </FormGroup>
        <FormGroup>
          <Input
            {...register(createJoinedPath(basePath, 'addressCity'))}
            errors={errors.addressCity}
            placeholder="City"
            width={255}
          />
          <Input
            {...register(createJoinedPath(basePath, 'addressState'))}
            errors={errors.addressState}
            placeholder="State"
            width={115}
          />
          <Input
            {...register(createJoinedPath(basePath, 'addressZipcode'))}
            errors={errors.addressZipcode}
            placeholder="Zip"
            width={185}
          />
        </FormGroup>
      </FormGroup>
      <FormGroup label="Billing Terms">
        <BillingTermsSelect
          name={createJoinedPath(basePath, 'billingTerms')}
          errors={errors.billingTerms}
          width={200}
        />
      </FormGroup>
      <FormGroup label="Billing Instructions">
        <TextArea
          {...register(createJoinedPath(basePath, 'billingInstructions'))}
          errors={errors.billingInstructions}
        />
      </FormGroup>
      {!newEngagement && (
        <FormGroup label="Sales Representative">
          <SalesRepSelect
            name={createJoinedPath(basePath, 'salesRepId')}
            errors={errors.salesRep}
            width={380}
            showEnabledUsers
          />
        </FormGroup>
      )}
    </>
  );
};

ClientFields.propTypes = {
  children: PropTypes.node,
  client: CustomPropTypes.client,
  errors: PropTypes.object,
  newEngagement: PropTypes.bool,
  path: PropTypes.string,
};

export default ClientFields;
