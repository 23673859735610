import PropTypes from 'prop-types';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import v4 from 'uuid/v4';

import Loader from 'components/Loader';
import { fetchAssociatedDocuments } from 'utils/api';
import CustomPropTypes from 'utils/propTypes';
import { actions as documentsActions } from 'modules/documents';

import DocumentUpload from './DocumentUpload';

const initialState = {
  newDocuments: {},
  editingDocuments: [],
  edited: false,
};

const documentsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_NEW_DOCUMENT': {
      const { uuid, file } = action.payload;
      const newDocument = { uuid, file, name: file?.name ?? '', description: '' };
      const newDocuments = { ...state.newDocuments, [action.payload.uuid]: newDocument };

      return { ...state, newDocuments, edited: true };
    }
    case 'REMOVE_NEW_DOCUMENT': {
      const { [action.payload]: _, ...newDocuments } = state.newDocuments;

      return { ...state, newDocuments, edited: true };
    }
    case 'TOGGLE_DOCUMENT_EDITING': {
      const shouldDisable = state.editingDocuments.includes(action.payload);
      const editingDocuments = shouldDisable
        ? state.editingDocuments.filter(d => d !== action.payload)
        : [...state.editingDocuments, action.payload];

      return { ...state, editingDocuments, edited: true };
    }
    case 'RESET': {
      return { ...initialState };
    }
    default:
      return state;
  }
};

/**
 * Pushes a total # of unsaved (edited or new) documents to the global store.
 * This allows us to display a warning when the user tries to leave the page with unsaved documents.
 */
const useGlobalUnsavedDocumentsPatch = state => {
  const dispatch = useDispatch();

  // update the global unsaved documents count any time the state changes
  useEffect(() => {
    dispatch(documentsActions.updateUnsaved(state.editingDocuments.length + Object.keys(state.newDocuments).length));
  }, [dispatch, state]);

  // reset the global unsaved documents count on unmount
  useEffect(() => () => dispatch(documentsActions.updateUnsaved(0)), [dispatch]);
};

const useDocumentUploadModule = () => {
  const [state, dispatch] = useReducer(documentsReducer, initialState);

  useGlobalUnsavedDocumentsPatch(state);

  // memoized callbacks
  const addNewDocument = useCallback(file => {
    dispatch({ type: 'ADD_NEW_DOCUMENT', payload: { uuid: v4(), file } });
  }, []);

  const removeNewDocument = useCallback(documentUuid => {
    dispatch({ type: 'REMOVE_NEW_DOCUMENT', payload: documentUuid });
  }, []);

  const toggleEditing = useCallback(documentId => {
    dispatch({ type: 'TOGGLE_DOCUMENT_EDITING', payload: documentId });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  const actions = {
    addNewDocument,
    removeNewDocument,
    toggleEditing,
    reset,
  };

  const dropzone = useRef();
  const refs = { dropzone };

  const onBrowseButtonClick = useCallback(() => dropzone.current.open(), []);
  const onFileDropOrSelection = useCallback(files => files.forEach(actions.addNewDocument), [actions.addNewDocument]);
  const handlers = {
    onBrowseButtonClick,
    onFileDropOrSelection,
  };

  return { state, actions, refs, handlers };
};

export const DocumentUploadContainer = ({ associatedType, associatedId, editing, documents }) => {
  const documentUploadModule = useDocumentUploadModule();

  const {
    state,
    actions: { reset },
  } = documentUploadModule;

  useEffect(() => {
    if (state.edited && !editing) reset();
  }, [editing, state.edited, reset]);

  return (
    <DocumentUpload
      documentUploadModule={documentUploadModule}
      associatedType={associatedType}
      associatedId={associatedId}
      documents={documents}
      editing={editing}
    />
  );
};

DocumentUploadContainer.propTypes = {
  documents: PropTypes.arrayOf(CustomPropTypes.document).isRequired,
  associatedType: PropTypes.string.isRequired,
  associatedId: PropTypes.number.isRequired,
  editing: PropTypes.bool,
};

DocumentUploadContainer.defaultProps = {
  editing: false,
};

export const DocumentUploadLoader = ({ associatedType, associatedId, editing }) => {
  const { data, isLoading } = useQuery(['documents', { associatedType, associatedId }], () =>
    fetchAssociatedDocuments(associatedType, associatedId)
  );

  if (isLoading) return <Loader />;

  return (
    <DocumentUploadContainer
      associatedType={associatedType}
      associatedId={associatedId}
      documents={data.documents}
      editing={editing}
    />
  );
};

export default DocumentUploadLoader;
