import { useEffect, useRef } from 'react';

export const useMountedStateRef = () => {
  const mountedStateRef = useRef(false);

  useEffect(() => {
    mountedStateRef.current = true;
    return () => {
      mountedStateRef.current = false;
    };
  }, []);

  return mountedStateRef;
};
