import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RHFSelect, UncontrolledSelect } from 'components/inputs/Select';
import { useSalesReps } from 'hooks/referenceData/useSalesReps';
import { selectors as appStateSelectors } from 'modules/app';

const defaultOption = { label: 'HA - House Acct.', value: '-1' };

const SalesRepSelect = ({ uncontrolled, showEnabledUsers, ...props }) => {
  const { filter } = useSelector(appStateSelectors.byStateKey('engagements'));

  const params = {};
  if (!['closed', 'close_requested'].includes(filter) || showEnabledUsers) params.account_enabled = true;

  const { dataAsOptions, isLoading, refetch } = useSalesReps(params);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  const SelectComponent = uncontrolled ? UncontrolledSelect : RHFSelect;
  return (
    <SelectComponent
      placeholder="Select sales representative"
      options={[defaultOption, ...dataAsOptions]}
      isLoading={isLoading}
      {...props}
    />
  );
};

SalesRepSelect.propTypes = {
  ...UncontrolledSelect.propTypes,
  uncontrolled: PropTypes.bool,
};

export default SalesRepSelect;
