import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/propTypes';
import DocumentRow from './DocumentRow';
import EditDocumentRow from './EditDocumentRow';
import { useMutation } from 'react-query';
import * as api from 'utils/api';
import { queryClient } from 'lib/reactQuery';
import { useCallback, useMemo } from 'react';
import { actions as toastActions } from 'modules/toast';
import { useDispatch } from 'react-redux';

const { createToast } = toastActions;

const useMutations = (associatedType, associatedId) => {
  const dispatch = useDispatch();

  const invalidateAssociatedDocuments = () => {
    return queryClient.invalidateQueries({ queryKey: ['documents', { associatedType, associatedId }] });
  };

  const create = useMutation({
    mutationFn: document => api.createAssociatedDocument(associatedType, associatedId, document),
    onSuccess: data => {
      const isValidFile = data.isValidFile ?? data.document?.isValidFile;

      if (!isValidFile) {
        dispatch(
          createToast('alert', {
            title: 'Invalid File Type',
            message: 'Please choose a .pdf, .docx, or .xlsx file to upload.',
          })
        );
      }

      return invalidateAssociatedDocuments();
    },
  });

  const update = useMutation(({ id, ...attributes }) => api.updateDocument(id, attributes), {
    onSuccess: invalidateAssociatedDocuments,
  });

  const destroy = useMutation(({ id }) => api.destroyDocument(id), {
    onSuccess: () => {
      invalidateAssociatedDocuments();
      console.log('success');
    },
  });

  return { create, update, destroy };
};

export const DocumentRowContainer = ({ associatedType, associatedId, ...props }) => {
  const {
    documentUploadModule: {
      actions,
      state: { editingDocuments },
    },
    document,
    isNew,
  } = props;

  const mutations = useMutations(associatedType, associatedId);

  const toggleEditing = useCallback(() => {
    if (isNew) {
      actions.removeNewDocument(document.uuid);
    } else {
      actions.toggleEditing(document.id);
    }
  }, [actions, document, isNew]);

  const isEditing = useMemo(() => isNew || editingDocuments.includes(document.id), [editingDocuments, document, isNew]);

  if (isEditing) {
    return (
      <EditDocumentRow
        actions={actions}
        mutations={mutations}
        document={props.document}
        isNew={props.isNew}
        toggleEditing={toggleEditing}
      />
    );
  }

  return (
    <DocumentRow
      mutations={mutations}
      document={props.document}
      showControls={props.showControls}
      toggleEditing={toggleEditing}
    />
  );
};

DocumentRowContainer.propTypes = {
  documentUploadModule: PropTypes.shape({
    actions: PropTypes.shape({
      addNewDocument: PropTypes.func.isRequired,
      removeNewDocument: PropTypes.func.isRequired,
      toggleEditing: PropTypes.func.isRequired,
    }).isRequired,
    state: PropTypes.shape({
      editingDocuments: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }),
  associatedType: PropTypes.string.isRequired,
  associatedId: PropTypes.number.isRequired,
  document: CustomPropTypes.document.isRequired,
  isNew: PropTypes.bool,
  showControls: PropTypes.bool,
};

export default DocumentRowContainer;
