import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

import styles from './Logo.styles';

const Logo = ({ classes }) => (
  <Link to="/engagements">
    <div className={classes.Logo}>
      <span className={classes.moser}>moser</span>
      <span className={classes.engagements}>engagements</span>
    </div>
  </Link>
);

export default injectSheet(styles)(Logo);
