import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { signOut } from 'actions/currentUser';
import { Redirect } from 'react-router';

class LogoutContainer extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.signOut();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default connect(null, { signOut })(LogoutContainer);
