import { List } from 'immutable';

import {
  fetchEngagementProjectStatuses,
  receiveProjectStatuses,
  unloadProjectStatuses,
  receiveProjectStatus,
  displayProjectStatusError,
} from 'actions/projectStatuses';

import { createGenericReducer, genericFetchHandler } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'projectStatuses',
  initialState: {
    engagementId: null,
    newProjectStatuses: List(),
    errors: {},
  },
  actionTypes: {
    fetchSuccess: receiveProjectStatuses,
    unload: unloadProjectStatuses,
  },
  actionHandlers: {
    [fetchEngagementProjectStatuses]: (state, { payload: engagementId }) =>
      genericFetchHandler(state.set('engagementId', engagementId)),
    [receiveProjectStatus]: (state, { payload: projectStatus }) => {
      return state
        .set('errors', {})
        .update('projectStatuses', projectStatuses => projectStatuses.set(projectStatus.id, projectStatus));
    },
    [displayProjectStatusError]: (state, { payload: projectStatus }) => {
      return state.set('errors', projectStatus.errors);
    },
  },
});
