import styled from 'styled-components';

const LoaderDotsContainer = styled.span`
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const dotSize = 2;
const dotColor = 'white';

const LoaderDot = styled.span`
  border-radius: 100%;
  border: ${dotSize}px solid ${dotColor};
  margin: calc(${dotSize}px * 2);

  &:nth-child(1) {
    animation: preloader 0.6s ease-in-out alternate infinite;
  }
  &:nth-child(2) {
    animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
  }
  &:nth-child(3) {
    animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
  }

  @keyframes preloader {
    100% {
      transform: scale(2);
    }
  }
`;

export const LoaderDots = () => {
  return (
    <LoaderDotsContainer>
      <LoaderDot />
      <LoaderDot />
      <LoaderDot />
    </LoaderDotsContainer>
  );
};
