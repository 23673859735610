import intersection from 'lodash/intersection';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import AddButton from 'components/buttons/AddButton';
import SearchButton from 'components/buttons/SearchButton';
import Loader from 'components/Loader';
import Page from 'components/Page';
import ResourceFilter from 'components/ResourceFilter';
import SearchBox from 'components/SearchBox/SearchBox';
import Tab from 'components/Tab';
import TabGroup from 'components/TabGroup';
import EngagementsTable from 'components/tables/EngagementsTable.container';

import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/currentUser';
import CustomPropTypes from 'utils/propTypes';

const EngagementsIndex = ({
  engagements,
  engagement_manager: managerId,
  filter,
  isFetching,
  query,
  sales_rep: salesRepId,
  searching,
  sortCache,
  sortKey,
  sortType,
  sortDirection,
  updateQuery,
  updateFilter,
  updateProperty,
  toggleSearch,
}) => {
  const currentUser = useSelector(getCurrentUser);
  const isAdminAccounting = intersection(currentUser.roles, ['accounting', 'admin']).length > 0;

  const headerAdditions = (
    <div style={{ display: 'flex' }}>
      <TabGroup onChange={updateFilter} value={filter}>
        <Tab value="open">Active</Tab>
        {isAdminAccounting && <Tab value="new">New</Tab>}
        {isAdminAccounting && <Tab value="updated">Updated</Tab>}
        {isAdminAccounting && <Tab value="close_requested">Close Requested</Tab>}
        <Tab value="closed">Closed</Tab>
      </TabGroup>
      <SearchButton onClick={toggleSearch} active={searching} />
      <AddButton linkTo="/engagements/new" />
    </div>
  );

  const resourceFilter = (
    <ResourceFilter
      engagementManagerId={managerId}
      salesRepId={salesRepId}
      onEngagementManagerChange={updateProperty('engagement_manager')}
      onSalesRepChange={updateProperty('sales_rep')}
    />
  );

  return (
    <Page title="Engagements" headerAdditions={headerAdditions} beforeContent={resourceFilter}>
      {searching && (
        <SearchBox
          placeholder="Search by client, engagement name, or project number"
          onChange={updateQuery}
          value={query}
        />
      )}
      {isFetching ? (
        <Loader />
      ) : (
        <EngagementsTable
          engagements={orderBy(engagements, ['client.name', 'projectId'])}
          onSortByChange={updateProperty('sortBy')}
          onSortCacheChange={updateProperty('sortCache')}
          onSortDirectionChange={updateProperty('sortDirection')}
          onSortKeyChange={updateProperty('sortKey')}
          onSortyTypeChange={updateProperty('sortType')}
          sortCache={sortCache}
          sortKey={sortKey}
          sortDirection={sortDirection}
          sortType={sortType}
        />
      )}
    </Page>
  );
};

EngagementsIndex.propTypes = {
  engagements: CustomPropTypes.engagements,
  filter: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  engagement_manager: PropTypes.number,
  sales_rep: PropTypes.number,
  query: PropTypes.string.isRequired,
  searching: PropTypes.bool,
  sortCache: PropTypes.object.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  sortDirection: PropTypes.number.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateProperty: PropTypes.func,
  updateQuery: PropTypes.func.isRequired,
};

export default EngagementsIndex;
