export default {
  Table: {
    '& .userName': {
      'min-width': 200,
    },
    '& .userEmail': {
      'min-width': 250,
    },
  },
};
