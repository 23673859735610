import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link, NavLink } from 'react-router-dom';

import Logo from 'components/Logo';
import CustomPropTypes from 'utils/propTypes';

import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/currentUser';
import styles from './App.styles';

const AppLayout = ({ children, classes }) => {
  const currentUser = useSelector(getCurrentUser);

  return (
    <div>
      <header className={classes.header}>
        <Logo />
        <div className={classes.headerContent}>
          <div className={classes.welcome}>
            Welcome, {currentUser && currentUser.name}
            <span className={classes.logout}>
              <Link to={'/logout'}>Logout</Link>
            </span>
          </div>
          <nav className={classes.navigation}>
            <NavLink to={'/engagements'}>Engagements</NavLink>
            <NavLink to={'/clients'}>Clients</NavLink>
            {currentUser.roles.includes('admin') && <NavLink to={'/users'}>Users</NavLink>}
          </nav>
        </div>
      </header>
      {children}
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(AppLayout);
