import * as theme from 'theme';

export default {
  block: {
    display: 'block',
    width: '100%',
  },
  inline: {
    extend: theme.inlineContainer,
  },
  input: {
    extend: theme.formControl,
  },
  errors: {
    borderColor: theme.colors.darkRed,
    transition: [['border-color', '0']],
    '&:focus': {
      borderColor: theme.colors.darkRed,
    },
  },
};
