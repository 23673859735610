import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Icon from 'components/Icon';
import CustomPropTypes from 'utils/propTypes';

import styles from './SearchBox.styles';

const SearchBox = ({ onChange, value, placeholder, classes }) => (
  <div className={classes.SearchBox}>
    <div className={classes.inputContainer}>
      <Icon type="search" className={classes.icon} />
      <input
        type="text"
        className={classes.input}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value}
        autoFocus
      />
    </div>
  </div>
);

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(SearchBox);
