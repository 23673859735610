import { createSelector } from 'reselect';

export const fetchingUsers = state => state.users.get('isFetching');

const users = state => state.users.get('users');
export const getAllUsers = createSelector([users], users => users.toArray());

export const getEngagementManagers = createSelector([getAllUsers], users =>
  users.filter(user => user.isEngagementManager)
);

export const getSalesReps = createSelector([getAllUsers], users => users.filter(user => user.isSalesRep));

export const getAdmins = createSelector([getAllUsers], users => users.filter(user => user.isAdmin));
