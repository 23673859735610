import * as theme from 'theme';

const buttonStyles = {
  Button: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    'font-size': '20px',
    'text-align': 'center',
    padding: [10, 30],
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    background: theme.colors.darkBlue,
    transition: 'opacity 0.5s',

    '&:disabled': {
      cursor: 'not-allowed',
      opacity: '0.5',
    },
  },
  cancel: {
    background: '#e7e7e7',
    color: theme.colors.darkGray,
  },
  primary: {
    background: theme.colors.green,
    color: '#fff',
  },
  square: {
    width: theme.page.header.height,
    height: theme.page.header.height,
    padding: 0,
    margin: 0,
  },
};
export default buttonStyles;
