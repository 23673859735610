import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { updateUser } from 'actions/users';
import showPageFactory from 'hoc/showPage';
import { fetchUser } from 'utils/api';

import Loader from 'components/Loader';
import ShowUser from './ShowUser';
import { useRoles } from 'hooks/useRoles';

const ShowUserContainer = ({ fetchRoles, ...props }) => {
  const { data, isLoading: isRolesLoading } = useRoles();
  if (isRolesLoading || props.loading || !props.user) return <Loader />;
  return <ShowUser {...props} roles={data.roles} />;
};

const isEmptyField = (object, field) => !isUndefined(object[field]) && isEmpty(object[field]);

const beforeSaveHook = updatedUser => {
  const update = { ...updatedUser };

  if (isEmptyField(update, 'ptoBalance')) update.ptoBalance = '0';

  return update;
};

const enhancements = showPageFactory({
  action: updateUser,
  beforeSave: beforeSaveHook,
  endpoint: fetchUser,
});

export default enhancements(ShowUserContainer);
