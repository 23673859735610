import { call, put, select } from 'redux-saga/effects';

import { removeNewResource, receiveResource, toggleResourceEditing } from 'actions/resources';
import { getEngagementId } from 'selectors/resources';
import { createEngagementResource, updateResource } from 'utils/api';

export default function* saveResourceSaga({
  payload: {
    isNew,
    uuid,
    resource: { id: resourceId, ...attributes },
  },
}) {
  const endpoint = isNew ? createEngagementResource : updateResource;
  const engagementId = yield select(getEngagementId);
  const id = isNew ? engagementId : resourceId;

  try {
    const { resource, errors } = yield call(endpoint, id, attributes);

    if (errors) {
      // TODO: handle errors
      console.log(errors);
    } else {
      if (isNew) {
        yield put(removeNewResource(uuid));
      } else {
        yield put(toggleResourceEditing(resource.id));
      }
      yield put(receiveResource(resource));
    }
  } catch (e) {
    console.log('something went wrong: ', e);
  }
}
