import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import CancelButton from 'components/buttons/CancelButton';
import SaveCancelButtons from 'components/buttons/SaveCancelButtons';
import ClientInfoFields from 'components/fields/ClientInfoFields';
import ProjectBillingFields from 'components/fields/ProjectBillingFields';
import ProjectInfoFields from 'components/fields/ProjectInfoFields';
import ResourcesFields from 'components/fields/ResourcesFields';
import Page from 'components/Page';
import Section from 'components/Section';
import ValidationSection from 'components/ValidationSection';
import { BillingClass, BillingType, VALIDATION_MODE } from 'constants';
import { goBack } from 'utils/navigation';
import { engagementSchema } from '../../../schemas/engagement.schema';

import { combineErrors } from '../../../utils/combineErrors';

const getBillingTypeBillingClassSuggestion = billingType => {
  switch (billingType) {
    case BillingType.VARIABLE:
      return BillingClass.DETAILED;
    default:
      return BillingClass.CONSOLIDATED;
  }
};

const NewEngagement = ({ submit, errors: serverErrors }) => {
  const formContext = useForm({
    resolver: yupResolver(engagementSchema),
    mode: VALIDATION_MODE,
    shouldUnregister: true,
  });

  const billingType = formContext.watch('billingType');
  const lastBillingType = useRef(billingType);
  const errors = combineErrors(formContext.formState.errors, serverErrors);

  useEffect(() => {
    if (billingType === lastBillingType.current) return;

    lastBillingType.current = billingType;
    const billingClassSuggestion = getBillingTypeBillingClassSuggestion(billingType);

    if (billingClassSuggestion === formContext.getFieldState('billingClass')) return;

    formContext.setValue('billingClass', billingClassSuggestion);
  }, [formContext, billingType]);

  return (
    <Page title="New Engagement" headerAdditions={<CancelButton onClick={() => goBack('/engagements')} />} padding={15}>
      {Object.keys(errors).length > 0 && (
        <ValidationSection>
          <h4>There are errors preventing you from saving this engagement, please see below.</h4>
        </ValidationSection>
      )}
      <FormProvider {...formContext}>
        <form onSubmit={formContext.handleSubmit(submit)}>
          <Section title="Client Information">
            <ClientInfoFields errors={errors} newEngagement />
          </Section>
          <Section title="Project Information">
            <ProjectInfoFields errors={errors} />
          </Section>
          <Section title="Project Billing">
            <ProjectBillingFields errors={errors} />
          </Section>
          <Section title="Resources">
            <ResourcesFields errors={errors} />
          </Section>
          <SaveCancelButtons />
        </form>
      </FormProvider>
    </Page>
  );
};

NewEngagement.propTypes = {
  engagement: PropTypes.object,
  errors: PropTypes.object,
  submit: PropTypes.func.isRequired,
};

export default NewEngagement;
