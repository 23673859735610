import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import MoserIcon from 'components/MoserIcon';
import CustomPropTypes from 'utils/propTypes';

import styles from './Dialog.styles';

const Dialog = ({ header, children, classes }) => (
  <div className={classes.Dialog}>
    <div className={classes.header}>
      <MoserIcon size={30} style={{ marginRight: 15 }} />
      {header}
    </div>
    <div className={classes.container}>{children}</div>
  </div>
);

Dialog.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Dialog);
