import * as theme from 'theme';

export default {
  TextArea: {
    extend: theme.formControl,
    resize: 'vertical',
  },
  errors: {
    borderColor: theme.colors.darkRed,
    transition: [['border-color', '0']],
    '&:focus': {
      borderColor: theme.colors.darkRed,
    },
  },
};
