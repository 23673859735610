/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EngagementManagerSelect from 'components/inputs/EngagementManagerSelect';
import SalesRepSelect from 'components/inputs/SalesRepSelect';
import * as theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto ${theme.page.gutter}px;
  max-width: 1280px;
`;

const Item = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-right: 15px;

  &:last-child {
    margin: 0;
  }
`;

const ItemLabel = styled.label`
  font-size: 14px;
  margin-right: 5px;
`;

const ResourceFilter = ({ engagementManagerId, salesRepId, onEngagementManagerChange, onSalesRepChange }) => {
  const handleReset = () => {
    onEngagementManagerChange(null);
    onSalesRepChange(null);
  };

  return (
    <Container>
      <Item>
        <em>Showing engagements for:</em>
      </Item>
      <Item>
        <ItemLabel>Sales Rep</ItemLabel>
        <SalesRepSelect name="salesRepId" onChange={onSalesRepChange} value={salesRepId} uncontrolled cached />
      </Item>
      <Item>
        <ItemLabel>Engagement Manager</ItemLabel>
        <EngagementManagerSelect
          name="engagementManagerId"
          onChange={onEngagementManagerChange}
          value={engagementManagerId}
          uncontrolled
          cached
        />
      </Item>
      {(engagementManagerId || salesRepId) && (
        <Item>
          <a onClick={handleReset}>Reset</a>
        </Item>
      )}
    </Container>
  );
};

ResourceFilter.propTypes = {
  defaultEngagementManagerId: PropTypes.number,
  defaultSalesRepId: PropTypes.number,
  engagementManagerId: PropTypes.number,
  onEngagementManagerChange: PropTypes.func.isRequired,
  onSalesRepChange: PropTypes.func.isRequired,
  salesRepId: PropTypes.number,
};

export default ResourceFilter;
