import { all, fork } from 'redux-saga/effects';

import clientsSaga from './clients';
import currentUser from './currentUser';
import employeesSaga from './employees';
import engagementsSaga from './engagements';
import projectStatusesSaga from './projectStatuses';
import reportsSaga from './reports';
import resourcesSaga from './resources';
import rolesSaga from './roles';
import usersSaga from './users';
import validationSaga from './validation';

export default function* rootSaga() {
  yield all([
    fork(clientsSaga),
    fork(currentUser),
    fork(employeesSaga),
    fork(engagementsSaga),
    fork(projectStatusesSaga),
    fork(reportsSaga),
    fork(resourcesSaga),
    fork(rolesSaga),
    fork(usersSaga),
    fork(validationSaga),
  ]);
}
