import { Map, OrderedMap } from 'immutable';
import { handleActions } from 'redux-actions';

export const isFetching = path => state => state[path].get('isFetching');
export const isInvalid = path => state => state[path].get('isInvalid');

export const orderedMapFromArray = (arr, indexKey = 'id') =>
  arr.reduce((om, record, index) => om.set(record[indexKey] ? record[indexKey] : index, record), OrderedMap());

export const createGenericState = initialState =>
  Map({
    isFetching: false,
    isInvalid: false,
    errors: null,
    ...initialState,
  });

export const genericFetchHandler = state =>
  state
    .set('isFetching', true)
    .set('isInvalid', false)
    .set('errors', null);

export const createGenericFetchSuccessHandler = key => (state, { payload }) =>
  state.set('isFetching', false).set(key, orderedMapFromArray(payload));

export const genericCreateHandler = state => state.set('isInvalid', false).set('errors', null);

export const createGenericCreateSuccessHandler = key => (state, { payload }) => state.setIn([key, payload.id], payload);

export const genericInvalidHandler = (state, { payload }) =>
  state
    .set('isFetching', false)
    .set('isInvalid', true)
    .set('errors', payload);

export const createGenericCreateDestroyHandler = key => (state, { payload }) =>
  state.update(key, records => records.delete(payload));

export const createGenericReducer = ({
  rootKey = 'items',
  actionTypes = {},
  initialState: baseInitialState = {},
  actionHandlers = {},
}) => {
  const initialState = createGenericState({
    [rootKey]: OrderedMap(),
    ...baseInitialState,
  });

  const baseHandlers = typeof actionHandlers === 'function' ? actionHandlers(initialState) : actionHandlers;

  const handlers = { ...baseHandlers };

  if (actionTypes.fetch) handlers[actionTypes.fetch] = genericFetchHandler;
  if (actionTypes.fetchSuccess) {
    handlers[actionTypes.fetchSuccess] = createGenericFetchSuccessHandler(rootKey);
  }
  if (actionTypes.create) handlers[actionTypes.create] = genericCreateHandler;
  if (actionTypes.createSuccess) {
    handlers[actionTypes.createSuccess] = createGenericCreateSuccessHandler(rootKey);
  }
  if (actionTypes.invalid) {
    handlers[actionTypes.invalid] = genericInvalidHandler;
  }
  if (actionTypes.destroy) {
    handlers[actionTypes.destroy] = createGenericCreateDestroyHandler(rootKey);
  }
  if (actionTypes.unload) handlers[actionTypes.unload] = () => initialState;

  return handleActions(handlers, initialState);
};
