import hoistNonReactStatics from 'hoist-non-react-statics';
import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { actions, selectors as appStateSelectors } from '../modules/app';

const indexPage = (
  appStateKey = null,
  { fetchAction, fetchOnMount = true, mapStateToProps = null }
) => WrappedComponent => {
  if (!appStateKey) {
    throw Error('No "appStateKey" given to indexPage helper!');
  }

  const IndexPage = ({ fetchAction, ...props }) => {
    const dispatch = useDispatch();
    const { filter, query, searching, sortCache, sortKey, sortDirection, sortType, ...customProperties } = useSelector(
      appStateSelectors.byStateKey(appStateKey)
    );

    const cacheRef = useRef({ customProperties, filter, query, sortCache, sortKey, sortDirection, sortType });

    const handleBaseFetch = useCallback(fetchAction, [fetchAction]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleThrottledFetch = useCallback(
      debounce(args => fetchAction(args), 300),
      [fetchAction]
    );

    const handleFetch = (throttled = false) => {
      let params = {};

      if (filter.length) params.filter = filter;
      if (filter === 'close_requested') params.close_requested = 1;
      if (query.length) params.query = query;
      params = { ...params, ...customProperties };

      if (throttled) {
        handleThrottledFetch(params);
        return;
      }

      handleBaseFetch(params);
    };

    const checkAndUpdateCache = (key, value) => {
      if (isEqual(cacheRef.current[key], value)) return true;
      cacheRef.current[key] = value;
      return false;
    };

    const updateProperty = property => value => {
      dispatch(actions.setAppState({ stateKey: appStateKey, payload: { [property]: value } }));
    };

    const updateFilter = filter => {
      updateProperty('filter')(filter);
    };

    const updateQuery = query => {
      updateProperty('query')(query);
    };

    const toggleSearch = () => {
      dispatch(actions.setAppState({ stateKey: appStateKey, payload: { searching: !searching, query: '' } }));
    };

    // Initial Mount Fetch
    useEffect(() => {
      if (fetchOnMount) {
        handleFetch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Properties Change
    useEffect(() => {
      if (checkAndUpdateCache('customProperties', customProperties)) return;
      handleFetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customProperties]);

    // Filter Change
    useEffect(() => {
      if (checkAndUpdateCache('filter', filter)) return;
      handleFetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    // Query Change
    useEffect(() => {
      if (checkAndUpdateCache('query', query)) return;
      handleFetch(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
      if (checkAndUpdateCache('sortCache', sortCache)) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortCache]);

    useEffect(() => {
      if (checkAndUpdateCache('sortKey', sortKey)) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey]);

    useEffect(() => {
      if (checkAndUpdateCache('sortDirection', sortDirection)) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDirection]);

    useEffect(() => {
      if (checkAndUpdateCache('sortType', sortType)) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    return (
      <WrappedComponent
        {...props}
        {...customProperties}
        filter={filter}
        searching={searching}
        query={query}
        fetch={fetch}
        toggleSearch={toggleSearch}
        sortCache={sortCache}
        sortKey={sortKey}
        sortDirection={sortDirection}
        sortType={sortType}
        updateFilter={updateFilter}
        updateProperty={updateProperty}
        updateQuery={updateQuery}
      />
    );
  };

  const ConnectedIndexPage = connect(mapStateToProps, { fetchAction })(IndexPage);

  hoistNonReactStatics(ConnectedIndexPage, WrappedComponent);

  return ConnectedIndexPage;
};

export default indexPage;
