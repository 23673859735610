import { connect } from 'react-redux';

import { createEngagement, unloadEngagement } from 'actions/engagements';
import { getErrorsFor } from 'selectors/validation';

import NewEngagement from './NewEngagement';

const mapStateToProps = state => ({ errors: getErrorsFor('engagement')(state) });

export default connect(mapStateToProps, { submit: createEngagement, unloadEngagement })(NewEngagement);
