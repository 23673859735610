import { call, put } from 'redux-saga/effects';

import { createClientSuccess, clientInvalid } from 'actions/clients';
import { pushRoute } from 'utils/navigation';
import * as api from 'utils/api';

export function* createClientSaga({ payload: attributes }) {
  try {
    const { client } = yield call(api.createClient, attributes);

    if (client) {
      if (client.errors) {
        yield put(clientInvalid(client.errors));
      } else {
        yield put(createClientSuccess(client));
        yield call(pushRoute, `/clients/${client.id}`);
      }
    }
  } catch (e) {
    console.warn('Something went wrong:', e);
  }
}
