import { handleActions } from 'redux-actions';

import { fetchCurrentUser, fetchCurrentUserSuccess, signInFailed, azureAuthSuccess } from 'actions/currentUser';
import { createGenericState } from 'utils/reducers';

const initialState = createGenericState({
  currentUser: null,
  isFetching: true,
});

export default handleActions(
  {
    [fetchCurrentUser]: state => state.set('isFetching', true).set('isInvalid', false),
    [fetchCurrentUserSuccess]: (state, { payload }) => state.set('isFetching', false).set('currentUser', payload),
    [azureAuthSuccess]: (state, { payload }) => state.set('errors', null),
    [signInFailed]: (state, { payload }) => state.set('isFetching', false).set('errors', payload),
  },
  initialState
);
