import * as theme from 'theme';

export default {
  FormGroup: {
    display: 'flex',
    '&:not(:first-child)': {
      margin: {
        top: 10,
      },
    },
  },
  label: {
    margin: {
      top: 10,
      right: 10,
    },
    'font-weight': 'bold',
    width: 175,
  },
  flex: {
    flex: '1 1 100%',
    'min-height': 40,
    '& > span': {
      'line-height': '40px',
    },
    '& > pre': {
      'font-size': '16px',
      'line-height': '22px',
      'white-space': 'pre-wrap',
      'font-family': theme.font,
      margin: [10, 0, 0],
    },
  },
  updatedField: {
    color: 'rgba(255,0,0,0.95)',
    textShadow: '0 0 5px rgba(255,0,0,0.35)',
  },
  errors: {
    '& > $flex': {
      border: '1px solid',
      borderColor: theme.colors.darkRed,
      borderRadius: 5,
    },
  },
};
