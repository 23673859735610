import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import UserFields from 'components/fields/UserFields';
import FormGroup from 'components/FormGroup';
import CustomPropTypes from 'utils/propTypes';

const currentYear = dayjs().year();

const EditableUserInfo = ({ editing, user, errors, classes, roles, salesRepRoleId }) => {
  if (editing) return <UserFields user={user} errors={errors} roles={roles} salesRepRoleId={salesRepRoleId} />;

  const userRoles = roles.filter(role => user.roles.includes(role.slug)).map(role => role.title);
  const isSalesRep = user.roles.includes('sales-rep');

  return (
    <div>
      <FormGroup label="Name">
        <span>{user.name}</span>
      </FormGroup>
      <FormGroup label="Email">
        <span>{user.email}</span>
      </FormGroup>
      <FormGroup label="Roles">
        {userRoles.length > 0 ? (
          <ul className={classes.roleList}>
            {userRoles.map(role => (
              <li key={role} className={classes.role}>
                {role}
              </li>
            ))}
          </ul>
        ) : (
          <span>None</span>
        )}
      </FormGroup>
      {isSalesRep && (
        <FormGroup label="Sales Rep ID">
          <span>{user.salesRepId}</span>
        </FormGroup>
      )}
      <FormGroup label="Start Date">
        <span>{user.startDate ? dayjs(user.startDate).format('MM/DD/YYYY') : 'N/A'}</span>
      </FormGroup>
      <FormGroup label={`${currentYear} Hours Requested`}>
        <span>{user.ptoRequested ? user.ptoRequested[currentYear] || 0 : 0} Hours</span>
      </FormGroup>
    </div>
  );
};

EditableUserInfo.propTypes = {
  classes: CustomPropTypes.classes,
  editing: PropTypes.bool,
  error: PropTypes.object,
  errors: PropTypes.object,
  user: CustomPropTypes.user.isRequired,
  roles: CustomPropTypes.roles.isRequired,
  salesRepRoleId: PropTypes.string.isRequired,
};

const styles = {
  roleList: {
    margin: [10, 0, 0, 0],
    padding: 0,
  },
  role: {
    margin: [0, 0, 0, 20],
  },
};

export default injectSheet(styles)(EditableUserInfo);
