import { useEffect, useState } from 'react';

import { RHFSelect } from 'components/inputs/Select';

const buildOptions = employees => {
  return employees.map(employee => {
    let label = employee.name;

    if (!employee.accountEnabled) label += ' (inactive)';

    return {
      label,
      value: employee.id,
    };
  });
};

const ResourcesSelect = props => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(buildOptions(props.employees));
  }, [props.employees]);

  return <RHFSelect placeholder="Select resource" {...props} options={options} />;
};

export default ResourcesSelect;
