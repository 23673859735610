import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import injectSheet from 'react-jss';

import CustomPropTypes from 'utils/propTypes';

import styles from './TextArea.styles';

const TextArea = forwardRef(({ style = {}, width = 575, height = 140, errors, classes, ...props }, ref) => (
  <textarea
    {...props}
    ref={ref}
    className={classnames({ [classes.errors]: errors }, classes.TextArea)}
    style={{ ...style, width, height }}
  />
));

TextArea.propTypes = {
  style: PropTypes.object,
  errors: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(TextArea);
