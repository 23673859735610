import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import CustomPropTypes from 'utils/propTypes';

import styles from './Table.styles';

const Table = ({ className, scrollable, classes, ...props }) => (
  <div className={scrollable && classes.tableScroller}>
    <table {...props} className={[classes.Table, className].filter(Boolean).join(' ')} />
  </div>
);

Table.propTypes = {
  classes: CustomPropTypes.classes,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
};

export default injectSheet(styles)(Table);
