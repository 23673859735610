import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { fetchUsers, updateUser } from 'actions/users';

import { updateUserSaga } from './updateUser';
import { fetchUsersSaga } from './fetchUsers';

export default function* usersSaga() {
  yield all([takeLatest(fetchUsers, fetchUsersSaga), takeEvery(updateUser, updateUserSaga)]);
}
