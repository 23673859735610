import indexPageFactory from 'hoc/indexPage';
import { fetchEngagements } from 'actions/engagements';
import { fetchingEngagements, getAllEngagements } from 'selectors/engagements';

import EngagementsIndex from './EngagementsIndex';

const mapStateToProps = state => ({
  engagements: getAllEngagements(state),
  isFetching: fetchingEngagements(state),
});

const appStateKey = 'engagements';
export default indexPageFactory(appStateKey, { fetchAction: fetchEngagements, mapStateToProps })(EngagementsIndex);
