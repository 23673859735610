import { call, put } from 'redux-saga/effects';

import { fetchClientsSuccess } from 'actions/clients';
import * as api from 'utils/api';

export function* fetchClientsSaga({ payload: params }) {
  try {
    const { clients } = yield call(api.fetchClients, params);
    if (clients) yield put(fetchClientsSuccess(clients));
  } catch (e) {
    console.warn('Something went wrong', e);
  }
}
