import * as yup from 'yup';

export const userSchema = yup.object({
  roleIds: yup.array(yup.string()).required(),
  salesRepId: yup
    .string()
    .default(null)
    .when(['roleIds', '$salesRepRoleId'], {
      is: (roleIds, salesRepRoleId) => roleIds.includes(salesRepRoleId),
      then: schema =>
        schema
          .trim()
          .length(2)
          .required()
          .strip(false),
      otherwise: schema => schema.strip(true),
    }),
});
