import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';

import CustomPropTypes from 'utils/propTypes';

import styles from './Page.styles';

const Page = ({ title, headerAdditions, beforeContent, children, breadcrumb, padding = 0, classes }) => (
  <div className={classes.Page}>
    {beforeContent && <div className={classes.beforeContent}>{beforeContent}</div>}
    {breadcrumb && (
      <div className={classes.breadcrumb}>
        <Link to={breadcrumb.to}>{breadcrumb.text || '< Back to Last Page'}</Link>
      </div>
    )}
    <div className={classes.container}>
      <div className={classes.header}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {headerAdditions && <div className={classes.headerAdditions}>{headerAdditions}</div>}
      </div>
      <div className={classes.body} style={{ padding }}>
        {children}
      </div>
    </div>
  </div>
);

Page.propTypes = {
  children: PropTypes.node,
  headerAdditions: PropTypes.node,
  beforeContent: PropTypes.node,
  title: PropTypes.string,
  breadcrumb: PropTypes.shape({ to: PropTypes.string, text: PropTypes.string }),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(Page);
