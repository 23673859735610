import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { formControlCSS, InlineContainer } from 'theme';

const BlockContainer = styled.div`
  display: block;
  width: 100%;
`;

const StyledInput = styled.input`
  ${formControlCSS}
`;

const Input = forwardRef(({ block, width, className, ...props }, ref) => {
  const Container = block ? BlockContainer : InlineContainer;

  return (
    <Container style={{ width }} className={classnames(className)}>
      <StyledInput className={classnames({ errors: !!props.errors })} type="text" ref={ref} {...props} />
    </Container>
  );
});

Input.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  block: PropTypes.bool,
  className: PropTypes.any,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default Input;
