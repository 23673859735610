import { useMountedStateRef } from 'hooks/useMountedStateRef';
import { useEffect, useState } from 'react';

// import Select from 'react-select';
import { fetchEmployees } from 'utils/api';
import { RHFSelect } from './Select';

const buildOptions = users => users.map(u => ({ label: u.name, value: u.id }));

const UsersSelect = props => {
  const [options, setOptions] = useState([]);
  const mountedStateRef = useMountedStateRef();

  useEffect(() => {
    const fetchOptions = async () => {
      const { employees } = await fetchEmployees();
      const activeEmployees = employees.filter(e => e.accountEnabled);
      if (mountedStateRef.current) setOptions(buildOptions(activeEmployees));
    };

    fetchOptions();
  }, [mountedStateRef]);

  return <RHFSelect {...props} options={options} isMulti={true} />;
};

export default UsersSelect;
