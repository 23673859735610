import { call, put } from 'redux-saga/effects';

import { updateUserSuccess, userInvalid } from 'actions/users';
import { actions as toastActions } from 'modules/toast';
import { updateUser } from 'utils/api';
import { pushRoute } from 'utils/navigation';

// Helpers
const toast = (type, title, message) =>
  toastActions.createToast(type, {
    title,
    message,
  });

export function* updateUserSaga({ payload: { id, ...attributes } }) {
  try {
    const { user } = yield call(updateUser, id, attributes);

    if (user) {
      if (user.errors) {
        yield put(userInvalid(user.errors));
      } else {
        yield put(updateUserSuccess(user));
        yield put(toast('success', 'Update Success', 'User was updated successfully!'));
        yield call(pushRoute, '/users');
      }
    }
  } catch (e) {
    console.warn('Something went wrong:', e);
  }
}
