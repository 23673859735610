import { fetchEmployees, fetchEmployeesSuccess } from 'actions/employees';
import { List } from 'immutable';
import { createGenericReducer } from 'utils/reducers';

export default createGenericReducer({
  rootKey: 'employees',
  initialState: {
    employees: List(),
  },
  actionTypes: {
    fetch: fetchEmployees,
    fetchSuccess: fetchEmployeesSuccess,
  },
});
