import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useIdleTimer } from 'react-idle-timer';
import { signOut } from 'actions/currentUser';

const DELAY_LOGOUT_MILLISECONDS = 24 * 60 * 60 * 1000;

export const AutoLogoutTimer = props => {
  const dispatch = useDispatch();
  const onIdle = useCallback(() => dispatch(signOut()), [dispatch]);

  useIdleTimer({ onIdle, timeout: DELAY_LOGOUT_MILLISECONDS });

  return props.children;
};
