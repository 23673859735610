import { createActions, handleActions } from 'redux-actions';
import { fetchCurrentUserSuccess } from '../actions/currentUser';

const baseSearchState = {
  filter: '',
  query: '',
  searching: false,
  sortCache: {},
  sortDirection: 1,
  sortKey: 'clients',
  sortType: 'string',
};

const initialState = {
  clients: {
    ...baseSearchState,
    filter: 'open',
  },
  engagements: {
    ...baseSearchState,
    filter: 'open',
    engagement_manager: null,
    sales_rep: null,
  },
  users: {
    ...baseSearchState,
  },
};

// Selectors
export const selectors = {
  byStateKey: key => state => state.app[key],
};

// Actions
export const actions = createActions('SET_APP_STATE');

// Reducers
export default handleActions(
  {
    [fetchCurrentUserSuccess](state, { payload: currentUser }) {
      const nextEngagementManager = currentUser.engagementManager ? currentUser.id : null;
      const nextSalesRep = currentUser.salesRep ? currentUser.id : null;

      return {
        ...state,
        engagements: {
          ...state.engagements,
          engagement_manager: nextEngagementManager,
          sales_rep: nextSalesRep,
        },
      };
    },
    [actions.setAppState](state, { payload: { stateKey = '', payload = {} } }) {
      return {
        ...state,
        [stateKey]: {
          ...(state[stateKey] ?? {}),
          ...(payload ?? {}),
        },
      };
    },
  },
  initialState
);
