import 'font-awesome/css/font-awesome.min.css';
import 'noty/lib/noty.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';

import rollbarConfig from 'config/rollbar';
import ToastContainer from 'components/Toasts/Toast.container';
import { history } from 'utils/navigation';
import { requireRoles } from 'hoc/auth/index';
import { queryClient } from 'lib/reactQuery';
import { AnalyticsProvider } from 'providers/AnalyticsProvider';
import store from 'store';

import App from './App';
import Clients from './Clients';
import Engagements from './Engagements';
import AuthGate from './AuthGate';
import { GlobalStyles } from './GlobalStyles';
import LoginContainer from './Login/Login.container';
import Logout from './Logout';
import StatusPage from './StatusPage';
import Users from './Users';
import { AutoLogoutTimer } from './AutoLogoutTimer';

const Forbidden = () => <StatusPage code={403} />;
const NotFound = () => <StatusPage code={404} />;

export const AppRoot = () => {
  return (
    <>
      <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <Router history={history}>
                <AnalyticsProvider>
                  <GlobalStyles />
                  <AuthGate loginComponent={LoginContainer}>
                    <AutoLogoutTimer>
                      <App>
                        <Switch>
                          <Redirect exact from="/" to="/engagements" />
                          <Route path="/engagements/new" component={Engagements.New} />
                          <Route path="/engagements/:id" component={Engagements.Show} />
                          <Route exact path="/engagements" component={Engagements.Index} />
                          <Route path="/clients/new" component={Clients.New} />
                          <Route path="/clients/:id" component={Clients.Show} />
                          <Route exact path="/clients" component={Clients.Index} />
                          <Route path="/users/:id" component={requireRoles(Forbidden, 'admin')(Users.Show)} />
                          <Route exact path="/users" component={requireRoles(Forbidden, 'admin')(Users.Index)} />
                          <Route path="/logout" component={Logout} />
                          <Route component={NotFound} />
                        </Switch>
                      </App>
                    </AutoLogoutTimer>
                  </AuthGate>
                </AnalyticsProvider>
              </Router>
              <ToastContainer />
            </ReduxProvider>
          </QueryClientProvider>
        </RollbarErrorBoundary>
      </RollbarProvider>
    </>
  );
};
