import { createAction } from 'redux-actions';

export const signIn = createAction('SIGN_IN');
export const signInFailed = createAction('SIGN_IN_FAILED');

export const signOut = createAction('SIGN_OUT');

export const signInAdmin = createAction('SIGN_IN_ADMIN');

export const azureAuthSuccess = createAction('AZURE_AUTH_SUCCESS');
export const azureAdminAuthSuccess = createAction('AZURE_ADMIN_AUTH_SUCCESS');

export const fetchCurrentUser = createAction('FETCH_CURRENT_USER');
export const fetchCurrentUserSuccess = createAction('FETCH_CURRENT_USER_SUCCESS');
