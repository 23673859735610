import { createAction } from 'redux-actions';

export const fetchClients = createAction('FETCH_CLIENTS');
export const fetchClientsSuccess = createAction('FETCH_CLIENTS_SUCCESS');

export const createClient = createAction('CREATE_CLIENT');
export const createClientSuccess = createAction('CREATE_CLIENT_SUCCESS');

export const updateClient = createAction('UPDATE_CLIENT');
export const updateClientSuccess = createAction('UPDATE_CLIENT_SUCCESS');

export const clientInvalid = createAction('CLIENT_INVALID');

export const unloadClient = createAction('UNLOAD_CLIENT');
