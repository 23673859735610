import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import Dialog from 'components/Dialog';
import CustomPropTypes from 'utils/propTypes';

import styles from './StatusPage.styles';

const statuses = {
  404: { text: 'not found', icon: '🕵' },
  403: { text: 'forbidden', icon: '⚠️' },
};

const StatusPage = ({ code, classes }) => {
  const status = statuses[code];

  return (
    <div className={classes.container}>
      <Dialog header="Uh Oh!">
        <div className={classes.statusIcon}>{status.icon}</div>
        <h2 className={classes.statusText}>
          {code} {status.text}
        </h2>
      </Dialog>
    </div>
  );
};

StatusPage.propTypes = {
  classes: CustomPropTypes.classes,
  code: PropTypes.number,
};

export default injectSheet(styles)(StatusPage);
