import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import injectSheet from 'react-jss';

import CustomPropTypes from 'utils/propTypes';

import styles from './TabGroup.styles';

const TabGroup = ({ currentValue, onTabClick, children, classes }) => (
  <ul className={classes.TabGroup}>
    {Children.map(
      children,
      child =>
        child &&
        cloneElement(child, {
          active: child.props.value === currentValue,
          onClick: () => onTabClick(child.props.value),
        })
    )}
  </ul>
);

TabGroup.propTypes = {
  currentValue: PropTypes.string,
  children: PropTypes.node,
  onTabClick: PropTypes.func.isRequired,
  classes: CustomPropTypes.classes,
};

export default injectSheet(styles)(TabGroup);
