import Button from 'components/Button';
import styled from 'styled-components';

const RequestCloseButton = ({ engagement, loading, ...rest }) => {
  if (!engagement) return null;

  const { closeRequested } = engagement;

  return (
    <Button theme="primary" disabled={loading} {...rest}>
      {closeRequested ? 'CANCEL CLOSURE REQUEST' : 'REQUEST CLOSE'}
      {closeRequested && <ButtonIcon className="fa fa-clock-o" />}
    </Button>
  );
};

const ButtonIcon = styled.i`
  margin-left: 0.5em;
`;

export default RequestCloseButton;
