import classnames from 'classnames';
import PropTypes from 'prop-types';
import { createInput } from 'react-json-form';
import injectSheet from 'react-jss';
import { compose } from 'redux';

import CustomPropTypes from 'utils/propTypes';

import styles from './Input.styles';

const Input = ({ icon, block, getRef, onChange, width, errors, classes, ...props }) => (
  <div className={block ? classes.block : classes.inline} style={{ width }} {...props}>
    {icon && <div className={classes.icon} />}
    <input
      type="text"
      {...props}
      className={classnames({ [classes.errors]: errors }, classes.input)}
      ref={node => {
        if (getRef) getRef(node);
      }}
      onChange={onChange}
    />
  </div>
);

Input.propTypes = {
  block: PropTypes.bool,
  classes: CustomPropTypes.classes,
  errors: PropTypes.object,
  getRef: PropTypes.func,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const enhance = compose(createInput(), injectSheet(styles));

export default enhance(Input);
