export const ConditionalLabel = ({ label, children, ...props }) => {
  if (!label) return children;

  return (
    <label {...props}>
      {children}
      <span>{label}</span>
    </label>
  );
};
