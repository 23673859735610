import Immutable from 'immutable';
import { isFunction } from 'lodash';
import packageJson from '../../package.json';

export const stateTransformer = state => {
  const newState = {};

  Object.keys(state).forEach(i => {
    if (Immutable.Iterable.isIterable(state[i])) {
      newState[i] = state[i].toJS();
    } else {
      newState[i] = state[i];
    }
  });

  return newState;
};

export const getDisplayName = Component => Component.displayName || Component.name || 'Component';

const usdFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const toUsd = number => usdFormatter.format(number);

export const states = [
  { label: 'ALABAMA', value: 'AL' },
  { label: 'ALASKA', value: 'AK' },
  { label: 'AMERICAN SAMOA', value: 'AS' },
  { label: 'ARIZONA', value: 'AZ' },
  { label: 'ARKANSAS', value: 'AR' },
  { label: 'CALIFORNIA', value: 'CA' },
  { label: 'COLORADO', value: 'CO' },
  { label: 'CONNECTICUT', value: 'CT' },
  { label: 'DELAWARE', value: 'DE' },
  { label: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { label: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  { label: 'FLORIDA', value: 'FL' },
  { label: 'GEORGIA', value: 'GA' },
  { label: 'GUAM', value: 'GU' },
  { label: 'HAWAII', value: 'HI' },
  { label: 'IDAHO', value: 'ID' },
  { label: 'ILLINOIS', value: 'IL' },
  { label: 'INDIANA', value: 'IN' },
  { label: 'IOWA', value: 'IA' },
  { label: 'KANSAS', value: 'KS' },
  { label: 'KENTUCKY', value: 'KY' },
  { label: 'LOUISIANA', value: 'LA' },
  { label: 'MAINE', value: 'ME' },
  { label: 'MARSHALL ISLANDS', value: 'MH' },
  { label: 'MARYLAND', value: 'MD' },
  { label: 'MASSACHUSETTS', value: 'MA' },
  { label: 'MICHIGAN', value: 'MI' },
  { label: 'MINNESOTA', value: 'MN' },
  { label: 'MISSISSIPPI', value: 'MS' },
  { label: 'MISSOURI', value: 'MO' },
  { label: 'MONTANA', value: 'MT' },
  { label: 'NEBRASKA', value: 'NE' },
  { label: 'NEVADA', value: 'NV' },
  { label: 'NEW HAMPSHIRE', value: 'NH' },
  { label: 'NEW JERSEY', value: 'NJ' },
  { label: 'NEW MEXICO', value: 'NM' },
  { label: 'NEW YORK', value: 'NY' },
  { label: 'NORTH CAROLINA', value: 'NC' },
  { label: 'NORTH DAKOTA', value: 'ND' },
  { label: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  { label: 'OHIO', value: 'OH' },
  { label: 'OKLAHOMA', value: 'OK' },
  { label: 'OREGON', value: 'OR' },
  { label: 'PALAU', value: 'PW' },
  { label: 'PENNSYLVANIA', value: 'PA' },
  { label: 'PUERTO RICO', value: 'PR' },
  { label: 'RHODE ISLAND', value: 'RI' },
  { label: 'SOUTH CAROLINA', value: 'SC' },
  { label: 'SOUTH DAKOTA', value: 'SD' },
  { label: 'TENNESSEE', value: 'TN' },
  { label: 'TEXAS', value: 'TX' },
  { label: 'UTAH', value: 'UT' },
  { label: 'VERMONT', value: 'VT' },
  { label: 'VIRGIN ISLANDS', value: 'VI' },
  { label: 'VIRGINIA', value: 'VA' },
  { label: 'WASHINGTON', value: 'WA' },
  { label: 'WEST VIRGINIA', value: 'WV' },
  { label: 'WISCONSIN', value: 'WI' },
  { label: 'WYOMING', value: 'WY' },
];

export const buildOptionsFromArray = (array = [], { labelFormatter = 'name', valueFormatter = 'id' } = {}) => {
  return array.map(item => ({
    label: isFunction(labelFormatter) ? labelFormatter(item) : item[labelFormatter],
    value: isFunction(valueFormatter) ? valueFormatter(item) : item[valueFormatter],
  }));
};

export const objectToOptions = (obj = {}, meta = {}) =>
  Object.keys(obj).map(key => ({
    ...meta[key],
    value: key,
    label: obj[key],
  }));

export const billingTermsTypes = [
  { label: 'Net 30', value: '30' },
  { label: 'Net 45', value: '45' },
  { label: 'Net 60', value: '60' },
  { label: '2%/15, Net 30', value: '2%/15,30', disabled: true },
];

export const statuses = [
  { label: 'green', value: 'green' },
  { label: 'orange', value: 'orange' },
  { label: 'red', value: 'red' },
];

const baseStateTypes = {
  new: 'New',
  active: 'Active',
  updated: 'Updated',
};

export const engagementStateTypes = {
  ...baseStateTypes,
  closed: 'Closed',
};

export const clientStateTypes = {
  ...baseStateTypes,
  closed: 'Inactive',
};

export const engagementTypes = {
  commercial: 'Commercial',
  program: 'Program',
  honeycomb: 'Honeycomb',
  subscriptions: 'Software Subscriptions',
  labs: 'Moser Labs',
};

export const billingTypes = {
  variable: 'T&M',
  fixed: 'Fixed Fee',
};

export const APP_NAME = packageJson.displayName ?? '';
export const APP_VERSION = packageJson.version ?? '';
export const IS_DEV = process.env.NODE_ENV === 'development';
