import apiFetch from 'utils/apiFetch';
import { apiFetchCached } from './apiFetchCached';

// currentUser
export const fetchCurrentUser = () => apiFetch('/api/user');
export const signout = () => apiFetch('/api/signout');

// clients
export const fetchClients = params => apiFetch('/api/clients', { params });
export const fetchClient = id => apiFetch(`/api/clients/${id}`);
export const createClient = client => apiFetch('/api/clients', { method: 'POST', body: { client } });

export const updateClient = (id, client) => apiFetch(`/api/clients/${id}`, { method: 'PATCH', body: { client } });

export const fetchBillingContacts = id => apiFetch(`/api/clients/${id}/billing_contacts`);

// engagements
export const fetchEngagements = params => apiFetch('/api/engagements', { params });

export const fetchEngagement = id => apiFetch(`/api/engagements/${id}`);
export const createEngagement = engagement => apiFetch('/api/engagements', { method: 'POST', body: { engagement } });

export const updateEngagement = (id, engagement) =>
  apiFetch(`/api/engagements/${id}`, { method: 'PATCH', body: { engagement } });

export const requestCloseEngagement = (id, undo) =>
  apiFetch(`/api/engagements/${id}/request_close`, {
    method: 'POST',
    body: { undo: undo },
  });

// documents
export const fetchAssociatedDocuments = (type, id) => apiFetch(`/api/${type}/${id}/documents`);

export const createAssociatedDocument = (type, id, document) => {
  const formData = Object.keys(document).reduce((formData, key) => {
    formData.append(`document[${key}]`, document[key]);
    return formData;
  }, new FormData());

  return apiFetch(`/api/${type}/${id}/documents`, { method: 'POST', formData });
};

export const updateDocument = (id, document) =>
  apiFetch(`/api/documents/${id}`, { method: 'PATCH', body: { document } });

export const destroyDocument = id => apiFetch(`/api/documents/${id}`, { method: 'DELETE' });

// engagement resources
export const fetchEngagementResources = (id, params) => apiFetch(`/api/engagements/${id}/resources`, { params });

export const createEngagementResource = (id, resource) =>
  apiFetch(`/api/engagements/${id}/resources`, {
    method: 'POST',
    body: { resource },
  });

// engagement project statuses
export const fetchEngagementProjectStatuses = id => apiFetch(`/api/engagements/${id}/project_statuses`);

export const createEngagementProjectStatus = (id, projectStatus) =>
  apiFetch(`/api/engagements/${id}/project_statuses`, {
    method: 'POST',
    body: { projectStatus },
  });

// reports
export const fetchFixedFeeProfitabilityReport = params => apiFetch(`/api/fixed_fee_profitability_report`, { params });

// resources
export const updateResource = (id, resource) =>
  apiFetch(`/api/resources/${id}`, { method: 'PATCH', body: { resource } });
export const fetchResourceRoles = () => apiFetch('/api/resources/roles');

export const destroyResource = id => apiFetch(`/api/resources/${id}`, { method: 'DELETE' });

// users
export const fetchUsers = params => apiFetch('/api/employees', { params });
export const fetchUser = id => apiFetch(`/api/employees/${id}`);
export const fetchSalesReps = params => apiFetch('/api/employees/sales_reps', { params });
export const fetchSalesRepsCached = params =>
  apiFetchCached({ key: 'salesReps' }, '/api/employees/sales_reps', { params });
export const fetchEngagementManagers = params => apiFetch('/api/employees/engagement_managers', { params });
export const fetchEngagementManagersCached = params =>
  apiFetchCached({ key: 'engagementManagers' }, '/api/employees/engagement_managers', { params });

export const updateUser = (id, user) => apiFetch(`/api/employees/${id}`, { method: 'PATCH', body: { user } });

// roles
export const fetchRoles = () => apiFetch('/api/roles');

// departments
export const fetchDepartments = () => apiFetch('/api/departments');

// employees
export const fetchEmployees = params => apiFetch('/api/employees', { params });
