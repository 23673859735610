import indexPageFactory from 'hoc/indexPage';
import { fetchUsers } from 'actions/users';
import { fetchingUsers, getAllUsers } from 'selectors/users';

import UsersIndex from './UsersIndex';

const mapStateToProps = state => ({
  isFetching: fetchingUsers(state),
  users: getAllUsers(state),
});

const appStateKey = 'users';
export default indexPageFactory(appStateKey, { fetchAction: fetchUsers, mapStateToProps })(UsersIndex);
