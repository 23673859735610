import classnames from 'classnames';
import PropTypes from 'prop-types';
import BaseDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import injectSheet from 'react-jss';

import Icon from 'components/Icon';
import CustomPropTypes from 'utils/propTypes';

import styles from './DatePicker.styles';

const DatePicker = ({ defaultValue, style, width, errors, showIcon = true, classes, ...props }) => (
  <label className={classes.container}>
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <BaseDatePicker
          {...props}
          className={classnames({ [classes.errors]: errors }, classes.datePicker)}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          selected={field.value}
          style={{ ...style, width }}
          autoComplete="off"
        />
      )}
    />
    {showIcon && <Icon type="calendar-o" className={classes.calendarIcon} />}
  </label>
);

DatePicker.propTypes = {
  classes: CustomPropTypes.classes,
  defaultValue: PropTypes.instanceOf(Date),
  errors: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default injectSheet(styles)(DatePicker);
