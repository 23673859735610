import { call, put, select } from 'redux-saga/effects';

import { updateEngagementSuccess, engagementInvalid } from 'actions/engagements';
import { updateEngagement } from 'utils/api';
import { pushRoute } from 'utils/navigation';
import { unsavedResourceCount } from 'selectors/resources';
import { unsavedDocumentCount } from 'modules/documents';

export default function* updateEngagementSaga({ payload: { id, ...attributes } }) {
  const unsaved = (yield select(unsavedResourceCount)) + (yield select(unsavedDocumentCount));

  if (unsaved) {
    const message = 'You have unsaved resources or documents. Please make sure all resources and documents are saved before continuing.'; // eslint-disable-line
    yield call(window.alert, message);
  } else {
    try {
      const { engagement } = yield call(updateEngagement, id, attributes);

      if (engagement) {
        if (engagement.errors) {
          yield put(engagementInvalid(engagement.errors));
        } else {
          yield put(updateEngagementSuccess(engagement));
          yield call(pushRoute, '/engagements');
        }
      }
    } catch (e) {
      console.warn('Something went wrong:', e);
    }
  }
}
