import { combineReducers } from 'redux';

import { signOut } from 'actions/currentUser';
import appReducer from 'modules/app';
import { reducer as documentsReducer } from 'modules/documents';

import clientsReducer from './clients';
import currentUserReducer from './currentUser';
import employeesReducer from './employees';
import engagementReducer from './engagement';
import engagementsReducer from './engagements';
import projectStatusesReducer from './projectStatuses';
import reportsReducer from './reports';
import resourcesReducer from './resources';
import rolesReducer from './roles';
import toastReducer from '../modules/toast';
import usersReducer from './users';
import validationReducer from './validation';

const baseReducer = combineReducers({
  app: appReducer,
  clients: clientsReducer,
  currentUser: currentUserReducer,
  documents: documentsReducer,
  employees: employeesReducer,
  engagement: engagementReducer,
  engagements: engagementsReducer,
  projectStatuses: projectStatusesReducer,
  reports: reportsReducer,
  resources: resourcesReducer,
  roles: rolesReducer,
  toasts: toastReducer,
  users: usersReducer,
  validation: validationReducer,
});

export default (state, action) => {
  // Blow away all state if user logged out
  if (action.type === signOut.toString()) state = undefined;

  // Delegate to baseReducer
  return baseReducer(state, action);
};
