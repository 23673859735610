import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchEngagements, createEngagement, updateEngagement } from 'actions/engagements';

import createEngagementSaga from './createEngagement';
import fetchEngagementsSaga from './fetchEngagements';
import updateEngagementSaga from './updateEngagement';

export default function* engagementsSaga() {
  yield all([
    takeEvery(createEngagement, createEngagementSaga),
    takeEvery(updateEngagement, updateEngagementSaga),
    takeLatest(fetchEngagements, fetchEngagementsSaga),
  ]);
}
