import { call, put, select } from 'redux-saga/effects';

import { displayProjectStatusError, receiveProjectStatus } from 'actions/projectStatuses';
import { updateSingleEngagement } from 'actions/engagements';
import { getEngagementId } from 'selectors/projectStatuses';
import { createEngagementProjectStatus as endpoint } from 'utils/api';

export default function* saveProjectStatusSaga({
  payload: { id: projectStatusId, singleBulk = false, ...attributes },
}) {
  let id;
  // Updating in list of engagements
  if (singleBulk) {
    id = projectStatusId;
  } else {
    id = yield select(getEngagementId);
  }

  try {
    const { projectStatus } = yield call(endpoint, id, attributes);

    if (projectStatus.errors) {
      if (singleBulk) {
        // not handling the bulk path yet, let's just hope no errors occur on the list page  ;)
      } else {
        yield put(displayProjectStatusError(projectStatus));
      }
    } else {
      if (singleBulk) {
        yield put(updateSingleEngagement({ id, status: projectStatus }));
      } else {
        yield put(receiveProjectStatus(projectStatus));
      }
    }
  } catch (e) {
    console.log('something went wrong: ', e);
  }
}
