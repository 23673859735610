import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import mediumIcon from 'assets/images/white-mark-medium.png';
import smallIcon from 'assets/images/white-mark-small.png';

import CustomPropTypes from 'utils/propTypes';

import styles from './MoserIcon.styles';

const MoserIcon = ({ size = 60, style, classes }) => (
  <div
    className={classes.MoserIcon}
    style={{
      ...style,
      backgroundImage: `url(${size > 80 ? mediumIcon : smallIcon})`,
      width: size,
      height: size,
    }}
  />
);

MoserIcon.propTypes = {
  classes: CustomPropTypes.classes,
  size: PropTypes.number,
  style: PropTypes.object,
};

export default injectSheet(styles)(MoserIcon);
