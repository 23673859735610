import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from 'modules/toast';
import { resourceDestroyed } from 'actions/resources';
import { destroyResource } from 'utils/api';

const INVALID_FOREIGN_KEY_NAME = 'invalid_foreign_key';

const toast = (type, title, message) =>
  toastActions.createToast(type, {
    title,
    message,
    timeout: 20000,
  });

export default function* destroyResourceSaga({ payload: resourceId }) {
  if (!window.confirm('Are you sure you want to delete this resource?')) return;

  if (resourceId) {
    try {
      const { error } = yield call(destroyResource, resourceId);

      if (error && error.name === INVALID_FOREIGN_KEY_NAME) {
        return yield put(
          toast(
            'alert',
            'Delete Failed',
            "This resource can't be deleted because they have time entered for the engagement."
          )
        );
      }

      yield put(resourceDestroyed(resourceId));
    } catch (e) {
      console.log('something went wrong, ', e);
    }
  }
}
