import Select from 'components/inputs/Select';
import * as theme from 'theme';
import { clientStateTypes, engagementStateTypes, objectToOptions } from 'utils/common';

const selectStyles = { container: provided => ({ ...provided, color: theme.colors.darkBlue }) };

const stateTypeOptions = (entityType, roles) => {
  let defaultOpts = { new: { disabled: true }, updated: { disabled: true }, closed: { disabled: false } };

  if (entityType === 'client') return objectToOptions(clientStateTypes, defaultOpts);

  const isAccounting = roles.includes('accounting');
  defaultOpts.closed.disabled = !isAccounting;
  return objectToOptions(engagementStateTypes, defaultOpts);
};

const CurrentStateSelect = ({ entityType, roles = [], ...rest }) => (
  <Select
    placeholder="Select State"
    autoresize={false}
    width={150}
    styles={selectStyles}
    {...rest}
    isOptionDisabled={option => option.disabled}
    options={stateTypeOptions(entityType, roles)}
  />
);

export default CurrentStateSelect;
