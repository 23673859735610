import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { setEngagement } from 'actions/engagement';
import { unloadEngagement, updateEngagement } from 'actions/engagements';
import showPageFactory from 'hoc/showPage';
import { getErrorsFor } from 'selectors/validation';
import { fetchEngagement, requestCloseEngagement } from 'utils/api';
import CustomPropTypes from 'utils/propTypes';
// import omit from 'lodash/omit';

import ShowEngagement from './ShowEngagement';

class ShowEngagementContainer extends Component {
  static propTypes = {
    currentUser: CustomPropTypes.user.isRequired,
    engagement: CustomPropTypes.engagement,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    refetch: PropTypes.func.isRequired,
    setEngagement: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    unloadEngagement: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    documents: this.props.engagement.documents,
  };

  componentDidMount() {
    this.props.setEngagement(this.props.engagement);
  }

  componentWillUnmount() {
    this.props.unloadEngagement();
  }

  handleRequestClose = async () => {
    const { id, closeRequested } = this.props.engagement;
    if (!id) return;

    try {
      this.setState({ loading: true });
      await requestCloseEngagement(id, closeRequested);
      await this.props.refetch();
      this.props.setEngagement(this.props.engagement);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <ShowEngagement
        currentUser={this.props.currentUser}
        engagement={this.props.engagement}
        errors={this.props.errors}
        handleBillingToggle={this.handleBillingToggle}
        handleRequestClose={this.handleRequestClose}
        loading={this.props.loading || this.state.loading}
        submit={this.props.submit}
      />
    );
  }
}

const mapStateToProps = state => ({
  errors: getErrorsFor('engagement')(state),
});

export default compose(
  showPageFactory({
    endpoint: fetchEngagement,
    action: updateEngagement,
  }),
  connect(mapStateToProps, { unloadEngagement, setEngagement })
)(ShowEngagementContainer);
