import { useReferenceQuery } from 'hooks/useReferenceQuery';
import { fetchClients } from '../../utils/api';

export const useClients = () => {
  const queryName = 'clients';

  const queryMethod = async () => {
    const { clients } = await fetchClients();
    return clients;
  };

  const clientsQuery = useReferenceQuery(queryName, queryMethod);
  return clientsQuery;
};
