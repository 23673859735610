import { hasShadow, colors } from 'theme';

const appStyles = {
  header: {
    extend: hasShadow,
    background: 'white',
    height: 95,
    display: 'flex',
    '& a': {
      color: colors.darkBlue,
    },
  },
  headerContent: {
    flex: '1 1 100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'flex-end',
    'padding-right': 60,
  },
  welcome: {
    'font-size': '12px',
  },
  logout: {
    display: 'inline-block',
    width: 45,
    'text-align': 'right',
    '&:hover': {
      'font-weight': 'bold',
    },
  },
  navigation: {
    display: 'flex',
    'margin-top': 16,
    'text-transform': 'uppercase',
    '& a:not(:first-child)': {
      'margin-left': 20,
    },
    '& a.active': {
      'font-weight': 'bold',
    },
  },
};

export default appStyles;
