import * as theme from 'theme';

export default {
  validationSection: {
    color: theme.colors.darkRed,
    marginBottom: 10,
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      margin: '5px 0 15px',
    },
  },
};
