import { createAction } from 'redux-actions';

export const fetchEngagements = createAction('FETCH_ENGAGEMENTS');
export const fetchEngagementsSuccess = createAction('FETCH_ENGAGEMENTS_SUCCESS');

export const createEngagement = createAction('CREATE_ENGAGEMENT');
export const createEngagementSuccess = createAction('CREATE_ENGAGEMENT_SUCCESS');

export const updateSingleEngagement = createAction('UPDATE_SINGLE_ENGAGEMENT');
export const updateEngagement = createAction('UPDATE_ENGAGEMENT');
export const updateEngagementSuccess = createAction('UPDATE_ENGAGEMENT_SUCCESS');

export const engagementInvalid = createAction('ENGAGEMENT_INVALID');

export const unloadEngagement = createAction('UNLOAD_ENGAGEMENT');
