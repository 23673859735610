import { useReferenceQuery } from 'hooks/useReferenceQuery';
import { fetchEngagementManagers } from '../../utils/api';

export const useEngagementManagers = params => {
  const queryName = 'engagementManagers';

  const queryMethod = async () => {
    const { spudUsers } = await fetchEngagementManagers(params);
    return spudUsers;
  };

  const engagementManagersQuery = useReferenceQuery(queryName, queryMethod);
  return engagementManagersQuery;
};
