import indexPageFactory from 'hoc/indexPage';
import { fetchClients } from 'actions/clients';
import { fetchingClients, getAllClients } from 'selectors/clients';

import ClientsIndex from './ClientsIndex';

const mapStateToProps = state => ({
  clients: getAllClients(state),
  isFetching: fetchingClients(state),
});

const appStateKey = 'clients';
export default indexPageFactory(appStateKey, { fetchAction: fetchClients, mapStateToProps })(ClientsIndex);
